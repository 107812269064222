import styled from '@emotion/styled'

export const BasicTileGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 164px);
  gap: 8px;
  grid-auto-rows: 168px;
  justify-content: center;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fit, minmax(120px, 150px));
    grid-template-rows: repeat(auto-fill, 175px);
    grid-auto-rows: 175px;
    gap: 10px;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(3, minmax(120px, 130px));
    grid-auto-rows: 145px;
    grid-template-rows: 145px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (max-width: 400px) {
    grid-template-columns: repeat(2, minmax(90px, 1fr));
    grid-auto-rows: 185px;
    grid-template-rows: 185px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }
`

export const GridLarge = styled.div`
  display: grid;
  grid-template-columns: 336px 160px 160px 160px 160px;
  grid-template-rows: repeat(2, 190px);
  gap: 12px;
  justify-content: center;

  @media (max-width: 1100px) {
    grid-template-columns: 288px 165px 165px 165px 165px;
    grid-template-rows: repeat(2, 165px);
    gap: 15px;
  }

  @media (max-width: 1040px) {
    grid-template-columns: 270px 155px 155px 155px 155px;
    grid-template-rows: repeat(2, 168px);
    gap: 15px;
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-template-rows: repeat(auto-fill, 170px);
    grid-auto-rows: 170px;
    gap: 10px;
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(3, minmax(120px, 130px));
    grid-auto-rows: 145px;
    grid-template-rows: 145px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (max-width: 400px) {
    grid-template-columns: repeat(2, minmax(90px, 1fr));
    grid-auto-rows: 185px;
    grid-template-rows: 185px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }
`

export const GridMedium = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 77px);
  justify-content: center;

  & > :nth-of-type(n + 1) {
    grid-column: 1/3;
  }

  & > :nth-of-type(n + 2) {
    grid-column: 3/5;
  }

  & > :nth-of-type(n + 3) {
    grid-column: 5/7;
  }

  & > :nth-of-type(n + 4) {
    grid-column: 7/9;
  }

  & > :nth-of-type(n + 5) {
    grid-column: 9/11;
  }

  & > :nth-of-type(n + 6) {
    grid-column: 11/13;
  }

  & > :nth-of-type(n + 7) {
    grid-column: 1/4;
  }

  & > :nth-of-type(n + 8) {
    grid-column: 4/7;
  }

  & > :nth-of-type(n + 9) {
    grid-column: 7/10;
  }

  & > :nth-of-type(n + 10) {
    grid-column: 10/13;
  }

  & > :nth-of-type(n + 11) {
    grid-column: span 2;
  }

  grid-template-rows: 168px 250px;
  grid-auto-rows: 168px;
  gap: 8px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-template-rows: repeat(auto-fill, 175px);
    grid-auto-rows: 175px;
    gap: 10px;

    & > * {
      grid-column: auto !important; // Allow items to fill the grid naturally
    }
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(3, minmax(120px, 130px));
    grid-auto-rows: 145px;
    grid-template-rows: 145px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    & > * {
      grid-column: auto !important; // Allow items to fill the grid naturally
    }
  }

  @media (max-width: 400px) {
    grid-template-columns: repeat(2, minmax(90px, 1fr));
    grid-auto-rows: 185px;
    grid-template-rows: 185px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }
`

export const GridSmall = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 164px);
  gap: 8px;
  grid-template-rows: 168px;
  grid-auto-rows: 168px;
  justify-content: center;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-template-rows: repeat(auto-fill, 175px);
    grid-auto-rows: 175px;
    gap: 10px;
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(3, minmax(120px, 130px));
    grid-auto-rows: 145px;
    grid-template-rows: 145px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (max-width: 400px) {
    grid-template-columns: repeat(2, minmax(90px, 1fr));
    grid-auto-rows: 185px;
    grid-template-rows: 185px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }
`

// Custom grids

export const GridSmallCustom = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 250px);
  grid-auto-rows: 256px;
  gap: 8px;
  grid-template-rows: 256px;
  justify-content: center;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fit, minmax(120px, 150px));
    grid-template-rows: repeat(auto-fill, 175px);
    grid-auto-rows: 175px;
    gap: 10px;
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(3, minmax(120px, 130px));
    grid-auto-rows: 145px;
    grid-template-rows: 145px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (max-width: 400px) {
    grid-template-columns: repeat(2, minmax(90px, 1fr));
    grid-auto-rows: 185px;
    grid-template-rows: 185px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }
`

export const GridMediumCustom = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 336px);
  gap: 8px;
  grid-template-rows: 335px;
  justify-content: center;

  @media (max-width: 1100px) {
    justify-content: center;
    grid-template-columns: repeat(2, 220px);
    grid-auto-rows: 240px;
    grid-template-rows: 240px;
    gap: 5px;

    & > :nth-of-type(n + 3) {
      grid-column: 1/3;
    }
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
    grid-auto-rows: 120px;
    grid-template-rows: 220px;
    justify-content: center;
    gap: 5px;
    padding-left: 5px;
    padding-right: 5px;

    & > :nth-of-type(n + 3) {
      grid-column: 1/3;
    }
  }

  @media (max-width: 400px) {
    grid-template-columns: repeat(2, minmax(90px, 1fr));
    grid-auto-rows: 185px;
    grid-template-rows: 185px;
    gap: 5px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }
`
