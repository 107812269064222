import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'

const MenuItem = styled.div`
  ${props =>
    css({
      color: props.variant === 'secondary' ? '#FFF' : 'menu-item',
      py: 0,
      fontWeight: props.variant === 'secondary' ? '500' : '700',
      borderTop: props.border ? '1px solid #2A3149' : 'none',
    })};

  flex-direction: column;
  font-family: Raleway;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  transition: 0.3s;
`

const DropDownButton = styled.div`
  ${props =>
    css({
      transform: props.open ? 'rotate(180deg)' : 'rotate(0deg)',
    })}
  transition: transform 0.3s;
`
const DropDownWrapper = styled.div`
  ${props =>
    css({
      backgroundColor:
        props.variant === 'secondary' ? 'none' : 'menu-item-background-active',
      paddingLeft: props.variant === 'secondary' ? '12px' : '42px',
    })};

  font-size: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;

  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  cursor: initial;
  transition: 0.3s;
`

const DrawerMenuSubitem = styled.div`
  position: relative;

  ${props =>
    css({
      color: props.active ? 'menu-item-color-active' : 'white',
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Raleway',

      '&:before': {
        content: `${props.active ? '""' : 'none'}`,
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        backgroundColor: 'menu-item-color-active',
        position: 'absolute',
        left: '-5%',
        top: '16px',
      },

      '&:hover': {
        color: 'menu-item-color-active',
      },
    })};

  padding: 8px 0;
  transition: 0.3s;
`

export function DrawerMenuItem(props) {
  const Icon = props.icon || null

  if (props.to && !props.items) {
    return (
      <MenuItem border={props.border} variant={props.variant}>
        <Common.Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          padding="8px 12px"
          borderRadius="4px"
          color={props.active ? 'menu-item-color-active' : 'menu-item'}
        >
          <ReactRouter.Link to={props.to}>
            <Common.Box width="100%" display="flex">
              {props.icon && (
                <Common.Box pr={0}>
                  <Icon />
                </Common.Box>
              )}
              {props.children}
            </Common.Box>
          </ReactRouter.Link>
        </Common.Box>
      </MenuItem>
    )
  }

  return (
    <MenuItem
      border={props.border}
      active={props.dropDown === props.name}
      onClick={() => (props.items ? props.onDropDown(props.name) : {})}
    >
      <Common.Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        padding="8px 12px"
        borderRadius="4px"
        backgroundColor={
          props.dropDown === props.name
            ? 'menu-item-background-active'
            : 'transparent'
        }
      >
        <Common.Box width="100%" display="flex">
          {props.icon && (
            <Common.Box pr={0}>
              <Icon />
            </Common.Box>
          )}
          {props.children}
        </Common.Box>

        {props.items && (
          <DropDownButton open={props.dropDown === props.name}>
            <Icons.Chevron />
          </DropDownButton>
        )}
      </Common.Box>

      {props.dropDown === props.name && (
        <DropDownWrapper variant={props.variant}>
          {props.items?.map(item => (
            <DrawerMenuSubitem active={item.active} key={item.label}>
              <ReactRouter.Link to={item.to}>{item.label}</ReactRouter.Link>
            </DrawerMenuSubitem>
          ))}
        </DropDownWrapper>
      )}
    </MenuItem>
  )
}

DrawerMenuItem.propTypes = {
  icon: PropTypes.func,
  children: PropTypes.element,
  dropDown: PropTypes.string,
  onDropDown: PropTypes.func,
  items: PropTypes.array,
  to: PropTypes.string,
  active: PropTypes.bool,
  name: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  border: PropTypes.bool,
}
