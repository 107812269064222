export function makeDataSchema(limits, playerLimitMaximum, loading = false) {
  if (loading) {
    return
  }

  return {
    properties: {
      amount: {
        type: 'number',
        maximum: Math.min(limits.max, playerLimitMaximum),
        minimum: limits.min,
      },
    },
    required: ['amount'],
    type: 'object',
  }
}
