import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Url from 'url'

import * as Herz from '@rushplay/herz'

import * as Configuration from './configuration'
import * as Player from './player'
import { useServerConfiguration } from './server-configuration'

export function useTransactionAmountLimits(transactionType) {
  const [limits, setLimits] = React.useState({})
  const [loading, setLoading] = React.useState(true)

  const { token } = Herz.Auth.useSession()
  const serverConfig = useServerConfiguration()

  const payerConfig = ReactRedux.useSelector(state => ({
    host: Configuration.getPayerUrl(state.configuration),
    userId: Player.getUsername(state.player),
    countryCode: Player.getCountryCode(state.player),
  }))

  React.useEffect(() => {
    const transactionTypeUrl =
      transactionType === 'redeem' ? 'withdrawal' : 'deposit'
    const controller = new AbortController()
    const payerUrl = Url.format({
      pathname: '/api/transaction-limits',
      query: {
        transaction_type: transactionTypeUrl,
        country_code: payerConfig.countryCode || serverConfig.country.alpha2,
        token,
        user_id: payerConfig.userId,
      },
    })

    fetch(`${payerConfig.host}${payerUrl}`, {
      method: 'GET',
      signal: controller.signal,
    })
      .then(res => res.json())
      .then(body => {
        setLimits({
          max: R.pathOr(Number.MAX_SAFE_INTEGER, ['data', 'max'], body),
          min: R.pathOr(0, ['data', 'min'], body),
        })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })

    // Cancels request if longer than 5s
    const id = setTimeout(() => controller.abort(), 5000)

    return () => {
      // cancel request if unmounted before we get a response
      controller.abort()
      clearTimeout(id)
    }
  }, [
    payerConfig.host,
    payerConfig.countryCode,
    payerConfig.userId,
    token,
    transactionType,
  ])

  return { limits, loading }
}
