import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Statistics from './statistics'
import { Amount } from './amount'

function getBackground(place) {
  if (place === 1) {
    return 'linear-gradient(94deg, #DC4718 0.32%, #D5856F 100%)'
  }
  if (place === 2) {
    return 'linear-gradient(94deg, #DC8218 0.32%, #D7B332 100%)'
  }
  if (place === 3) {
    return 'linear-gradient(94deg, #DCB119 0.32%, #B38C00 100%)'
  }
  if (place > 10) {
    return 'linear-gradient(94deg, #5718DC 0.32%, #1849DC 100%)'
  }

  return 'radial-gradient(89.53% 92.72% at 50% 117.43%, rgba(35, 57, 128, 0.2) 0%, rgba(17, 21, 64, 0.2) 100%)'
}

const RacesHeadingRow = styled(Common.Box)`
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 3fr;
  gap: 10px;
  width: 100%;
  ${css({
    p: 1,
  })}
`

const HeadingRowItem = styled(Common.Box)`
  display: flex;
  align-items: center;
`

const RacesRow = styled(Common.Box, {
  shouldForwardProp: Common.noneOf(['place']),
})`
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 3fr;
  gap: 10px;
  font-size: 12px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  ${props =>
    css({
      p: 1,
      my: 0,
      background: getBackground(props.place),
      backgroundPosition: 'top',
    })}
`

const RowItem = styled(Common.Box)`
  display: flex;
  align-items: center;
  color: #fff;
  ${props =>
    css({
      fontWeight: props.bold ? 'bold' : 'normal',
    })}
`

const BigWinsRow = styled(Common.Box)`
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 2fr;
  gap: 10px;
  font-size: 12px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  ${() =>
    css({
      p: 1,
      my: 0,
      background:
        'radial-gradient(89.53% 92.72% at 50% 117.43%, rgba(35, 57, 128, 0.2) 0%, rgba(17, 21, 64, 0.2) 100%)',
      backgroundPosition: 'top',
    })}
`

function getTranslationKey(props) {
  if (!props || typeof props.kind !== 'string') {
    return
  }

  switch (props?.kind?.toLowerCase()) {
    case 'freespins': {
      return `leaderboard.prize.${Herz.Utils.Strings.toKebabCase(props?.kind)}`
    }

    case 'featuretriggers': {
      return `leaderboard.prize.${Herz.Utils.Strings.toKebabCase(props?.kind)}${
        props.zeroWagering ? '.zero-wager' : ''
      }`
    }

    default: {
      return `leaderboard.prize.${Herz.Utils.Strings.toKebabCase(props?.kind)}`
    }
  }
}

function getPrizeInformation(prize = [], translate) {
  if (!prize.length) {
    return '-'
  }
  return prize.reduce((acc, prize, index) => {
    if (prize.length === 1) {
      return translate(getTranslationKey(prize), {
        amount: prize.amount,
        currency: prize.currency,
      })
    }

    if (index > 0) {
      return `${acc} + ${translate(getTranslationKey(prize), {
        amount: prize.amount,
        currency: prize.currency,
      })}`
    }

    return translate(getTranslationKey(prize), {
      amount: prize.amount,
      currency: prize.currency,
    })
  }, '')
}

function getGameName(gameName) {
  if (!gameName) {
    return '-'
  }

  if (gameName === 'Delasport') {
    return 'Sportsbook'
  }

  return gameName
}

function Record(props) {
  const translate = Herz.I18n.useTranslate()

  if (props.bigWins) {
    return (
      <BigWinsRow>
        <RowItem>{props.player || '-'}</RowItem>
        <RowItem bold>{getGameName(props.gameName)}</RowItem>
        <RowItem>
          <Amount decimals="show" currency={props.currency}>
            {props.score || '-'}
          </Amount>
        </RowItem>
        <RowItem>
          {props.winRatio ? `${Math.round(props.winRatio)}x` : '-'}
        </RowItem>
      </BigWinsRow>
    )
  }

  const prizeInformation = getPrizeInformation(props.prize, translate)

  return (
    <RacesRow place={props.place}>
      <RowItem bold>{props.place || '-'}</RowItem>
      <RowItem bold>{props.player || '-'}</RowItem>
      <RowItem>{props.score || '-'}</RowItem>
      <RowItem bold>{prizeInformation}</RowItem>
    </RacesRow>
  )
}

Record.propTypes = {
  bigWins: PropTypes.bool,
  gameName: PropTypes.string,
  place: PropTypes.number,
  player: PropTypes.string,
  currency: PropTypes.string,
  prize: PropTypes.object,
  winRatio: PropTypes.number,
  score: PropTypes.number,
}

function Placeholder(props) {
  if (props.recordCount >= 10) {
    return null
  }

  const array = Array.from({ length: 10 - props.recordCount }, () => uuidv4())

  return (
    <React.Fragment>
      {array.map(item => (
        <Record key={item} bigWins={props.bigWins} />
      ))}
    </React.Fragment>
  )
}

Placeholder.propTypes = {
  bigWins: PropTypes.bool,
  recordCount: PropTypes.number,
}

function PlayerRecord(props) {
  const translate = Herz.I18n.useTranslate()

  const prizeInformation = getPrizeInformation(props.prize, translate)
  return (
    <RacesRow place={props.place}>
      <RowItem bold>{props.place || '-'}</RowItem>
      <RowItem bold>{props.player || '-'}</RowItem>
      <RowItem>{props.score || '-'}</RowItem>
      <RowItem bold>{prizeInformation}</RowItem>
    </RacesRow>
  )
}

PlayerRecord.propTypes = {
  place: PropTypes.number,
  player: PropTypes.string,
  score: PropTypes.number,
  prize: PropTypes.number,
}

const BigWinsHeadingRow = styled(Common.Box)`
  display: grid;
  grid-template-columns: 2fr 4fr 2fr 2fr;
  gap: 10px;
  width: 100%;
  ${css({
    p: 1,
  })}
`

export function RacesBoard(props) {
  const translate = Herz.I18n.useTranslate()

  return (
    <Common.Box>
      <RacesHeadingRow>
        <HeadingRowItem>
          {translate('leaderboard.table.heading.prize')}
        </HeadingRowItem>
        <HeadingRowItem>
          {translate('leaderboard.table.heading.player')}
        </HeadingRowItem>
        <HeadingRowItem>
          {translate('leaderboard.table.heading.score')}
        </HeadingRowItem>
        <HeadingRowItem>
          {translate('leaderboard.table.heading.prize')}
        </HeadingRowItem>
      </RacesHeadingRow>
      {props?.records?.map(([player, score, prize], index) => (
        <Record
          key={`${player}_${score}`}
          place={index + 1}
          player={player}
          score={score}
          prize={prize}
        />
      ))}
      <Placeholder recordCount={props.records?.length} />
      {Boolean(props.player?.playerRank) && props.player?.playerRank > 10 ? (
        <PlayerRecord
          player={props.player.username}
          place={props.player.playerRank}
          score={props.player.playerProgress}
          prize={props.player.playerRewards}
        />
      ) : null}
    </Common.Box>
  )
}

RacesBoard.propTypes = {
  records: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  player: PropTypes.shape({
    playerRank: PropTypes.number,
    username: PropTypes.string,
    playerProgress: PropTypes.number,
    playerRewards: PropTypes.array,
  }),
}

export function BigWinsBoard() {
  const translate = Herz.I18n.useTranslate()
  const newestBigWins = ReactRedux.useSelector(state =>
    Statistics.getNewestBigSettles(state.statistics)
  )

  return (
    <Common.Box>
      <BigWinsHeadingRow>
        <HeadingRowItem>
          {translate('leaderboard.table.heading.player')}
        </HeadingRowItem>
        <HeadingRowItem>
          {translate('leaderboard.table.heading.game-title')}
        </HeadingRowItem>
        <HeadingRowItem>
          {translate('leaderboard.table.heading.win')}
        </HeadingRowItem>
        <HeadingRowItem>
          {translate('leaderboard.table.heading.muliplier')}
        </HeadingRowItem>
      </BigWinsHeadingRow>
      {newestBigWins.map(
        ({
          username,
          gameName,
          winRatio,
          amountCents,
          displayName,
          currency,
          settleTimestamp,
        }) => {
          const timestamp = settleTimestamp.replaceAll(' ', '-')
          return (
            <Record
              key={`${username}_${gameName}_${winRatio}_${amountCents}_${timestamp}`}
              player={username || displayName}
              score={amountCents}
              gameName={gameName}
              currency={currency}
              winRatio={winRatio}
              bigWins
            />
          )
        }
      )}
      <Placeholder recordCount={newestBigWins.length} bigwins />
    </Common.Box>
  )
}
