import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import { Button } from './button'
import { HtmlContent } from './html-content'
import { useSafeUpdateQuery } from './use-safe-update-query'

const GradientText = styled.h3`
  font-family: Rubik;
  background: linear-gradient(#ffffff, #9090ff);
  font-size: 18px;
  text-align: start;
  font-weight: 800;
  line-height: 1 !important;
  text-transform: uppercase;
  padding-bottom: 10px;

  ${css({
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  })};

  & span {
    color: #4ae948;
    ${css({
      '-webkit-text-fill-color': '#4AE948',
      background: 'none!important',
    })};
  }
`

export function InviteAFriend() {
  const translate = Herz.I18n.useTranslate()
  const inviteQuery = useSafeUpdateQuery({ invite: 'friend', menu: null })
  return (
    <Common.Box position="relative" width="330px" ml="2px">
      <Common.Box
        position="absolute"
        bottom="-10%"
        right="-3%"
        width="143px"
        height="138px"
        background="url(/images/invite.png) no-repeat center"
        backgroundSize="contain"
      />
      <Common.Box
        display="grid"
        gridTemplateColumns="60% 40%"
        background="radial-gradient(89.53% 92.72% at 50% 117.43%, #233980 0%, #111540 100%)"
        boxShadow="0px 0px 2px 0px #FFF, 0px 0px 4px 0px #1849DC"
        border="1px solid #1849DC"
        borderRadius="14px"
        width="330px"
      >
        <Common.Box p="14px" display="flex" flexDirection="column">
          <GradientText>
            <HtmlContent
              html={{
                __html: translate('invite-a-friend.a-friend', {
                  amount: 50,
                }),
              }}
            />
          </GradientText>
          <Common.Box as={ReactRouter.Link} pr="1" to={`?${inviteQuery}`}>
            <Button variant="primary" stretch>
              {translate('invite-a-friend.invite')}
            </Button>
          </Common.Box>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  ['invite-a-friend.invite', 'invite-a-friend.a-friend'],
  InviteAFriend
)
