import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Herz from '@rushplay/herz'

import * as Configuration from './configuration'
import * as Player from './player'

export function useFetchPackages() {
  /**
   * @type Array
   */
  const [packages, setPackages] = React.useState([])
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState(null)
  const { token } = Herz.Auth.useSession()
  const depositCount = ReactRedux.useSelector(state =>
    Player.getDepositCount(state.player)
  )

  const apiUrl = ReactRedux.useSelector(state =>
    Configuration.getApiUrl(state.configuration)
  )

  React.useEffect(() => {
    if (apiUrl) {
      setFetching(true)
      fetch(`${apiUrl}/coin_store/packages`, {
        headers: {
          Accept: 'application/vnd.casinosaga.v1',
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(data => {
          setPackages(data)
          setFetching(false)
        })
        .catch(err => setError(err))
    }
  }, [apiUrl, depositCount])

  return {
    packages,
    fetching,
    error,
  }
}
