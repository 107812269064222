import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import * as React from 'react'
import querystring from 'querystring'

import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'

import * as Constants from './constants'
import * as Promotions from './promotions'
import * as Theming from './theming'
import { QueryDrawer } from './query-drawer'
import { Transaction, formName, makeDataSchema } from './transaction'
import { useMenuQueries } from './use-menu-queries'
import { usePrev } from './use-prev'

const dataSchemaForKeysPreloading = makeDataSchema(
  { min: 1000, max: 5000 },
  6000
)
const preloadSchemaKeys = Forms.findTranslationKeys(
  formName,
  dataSchemaForKeysPreloading
)

export function Wallet() {
  const translate = Herz.I18n.useTranslate()
  const location = ReactRouter.useLocation()
  const dispatch = ReactRedux.useDispatch()
  const { authenticated } = Herz.Auth.useSession()
  const history = ReactRouter.useHistory()
  const query = querystring.parse(location?.search?.substring(1))
  const prevQuery = usePrev(query)
  const [step, setStep] = React.useState(Constants.TransactionStep.Amount)
  const [amount, setAmount] = React.useState(0)
  const [firstLaunch, setFirstLaunch] = React.useState(true)
  const { loginQuery } = useMenuQueries()

  // Fetch promotions on wallet open
  // TODO: remove when https://app.clickup.com/t/aprr3d is done
  React.useEffect(() => {
    if (prevQuery !== query && query.wallet) {
      dispatch(Promotions.fetch())
    }
  }, [query, prevQuery])

  /** Go out from opted campaigns when wallet closes
   *  or if app loaded for first time without opened wallet
   */
  React.useEffect(() => {
    if (query.wallet === Constants.TransactionType.PURCHASE && query.amount) {
      setAmount(Number.parseFloat(query.amount))
      setStep(Constants.TransactionStep.Payer)
      return
    }

    if ((prevQuery.wallet || firstLaunch) && !query.wallet) {
      setStep(Constants.TransactionStep.Amount)
      if (firstLaunch) {
        setFirstLaunch(false)
      }
    }
  }, [query, prevQuery, firstLaunch])

  function handleStepChange(step, amount) {
    if (!authenticated) {
      return history.push(`?${loginQuery}`)
    }
    setStep(step)

    if (amount) {
      setAmount(amount)
    }
  }

  if (!query.wallet) {
    return null
  }

  const initAmount = query.wallet === 'redeem' ? query.amount : amount

  return (
    <QueryDrawer
      activeQueryName="wallet"
      title={translate(`wallet.${query.wallet}`)}
      additionalQueries={{ amount: null }}
    >
      <Theming.Alternative>
        <Common.Box width="100%" height="100%" pt="50px">
          <Transaction
            onStepChange={handleStepChange}
            amount={initAmount}
            step={step}
            transactionType={query.wallet}
          />
        </Common.Box>
      </Theming.Alternative>
    </QueryDrawer>
  )
}

Herz.I18n.Loader.preload(
  [
    'wallet.purchase',
    'wallet.redeem',
    'transaction-cards.title',
    'transaction-cards.golden-coins',
    'transaction-cards.sweep-coins',
    'transaction-cards.usd',
    'transaction.purchase.error.min-amount',
    'transaction.purchase.error.max-amount',
    ...preloadSchemaKeys,
  ],
  Wallet
)

// for @loadable/component
export default Wallet
