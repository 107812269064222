import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'

import * as Icons from './icons'

const HINTS = {
  passwordLength: /.{8,}/,
  passwordContainsNumber: /\d/,
  passwordContainsCapitalLetter: /[A-Z]+/,
}

export function PasswordHints(props) {
  const translate = Herz.I18n.useTranslate()
  const field = Forms.useField(props.scope, { noRegister: true })

  return (
    <Common.Box mt="1">
      <Common.Box fontSize="12px" lineHeight="120%" color="#AABAEF" mb="12px">
        {translate(`hint.header`)}
      </Common.Box>
      {Object.entries(HINTS).map(([hintKey, hintValue]) => (
        <Common.Box
          key={hintKey}
          display="flex"
          alignItems="center"
          py="4px"
          color="#AABAEF"
        >
          <Common.Box
            mr="4px"
            display="inline-flex"
            color={
              field.value && hintValue.test(field.value)
                ? 'success'
                : 'ebony-clay'
            }
          >
            <Icons.CheckCircle />
          </Common.Box>
          <Common.Text fontSize="12px" lineHeight="120%">
            {translate(`hint.${Herz.Utils.Strings.toKebabCase(hintKey)}`)}
          </Common.Text>
        </Common.Box>
      ))}
    </Common.Box>
  )
}

PasswordHints.propTypes = {
  scope: PropTypes.string.isRequired,
}

Herz.I18n.Loader.preload(
  [
    'hint.header',
    'hint.password-contains-number',
    'hint.password-length',
    'hint.password-contains-capital-letter',
    'hint.password-contains-special-character',
  ],
  PasswordHints
)

export default PasswordHints
