import * as React from 'react'
import * as R from 'ramda'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import { InputField } from '../input-field'
import { SubmitButton } from '../submit-button'
import { useServerConfiguration } from '../server-configuration'

const DECIMAL_SEPARATORS = ['.', ',']

function parseValue(value) {
  const normalizedValue = value.replace(/,/, '.')

  return Number.parseFloat(normalizedValue)
}

function normalizeAmount(value) {
  const amount = parseValue(value)

  if (R.includes(R.last(value), DECIMAL_SEPARATORS)) {
    return value
  }

  if (Number.isNaN(amount)) {
    return 0
  }

  return Math.round(amount * 100)
}

function parseAmount(locale) {
  return value => {
    const amount = value / 100

    if (Boolean(value) && R.includes(R.last(value), DECIMAL_SEPARATORS)) {
      return value
    }

    if (Number.isNaN(amount)) {
      return ''
    }

    const formatter = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      useGrouping: false,
    })

    return formatter.format(amount)
  }
}

/**
 * @name RedeemAmountSection
 *
 * @description Amount section for redeem page
 *
 * @param {object} props
 * @param {function} props.handleSubmit
 * @param {number} props.amount
 * @param {boolean} props.disabled
 *
 * @param {*} props
 * @returns
 */
export function RedeemAmountSection(props) {
  const translate = Herz.I18n.useTranslate()
  const { locale } = useServerConfiguration()

  return (
    <Common.Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      py={2}
      px={['0px', 2]}
    >
      <Common.Box
        maxWidth="420px"
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Common.Box
          as="h3"
          fontSize="18px"
          fontFamily="Raleway"
          fontWeight="800"
          color="white"
          maxWidth="245px"
          lineHeight="normal"
        >
          {translate('redeem-page.amount.title')}
        </Common.Box>
        <Common.Box
          as="img"
          alt="cash"
          src="/images/redeem/cash.png"
          width="180px"
        />
      </Common.Box>

      <Common.Box
        width="100%"
        display="flex"
        flexDirection={['column', 'row']}
        py={[2, 4]}
      >
        <InputField
          disabled={props.disabled}
          currency="SCC"
          inputMode="decimal"
          parse={parseAmount(locale.slug)}
          normalize={normalizeAmount}
          initialValue={props.min || 0}
          scope="#/properties/amount"
          data-testid={`wallet.cash.amount`}
          stretch
        />
        <Common.Box width={['100%', 'auto']} pt="20px" pl={['0px', 0]}>
          <SubmitButton
            customStyle={{
              height: '46px',
              minWidth: '120px',
            }}
            stretch
          >
            {translate('redeem-page.cash-out')}
          </SubmitButton>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

RedeemAmountSection.propTypes = {
  disabled: PropTypes.bool,
  min: PropTypes.number.isRequired,
}
