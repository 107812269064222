import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

import { GradientText } from './gradient-text'

export function SellingPoint(props) {
  return (
    <Common.Box
      background="#0C132B"
      width="300px"
      minHeight="382px"
      boxShadow="0px 0px 4px 0px #1849DC"
      border="1px solid #212836"
      borderRadius="14px"
      position="relative"
      padding="16px 20px"
      display="flex"
      justifyContent="flex-start"
      flexDirection="column"
    >
      {props.src && (
        <Common.Box
          as="img"
          src={props.src}
          position="absolute"
          top="-95px"
          left="0%"
          right="0%"
          alt={props.title}
          with="330px"
          style={{ margin: 'auto' }}
        />
      )}
      <GradientText
        pt="40%"
        fontSize="24px"
        fontFamily="Rubik"
        fontWeight="800"
        maxWidth="250px"
        style={{
          textTransform: 'uppercase',
        }}
      >
        {props.title}
      </GradientText>
      <Common.Box
        style={{ lineHeight: '20px' }}
        as="p"
        pt="8px"
        fontSize="14px"
        fontWeight="400"
        fontFamily="Raleway"
        color="#B2C2EE"
        textAlign="center"
      >
        {props.description}
      </Common.Box>
    </Common.Box>
  )
}

SellingPoint.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}
