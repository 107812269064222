import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

import * as Analytics from './analytics'
import { RealityCheck } from './reality-check-modal'
import { getSafeUpdateQuery } from './use-safe-update-query'
import { useGameInfoUrlQuery } from './use-game-info-url-query'

// Background should always be black. It's game default
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background-color: #000000;
`

export function GameLauncher(props) {
  const dispatch = ReactRedux.useDispatch()
  const iframeRef = React.useRef(null)
  const history = ReactRouter.useHistory()
  const location = ReactRouter.useLocation()
  const { referrerUrl } = useGameInfoUrlQuery()
  React.useEffect(() => {
    function gameEventListener(event) {
      const action = event.data || {}
      switch (action.type) {
        case '@rushplay/gamer/GAME_CLOSED': {
          if (referrerUrl) {
            return history.push(referrerUrl)
          }

          return history.push('/casino')
        }
        case '@rushplay/gamer/BET_HISTORY_OPENED': {
          return history.push('/gambling-history')
        }
        case '@rushplay/gamer/CLASSIC_MODE_OPENED': {
          const query = getSafeUpdateQuery(location, { mode: 'classic' })
          return history.push(`${location.pathname}?${query}`)
        }
        case '@rushplay/gamer/LIMITS_OPENED': {
          return history.push('/account/deposit-limits')
        }
        case '@rushplay/gamer/WALLET_OPENED': {
          const query = getSafeUpdateQuery(location, { wallet: 'deposit' })
          return history.push(`${location.pathname}?${query}`)
        }
        case 'SET_GAME_FULL_SIZE': {
          if (iframeRef.current) {
            iframeRef.current.style.height = `${action.data.height}px`
          }

          return
        }
      }
    }
    window.addEventListener('message', gameEventListener)
    return () => window.removeEventListener('message', gameEventListener)
  }, [])

  React.useEffect(() => {
    if (
      props.game &&
      Object.keys(props.game).length !== 0 &&
      Object.getPrototypeOf(props.game) === Object.prototype
    ) {
      dispatch(
        Analytics.openGame({
          gameCategory: props.game.category,
          gameId: props.game.id,
          gameImage: props.game.imageUrl,
          gameProvider: props.game.provider,
          gameTitle: props.game.title,
        })
      )
    }
  }, [props.game])

  return (
    <Common.Box
      display="flex"
      flexGrow="1"
      flexShrink="1"
      flexBasis="0"
      flexDirection="column"
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <RealityCheck iframeRef={iframeRef} />
      <Iframe
        sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
        src={props.gameUrl}
        ref={iframeRef}
        title={props.title}
      />
    </Common.Box>
  )
}

GameLauncher.defaultProps = {
  title: '',
}

GameLauncher.propTypes = {
  gameUrl: PropTypes.string.isRequired,
  game: PropTypes.object,
  title: PropTypes.string,
}
