import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'

import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import { GameTile } from './casino-categories/game-tile'
import { GameTileSection } from './game-tile-section'
import { LiveCasinoGameTile } from './live-casino-game-tile'
import { useLiveCasinoTablesQuery } from './use-live-casino-tables-query'
import { useMenuQueries } from './use-menu-queries'

const Wrapper = styled.div`
  width: 100%;
  padding-top: 0px;

  @media (max-width: 960px) {
    max-width: 500px;
    margin: 0 auto;
  }
`

export function LiveCasinoCategoryLandingPage() {
  const { authenticated } = Herz.Auth.useSession()
  const { loginQuery } = useMenuQueries()
  const translate = Herz.I18n.useTranslate()
  const location = ReactRouter.useLocation()
  const [liveCasinoResponse, filtredGames] = useLiveCasinoTablesQuery('all')

  if (!liveCasinoResponse.fetching && R.isEmpty(filtredGames)) {
    return null
  }

  return (
    <Wrapper>
      {liveCasinoResponse.fetching ? (
        <GameTileSection
          id="skeleton"
          columns={6}
          count={6}
          skeleton
          layout={'row'}
          size={'custom-small'}
          hideButtonMore
        >
          {new Array(4).fill().map(index => (
            <GameTile
              id={index}
              size={'medium'}
              index={index}
              key={index}
              gameKey={index}
              skeleton
            />
          ))}
        </GameTileSection>
      ) : (
        <GameTileSection
          size="custom-small"
          layout="row"
          sectionTitle={translate(`landing-page.live-casino.section-title`)}
          columns={R.length(filtredGames)}
          hideButtonMore
        >
          {filtredGames?.slice(0, 4).map((game, index) => (
            <ReactRouter.Link
              key={index}
              to={
                authenticated
                  ? `/casino/games/${game.id}?referrer=${R.drop(
                      1,
                      location.pathname
                    )}`
                  : `?${loginQuery}`
              }
              style={{ display: 'inline-grid' }}
            >
              <LiveCasinoGameTile
                hideStatistics
                game={game}
                size="medium-custom"
              />
            </ReactRouter.Link>
          ))}
        </GameTileSection>
      )}
    </Wrapper>
  )
}
