import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

import * as Icons from './icons'

export function ErrorBanner(props) {
  return (
    <Common.Box
      width="100%"
      border="1px solid #EE5482"
      borderRadius="8px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="8px 12px"
      cursor="pointer"
      onClick={props.onClick}
    >
      <Common.Box display="flex" alignItems="center">
        <Icons.ErrSign />
        <Common.Box
          as="p"
          color="#EE5482"
          fontSize="16px"
          fontFamily="Raleway"
          fontWeight="700"
          pl={0}
        >
          {props.message}
        </Common.Box>
      </Common.Box>
      <Common.Box>
        <Icons.ErrNext />
      </Common.Box>
    </Common.Box>
  )
}

ErrorBanner.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
