import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as FramerMotion from 'framer-motion'
import * as Url from 'url'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as Notifications from '@rushplay/notifications'

import * as Configuration from './configuration'
import * as Player from './player'
import { Divider } from './divider'
import { PendingTransaction } from './pending-transaction'

function getPayerConfig(state) {
  return {
    host: Configuration.getPayerUrl(state.configuration),
    userId: Player.getUsername(state.player),
  }
}

export function usePendingTransactions() {
  const [pendingTransactions, setPendingTransactions] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [deleting, setDeleting] = React.useState(false)
  const { token } = Herz.Auth.useSession()

  const config = ReactRedux.useSelector(getPayerConfig)
  const dispatch = ReactRedux.useDispatch()

  const pendingTransactionsUrl = Url.format({
    pathname: '/api/pending-transactions',
    query: {
      token,
      user_id: config.userId,
    },
  })

  const onCancel = React.useCallback(
    (id, onErrorCallback) => {
      const apiUrl = Url.format({
        pathname: `/api/pending-transactions/${id}`,
        query: {
          token,
          user_id: config.userId,
        },
      })

      setDeleting(true)

      dispatch(
        Notifications.add({
          message: `wallet.transaction-cancel-success.withdrawal.content`,
          level: 'success',
        })
      )

      fetch(`${config.host}${apiUrl}`, { method: 'DELETE' })
        .then(() => {
          setDeleting(false)
          return dispatch(Player.fetchWithdrawInformation())
        })
        .catch(() => {
          setDeleting(false)
          onErrorCallback(false)
        })
    },
    [pendingTransactions, config.host, token, config.userId]
  )

  const refetch = React.useCallback(() => {
    fetch(`${config.host}${pendingTransactionsUrl}`, { method: 'GET' })
      .then(res => res.json())
      .then(body => {
        const data = body.data.map(item => ({
          ...item,
          onCancel,
        }))
        setPendingTransactions(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [config.host, pendingTransactionsUrl, onCancel])

  React.useEffect(() => {
    // If there's no userId or token there's no point in fetching
    if ((config.userId || token) && !deleting) {
      const controller = new AbortController()

      fetch(`${config.host}${pendingTransactionsUrl}`, { method: 'GET' })
        .then(res => res.json())
        .then(body => {
          const data = body.data.map(item => ({
            ...item,
            onCancel,
          }))
          setPendingTransactions(data)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })

      const id = setTimeout(() => controller.abort(), 5000)

      return () => {
        clearTimeout(id)
        controller.abort()
      }
    }
  }, [deleting, config.userId, token])

  return { pendingTransactions, loading, refetch }
}

export function PendingTransactions() {
  const translate = Herz.I18n.useTranslate()
  const { pendingTransactions, loading } = usePendingTransactions()

  return (
    <Common.Space pb={0}>
      {R.not(R.isEmpty(pendingTransactions)) && !loading && (
        <Common.Box
          pb={2}
          fontSize={3}
          fontWeight="bold"
          textAlign="center"
          fontFamily="head"
        >
          {translate('pending-withdrawals')}
        </Common.Box>
      )}
      <FramerMotion.AnimatePresence initial={false}>
        {R.map(
          transaction => (
            <FramerMotion.motion.div
              positionTransition
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0.15 } }}
              transition={{ duration: 0.3, delay: 0.25 }}
              key={transaction.transactionId}
            >
              <PendingTransaction
                amount={transaction.amount}
                date={transaction.created}
                provider={transaction.txType}
                onCancel={onErrorCallback =>
                  transaction.onCancel(
                    transaction.transactionId,
                    onErrorCallback
                  )
                }
              />
              <Common.Space py="12px">
                <Divider />
              </Common.Space>
            </FramerMotion.motion.div>
          ),
          pendingTransactions
        )}
      </FramerMotion.AnimatePresence>
    </Common.Space>
  )
}
Herz.I18n.Loader.preload(['pending-withdrawals'], PendingTransactions)
