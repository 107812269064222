import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Processes from '@rushplay/processes'
import loadable from '@loadable/component'
import styled from '@emotion/styled'

import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import * as Theming from '../theming'
import { Button } from '../button'
import { ButtonLoader } from '../button-loader'
import { Checkbox } from '../checkbox'
import { InputField } from '../input-field'
import { InputPasswordField } from '../input-password-field'
import { LoadingNotificationWrapper } from '../loading-notification-wrapper'
import { SelectField } from '../select-field'
import { useServerConfiguration } from '../server-configuration'

const FieldResponse = loadable(() => import('../field-response'))

const preloadRegErrorKeys = [
  'errors.registration.address-base.unique-address',
  'errors.registration.address-international-phone.unique-mobile',
  'errors.registration.address-mobile.format',
  'errors.registration.address-zip.zip-restricted',
  'errors.registration.birthdate.',
  'errors.registration.birthdate.age-restriction',
  'errors.registration.email-uniqueness',
  'errors.registration.username-uniqueness',
  'errors.registration.username-is-inappropriate',
  'errors.registration.email.format',
  'errors.registration.ip-address.uniqueness',
  'errors.registration.lockero-external-id.already-exists',
  'errors.registration.phone-validation.phone-invalid',
  'errors.registration.phone-validation.phone-not-unique',
  'errors.registration.phone-verification-code.invalid',
  'errors.registration.phone-verification.too-many-requests',
]

const StyledLink = styled(ReactRouter.Link)`
  text-decoration: underline;
  color: white;
`

function TermsLabel(props) {
  const translate = Herz.I18n.useTranslate(
    () => ['terms-and-conditions', 'privacy-policy', props.label],
    [props.label]
  )

  return (
    <Common.Box color="white" display="grid" gridGap={1}>
      <Common.Box color="white" style={{ whiteSpace: 'pre-wrap' }}>
        {translate(props.label)}
        <StyledLink to="/terms-and-conditions">
          {translate('terms-and-conditions')}
        </StyledLink>
      </Common.Box>
    </Common.Box>
  )
}

TermsLabel.propTypes = {
  label: PropTypes.string.isRequired,
}

function AllowCheckbox(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })
  const translate = Herz.I18n.useTranslate(() => [field.label], [field.label])

  return (
    <Theming.Primary>
      <Checkbox
        id={field.name}
        name={field.name}
        checked={field.value}
        label={translate(field.label)}
        value={field.value}
        onChange={() => field.onChangeValue(!field.value)}
      />
    </Theming.Primary>
  )
}

AllowCheckbox.propTypes = {
  initialValue: PropTypes.bool,
  scope: PropTypes.string,
}

function TermsCheckBox(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return (
    <>
      <Checkbox
        id={field.name}
        invalid={field.errors?.length > 0 && !props.suppressVisualFeedback}
        name={field.name}
        checked={field.value}
        label={<TermsLabel label={field.label} />}
        value={field.value}
        onChange={() => field.onChangeValue(!field.value)}
      />
      {!props.suppressVisualFeedback && <FieldResponse scope={props.scope} />}
    </>
  )
}

TermsCheckBox.propTypes = {
  initialValue: PropTypes.bool,
  scope: PropTypes.string,
  suppressVisualFeedback: PropTypes.bool,
}

export function RegistrationForm(props) {
  const translate = Herz.I18n.useTranslate()
  const form = Forms.useFormContext()

  const { country } = useServerConfiguration()
  const [cookieData] = Cookies.useCookie(
    Constants.CookieKeys.REGISTRATION_FORM_DATA
  )

  const step1Loading = ReactRedux.useSelector(state => {
    return Processes.isRunning(state.processes, {
      ids: [
        Constants.ProcessesIds.EMAIL_UNIQUENESS_REQUEST,
        Constants.ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST,
        Constants.ProcessesIds.USERNAME_UNIQUENESS_REQUEST,
      ],
    })
  })

  const step2Loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: [Constants.ProcessesIds.REGISTER_REQUEST],
    })
  )

  const callingCodeOptions = React.useMemo(
    () =>
      props.countryCallingCodes?.map(item => ({
        value: item,
        label: `+${item}`,
      })),
    [props.countryCallingCodes]
  )

  const countryOptions = React.useMemo(
    () =>
      props.countryNames?.map(item => ({
        value: item,
        label: `register.country.${item.toLowerCase().replace(' ', '-')}`,
      })),
    [props.countryNames]
  )

  return (
    <React.Fragment>
      {props.step === Constants.RegistrationStep.Credentials && (
        <Common.Box display="grid" gridGap={1}>
          <InputField
            customErrorKey={props.usernameErrorKey}
            inputMode="username"
            scope="#/properties/username"
            suppressVisualFeedback={!props.showVisualFeedback}
            onClearCustomErrorKey={props.onClearUsernameErrorKey}
          />
          <InputField
            autoComplete="email"
            customErrorKey={props.emailErrorKey}
            inputMode="email"
            initialValue={cookieData?.email ?? ''}
            scope="#/properties/email"
            suppressVisualFeedback={!props.showVisualFeedback}
            onClearCustomErrorKey={props.onClearEmailErrorKey}
          />
          <InputPasswordField
            autoComplete="new-password"
            initialValue={cookieData?.password ?? ''}
            scope="#/properties/password"
            suppressVisualFeedback={!props.showVisualFeedback}
          />

          <LoadingNotificationWrapper
            shouldDisplay={step1Loading}
            name={form.name}
          >
            <Common.Box pt={0} width="100%">
              <Button
                stretch
                fontSize="14px"
                loading={step1Loading}
                type="submit"
                variant="primary"
                disabled={step1Loading}
              >
                {step1Loading && !props.social ? (
                  <Common.Box py="4px" width="100%">
                    <ButtonLoader />
                  </Common.Box>
                ) : (
                  <Common.Box py="6.5px">
                    {translate('register.step1.cta')}
                  </Common.Box>
                )}
              </Button>
            </Common.Box>
          </LoadingNotificationWrapper>
        </Common.Box>
      )}

      {props.step === Constants.RegistrationStep.Identity && (
        <Common.Box display="grid" gridGap={1}>
          {props.social && (
            <InputField
              customErrorKey={props.usernameErrorKey}
              autoComplete="username"
              scope="#/properties/username"
              suppressVisualFeedback={!props.showVisualFeedback}
              initialValue={props.initialValues?.player?.username || ''}
              onClearCustomErrorKey={props.onClearUsernameErrorKey}
            />
          )}
          <Common.Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gridGap="8px"
            alignItems="start"
          >
            <InputField
              autoComplete="given-name"
              initialValue={props.initialValues?.player?.first_name || ''}
              scope="#/properties/firstName"
              suppressVisualFeedback={!props.showVisualFeedback}
            />
            <InputField
              autocomplete="family-name"
              scope="#/properties/lastName"
              initialValue={props.initialValues?.player?.last_name || ''}
              suppressVisualFeedback={!props.showVisualFeedback}
            />
          </Common.Box>
          <Common.Box>
            <Common.Box
              fontSize="12px"
              fontFamily="body"
              py="0.35em"
              color="input-text"
            >
              {translate('registration.birthdate')}
            </Common.Box>
            <Common.Box
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              gridGap="8px"
              alignItems="start"
            >
              <SelectField
                contentTranslated
                autoComplete="bday-month"
                inputMode="numeric"
                maxLength="2"
                initialValue={props.initialValues?.player?.bday_month || '1'}
                scope="#/properties/bdayMonth"
                suppressVisualFeedback={!props.showVisualFeedback}
                options={Constants.MONTHS}
                hideLabel
              />
              <SelectField
                contentTranslated
                autoComplete="bday-day"
                inputMode="numeric"
                maxLength="2"
                scope="#/properties/bdayDay"
                initialValue={props.initialValues?.player?.bday_day || '1'}
                suppressVisualFeedback={!props.showVisualFeedback}
                options={Constants.DAYS}
                hideLabel
              />
              <InputField
                autoComplete="bday-year"
                inputMode="numeric"
                maxLength="4"
                scope="#/properties/bdayYear"
                initialValue={props.initialValues?.player?.bday_year || ''}
                suppressVisualFeedback={!props.showVisualFeedback}
                hideLabel
              />
            </Common.Box>
          </Common.Box>

          <InputField
            autoComplete="street-address"
            scope="#/properties/street"
            suppressVisualFeedback={!props.showVisualFeedback}
            initialValue={props.initialValues?.player?.street || ''}
          />
          <Common.Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gridGap="8px"
            alignItems="start"
          >
            <SelectField
              disabled={countryOptions?.length === 1}
              autoComplete="country-name"
              initialValue={country.name}
              options={countryOptions}
              scope="#/properties/country"
              suppressVisualFeedback={!props.showVisualFeedback}
            />

            <SelectField
              contentTranslated
              autoComplete="address-level1"
              options={Constants.USA_STATES}
              initialValue={Constants.USA_STATES[0].value}
              scope="#/properties/state"
              suppressVisualFeedback={!props.showVisualFeedback}
            />
          </Common.Box>

          <Common.Box
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            gridGap="8px"
            alignItems="start"
          >
            <InputField
              autoComplete="address-level2"
              scope="#/properties/city"
              initialValue={props.initialValues?.player?.city || ''}
              suppressVisualFeedback={!props.showVisualFeedback}
            />
            <InputField
              scope="#/properties/zip"
              initialValue={
                props.initialValues?.player?.zip ||
                props.initialValues?.player?.postcode ||
                ''
              }
              suppressVisualFeedback={!props.showVisualFeedback}
            />
          </Common.Box>

          <Common.Box
            display="grid"
            gridTemplateColumns="80px 1fr"
            gridGap={0}
            alignItems="start"
          >
            <SelectField
              contentTranslated
              disabled={callingCodeOptions?.length === 1}
              autoComplete="tel-country-code"
              initialValue={callingCodeOptions[0]?.value}
              options={callingCodeOptions}
              scope="#/properties/countryCallingCode"
              suppressVisualFeedback={!props.showVisualFeedback}
            />
            <InputField
              autoComplete="tel"
              customErrorKey={props.phoneErrorKey}
              inputMode="tel"
              maxLength="11"
              scope="#/properties/phonenumber"
              suppressVisualFeedback={!props.showVisualFeedback}
              onClearCustomErrorKey={props.onClearPhoneErrorKey}
            />
          </Common.Box>

          <TermsCheckBox
            initialValue
            scope="#/properties/terms"
            suppressVisualFeedback={!props.showVisualFeedback}
          />
          <AllowCheckbox
            initialValue
            scope="#/properties/promotional"
            suppressVisualFeedback={!props.showVisualFeedback}
          />
          <LoadingNotificationWrapper
            shouldDisplay={step2Loading}
            name={form.name}
          >
            <Common.Box pb={2} width="100%">
              <Button
                stretch
                fontSize="14px"
                loading={step2Loading}
                type="submit"
                variant="primary"
                disabled={step2Loading}
              >
                <Common.Box py="6.5px" width="100%">
                  {step2Loading ? (
                    <Common.Box maxHeight="30px" width="100%">
                      <ButtonLoader />
                    </Common.Box>
                  ) : (
                    translate('register.step2.cta')
                  )}
                </Common.Box>
              </Button>
            </Common.Box>
          </LoadingNotificationWrapper>
        </Common.Box>
      )}
    </React.Fragment>
  )
}

RegistrationForm.propTypes = {
  countryNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  countryCallingCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  emailErrorKey: PropTypes.string,
  phoneErrorKey: PropTypes.string,
  usernameErrorKey: PropTypes.string,
  showVisualFeedback: PropTypes.bool,
  step: PropTypes.number.isRequired,
  onClearEmailErrorKey: PropTypes.func,
  onClearPhoneErrorKey: PropTypes.func,
  onClearUsernameErrorKey: PropTypes.func,
  social: PropTypes.bool,
  initialValues: PropTypes.object,
}

Herz.I18n.Loader.preload(
  [
    'errors.unsupported-country',
    'register.step1.cta',
    'register.step2.cta',
    'registration.switch-method.cta',
    'registration.switch-method.content',
    'registration.personal-info',
    'registration.location',
    'registration.birthdate',
    ...preloadRegErrorKeys,
  ],
  RegistrationForm
)
