import * as React from 'react'
import PropTypes from 'prop-types'
import { forceCheck } from 'react-lazyload'

import * as common from '@rushplay/common'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const loading = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`

const Loader = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #202836 25%, #333a46 50%, #232b3a 75%);
  background-size: 200px 100%;
  animation: ${loading} 1.5s infinite;
`

export function GameTileLoading(props) {
  // The forceCheck is set on a timeout to allow Lazyload to
  // setup it's listeners properly before we trigger the event
  // Otherwise the event is never picked up and thus not updating the component..
  React.useEffect(() => {
    const id = setTimeout(() => {
      forceCheck()
    }, 10)

    return () => {
      clearTimeout(id)
    }
  }, [])

  return (
    <common.Box
      fontSize={8}
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      flexDirection="column"
      height={props.height}
      color="rgba(255, 255, 255, 0.75)"
      borderRadius={1}
      backgroundColor="hsl(218.18deg 25.58% 16.86%)"
      position="relative"
    >
      <Loader />
    </common.Box>
  )
}

GameTileLoading.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  title: PropTypes.string,
}
