import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Processes from '@rushplay/processes'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'
import { ProcessesIds } from './constants'
import { transformDataToQueryString } from './utils'
import { useFacebookSignin } from './use-facebook-signing'
import { useGoogleOAuthSigninConsent } from './use-google-oauth-signin-consent'
import { useLogin } from './use-login'

const StyledDivider = styled('div')`
  width: 100%;
  color: #bbb;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: center;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    width: 25%;
    margin: 3px 8px;
    border-bottom: 1px solid #bbb;
  }
`

export const SocialLoginButton = styled('button')`
  ${props =>
    css({
      cursor: props.loading || props.disabled ? 'not-allowed' : 'pointer',
      opacity: props.loading || props.disabled ? 0.5 : 1,
      pointerEvents: props.loading || props.disabled ? 'none' : 'auto',
    })};

  display: flex;
  width: 100%;
  padding: 12px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  color: white;
  background: #2a3149;
  border-radius: 6px;
  border: none;
  outline: none;
`

/**
 * @name GoolgeLoginButton
 * @param {Object} props
 * @param {Function} props.onClick
 * @returns
 * @example
 * <GoolgeLoginButton onClick={handleGoogleLogin}/>
 */
export function GoolgeLoginButton(props) {
  return (
    <SocialLoginButton
      loading={props.loading}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Common.Box fontSize="20px">
        <Icons.Google />
      </Common.Box>
      Google
    </SocialLoginButton>
  )
}

GoolgeLoginButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

/**
 * @name FacebookLoginButton
 * @param {Object} props
 * @param {Function} props.onClick
 * @returns
 * @example
 * <FacebookLoginButton onClick={handleFacebookLogin}/>
 */
export function FacebookLoginButton(props) {
  return (
    <SocialLoginButton
      loading={props.loading}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Common.Box fontSize="20px">
        <Icons.Facebook />
      </Common.Box>
      Facebook
    </SocialLoginButton>
  )
}

FacebookLoginButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

/**
 *
 * @param {*} props
 * @param {Boolean} props.hidden
 * @param {Boolean} props.disabled
 * @param {Boolean} props.loading
 * @example
 *
 * const [loading, setLoading] = React.useState(false)
 * const [locks] = React.useState(false)
 *
 * <SocialLogin hidden={false} disabled={locks} loading={loading} />
 *
 * @returns
 */

export function SocialLogin(props) {
  const [provider, setProvider] = React.useState(null)
  const history = ReactRouter.useHistory()
  const dispatch = ReactRedux.useDispatch()
  const loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: [ProcessesIds.LOGIN_REQUEST],
    })
  )

  const { handleLogin } = useLogin()

  const googleOAuth = useGoogleOAuthSigninConsent({
    onSuccess: handleSuccess,
    onError: handleError,
    handdleClose: () => dispatch(Processes.stop('LOGIN_LOADING')),
  })

  const facebookOAuth = useFacebookSignin({
    onSuccess: handleSuccess,
    onError: handleError,
    handleClosed: () => dispatch(Processes.stop('LOGIN_LOADING')),
  })

  /**
   * Social login success handler
   * @param {*} data => Success | Error response
   * @returns
   */
  function handleSuccess(data) {
    if (data && data.session_token) {
      return handleLogin(data.session_token)
    }

    if (data.errors) {
      const transformData = transformDataToQueryString(data)
      const paramsString = `/?register=social&params=${transformData}&token=${data.token}&provider=${provider}&access_token=${data.access_token}`
      return history.push(paramsString)
    }
  }

  /**
   * Social login error handler
   * @returns
   */
  function handleError() {
    return dispatch(Processes.stop('LOGIN_LOADING'))
  }

  function handleFacebookLogin(e) {
    e.preventDefault()
    // start facebook oauth iframe
    facebookOAuth.handleLogin()
    setProvider('facebook')
  }

  function handleGoogleLogin(e) {
    e.preventDefault()
    // start google oauth iframe
    googleOAuth.handleLogin()
    setProvider('google')
  }

  if (props.hidden) {
    return null
  }

  return (
    <Common.Box width="100%" display="flex" flexDirection="column">
      <Common.Box width="100%" py={1}>
        <StyledDivider>or connect with</StyledDivider>
      </Common.Box>

      <Common.Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          gap: '16px',
        }}
      >
        <FacebookLoginButton
          onClick={handleFacebookLogin}
          loading={loading}
          disabled={props.disabled}
        />
        <GoolgeLoginButton
          onClick={handleGoogleLogin}
          loading={loading}
          disabled={props.disabled}
        />
      </Common.Box>
    </Common.Box>
  )
}

SocialLogin.propTypes = {
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}
