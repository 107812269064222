import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Constants from '../constants'
import Leaderboard from '../leaderboard'
import { GameProviderImages } from '../game-provider-images'
import { LandingPageCategory } from '../landing-page-category'
import { LiveCasinoBanner } from '../banners/live-casino-banner'
import { LiveCasinoCategoryLandingPage } from '../live-casino-landing-page'
import { Scrollable } from '../scrollable'
import { SellingPoint } from '../selling-points'
import { SeoSection } from '../seo-section'
import { SportsBanner } from '../banners/sport-banner'

import { LandingBanner } from './landing-banner'

export function LandingPage() {
  const translate = Herz.I18n.useTranslate()
  return (
    <Common.Box
      px={0}
      width="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Common.Box maxWidth={Constants.ContentMaxWidth} width="100%">
        <Common.Box width="100%" pt={2}>
          <LandingBanner />
        </Common.Box>
        <Common.Box
          pt={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mx="auto"
          maxWidth={Constants.ContentMaxWidth}
        >
          <Common.Box width="100%" mx="auto">
            <LandingPageCategory />
          </Common.Box>
          <Common.Box width="100%" py={0}>
            <LiveCasinoBanner />
          </Common.Box>
          <Common.Box width="100%" pb={1}>
            <LiveCasinoCategoryLandingPage />
          </Common.Box>
          <Common.Box width="100%" py={0}>
            <SportsBanner />
          </Common.Box>
          <Common.Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            pt="41px"
            mt={6}
            width="100%"
            as="section"
            style={{
              overflowX: 'clip',
            }}
          >
            <Common.Box
              py={1}
              px={0}
              width="100%"
              display="flex"
              justifyContent={['center', 'center', 'space-between']}
              alignItems="center"
              style={{ gap: '15px', rowGap: '120px' }}
              flexWrap="wrap"
            >
              <SellingPoint
                src="/images/landing-page/improve-chance.png"
                title={translate('landing-page.usp.title.1')}
                description={translate('landing-page.usp.description.1')}
              />
              <SellingPoint
                src="/images/landing-page/instant-redemption.png"
                title={translate('landing-page.usp.title.2')}
                description={translate('landing-page.usp.description.2')}
              />
              <SellingPoint
                src="/images/landing-page/service-24-7.png"
                title={translate('landing-page.usp.title.3')}
                description={translate('landing-page.usp.description.3')}
              />
            </Common.Box>
          </Common.Box>
        </Common.Box>
        <Common.Box pt={2} mx="auto" maxWidth={Constants.ContentMaxWidth}>
          <Leaderboard />
        </Common.Box>
      </Common.Box>
      <Scrollable py={4}>
        <GameProviderImages />
      </Scrollable>
      {translate('landing-page.seo-content') ? (
        <Common.Box width="100%" maxWidth={Constants.ContentMaxWidth}>
          <SeoSection content={translate('landing-page.seo-content')} />
        </Common.Box>
      ) : null}
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'landing-page.usp.title.1',
    'landing-page.usp.title.2',
    'landing-page.usp.title.3',
    'landing-page.usp.title.4',
    'landing-page.usp.description.1',
    'landing-page.usp.description.2',
    'landing-page.usp.description.3',
    'landing-page.usp.description.4',
    'landing-page.usp.header',
    'landing-page.features.header',
    'landing-page.features.title.1',
    'landing-page.features.title.2',
    'landing-page.features.title.3',
    'landing-page.features.description.1',
    'landing-page.features.description.2',
    'landing-page.features.description.3',
    'landing-page.live-casino-banner.overlay.2',
    'landing-page.live-casino-banner.overlay.1',
    'landing-page.live-casino-banner.bakground',
    'landing-page.seo-content',
    'sports.banner.title',
    'sports.banner.background',
    'sports.banner.button',
    'game-tile-section.show-more',
    'game-tile-section.live.header',
    'lobby.sport-casino-banner.title',
    'lobby.sport-casino-banner.description',
    'lobby.sport-casino-banner.button',
    'lobby.sport-casino-banner.background.mobile',
    'lobby.sport-casino-banner.background',
    'lobby.sport-casino-banner.overlay.1.mobile',
    'lobby.sport-casino-banner.overlay.1',
    'lobby.live-casino-banner.title',
    'lobby.live-casino-banner.background.mobile',
    'lobby.live-casino-banner.description',
    'lobby.live-casino-banner.button',
    'lobby.live-casino-banner.background',
    'lobby.live-casino-banner.overlay.1',
    'lobby.live-casino-banner.overlay.1.mobile',
    'lobby.live-casino-banner.background.mobile',
    'landing-page.live-casino.section-title',
  ],
  LandingPage
)

export default LandingPage
