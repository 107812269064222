import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as FramerMotion from 'framer-motion'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'

import * as Constants from './constants'
import * as CombinedSelectors from './combined-selectors'
import * as Player from './player'
import * as Theming from './theming'
import { DepositCampaignOfferPicker } from './deposit-campaign-offer-picker'
import { InputField } from './input-field'
import { SubmitButton } from './submit-button'
import { TransactionCards } from './transaction-cards'
import { WithdrawalLimitInfo } from './withdrawal-limit-info'
import { getSearchQuery } from './get-search-query'
import { useServerConfiguration } from './server-configuration'

const DECIMAL_SEPARATORS = ['.', ',']

function parseValue(value) {
  const normalizedValue = value.replace(/,/, '.')

  return Number.parseFloat(normalizedValue)
}

function normalizeAmount(value) {
  const amount = parseValue(value)

  if (R.includes(R.last(value), DECIMAL_SEPARATORS)) {
    return value
  }

  if (Number.isNaN(amount)) {
    return 0
  }

  return Math.round(amount * 100)
}

function parseAmount(locale) {
  return value => {
    const amount = value / 100

    if (Boolean(value) && R.includes(R.last(value), DECIMAL_SEPARATORS)) {
      return value
    }

    if (Number.isNaN(amount)) {
      return ''
    }

    const formatter = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      useGrouping: false,
    })

    return formatter.format(amount)
  }
}

export function TransactionAmount(props) {
  const translate = Herz.I18n.useTranslate()
  const location = ReactRouter.useLocation()
  const query = getSearchQuery(location)
  const { locale } = useServerConfiguration()
  const scope = '#/properties/amount'
  const isDeposit = props.transactionType === Constants.TransactionType.PURCHASE
  const lastDepositAmount = ReactRedux.useSelector(state =>
    Player.getLastDepositAmount(state.player)
  )
  const firstDeposit = ReactRedux.useSelector(state =>
    Player.getInitialDeposit(state.player)
  )
  const schema = Forms.useFormSchema()
  const requiredMinimum = schema.properties?.amount?.minimum

  const initialValue = React.useMemo(() => {
    const minimum = requiredMinimum ?? 0
    if (isDeposit) {
      return lastDepositAmount
        ? Math.max(lastDepositAmount, minimum)
        : Math.max(firstDeposit, minimum)
    }
    return minimum
  }, [isDeposit, firstDeposit, lastDepositAmount, requiredMinimum])

  const amountField = Forms.useField('#/properties/amount', {
    noRegister: true,
  })
  const amountCents = amountField.value ? amountField.value : 0
  const offers = ReactRedux.useSelector(state =>
    CombinedSelectors.getCalculatedOffers(state, { amountCents })
  )

  return (
    <FramerMotion.AnimatePresence>
      <FramerMotion.motion.div
        positionTransition
        exit={{ opacity: 0 }}
        key="amount-input"
      >
        {isDeposit ? (
          <Common.Box>
            <TransactionCards
              initialValue={query.amount || initialValue}
              onStepChange={props.onStepChange}
            />
          </Common.Box>
        ) : (
          <>
            <Theming.Primary>
              <InputField
                disabled={props.disabled}
                currency="SCC"
                inputMode="decimal"
                parse={parseAmount(locale.slug)}
                normalize={normalizeAmount}
                initialValue={query.amount || initialValue}
                scope={scope}
                data-testid={`wallet.${props.transactionType}.amount`}
              />
            </Theming.Primary>
            {props.offerPicker}
            {isDeposit && <DepositCampaignOfferPicker />}
            <Common.Box pt={2} pb={4} display="flex" justifyContent="center">
              <SubmitButton
                disabled={
                  isDeposit &&
                  !R.isEmpty(offers) &&
                  props.selectedOfferId === undefined
                    ? true
                    : null
                }
                onlyScopes={[scope]}
                data-testid={`wallet.${props.transactionType}.submit`}
              >
                {translate(
                  `transaction.${props.transactionType}.amount.submit`
                )}
              </SubmitButton>
            </Common.Box>
          </>
        )}
        {!isDeposit && <WithdrawalLimitInfo />}
      </FramerMotion.motion.div>
    </FramerMotion.AnimatePresence>
  )
}

TransactionAmount.propTypes = {
  offerPicker: PropTypes.node,
  selectedOfferId: PropTypes.number,
  transactionType: PropTypes.oneOf([
    Constants.TransactionType.AUTH,
    Constants.TransactionType.PURCHASE,
    Constants.TransactionType.REDEEM,
  ]).isRequired,
  disabled: PropTypes.bool.isRequired,
  onStepChange: PropTypes.func.isRequired,
}

Herz.I18n.Loader.preload(
  [
    ...Object.values(Constants.TransactionType).map(
      type => `transaction.${type}.amount.submit`
    ),
    'transaction.set-limit',
    'transaction.purchase.description',
    'coin-store.bonus',
    'coin-store.gold-coins',
    'transaction.purchase.custom-amount',
    'main-menu.deposit',
    'coins-store.free-bonus',
    'coins-store.input.usd.label',
    'coin-store.bonus.title',
  ],
  TransactionAmount
)

// for @loadable/components
export default TransactionAmount
