import * as R from 'ramda'
import * as React from 'react'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Emotion from '@emotion/core'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import { GameTileLoading } from './game-tile-loading'

function toCssArray(items) {
  return items.join(',')
}

export function titleSizeSmall(size) {
  switch (size) {
    case 'large': {
      return 3
    }
    case 'medium': {
      return 1
    }
    default: {
      return 1
    }
  }
}

export function titleSizeMedium(size) {
  switch (size) {
    case 'large': {
      return 5
    }
    case 'medium': {
      return 3
    }
    default: {
      return 1
    }
  }
}

const StyledBackground = styled('div', {
  shouldForwardProp: Common.noneOf([
    'alignBottom',
    'backgroundSrc',
    'disabled',
    'overlaySrc',
    'overlayBackgroundPosition',
    'size',
  ]),
})`
  position: relative;
  user-select: none;
  ${props => {
    return Emotion.css`
      background-image: url('${props.backgroundSrc}');
      background-position: ${toCssArray(
        props.alignBottom ? ['bottom center', 'center'] : ['center', 'center']
      )};
      background-repeat: no-repeat;
      background-size: cover;
    `
  }};
  ${() => {
    return Emotion.css`
      height: 100%;
    `
  }};
  ${props => props.disabled && 'opacity: 0.5'};
  ${props =>
    css(
      R.mergeAll([
        {
          backgroundColor: 'hsl(218.18deg 25.58% 16.86%)',
          borderRadius: 1,
          position: 'relative',
        },
        props.overlayBackgroundPosition
          ? { paddingTop: [0, 1] }
          : { paddingY: [0, 1], paddingX: [0, 1] },
      ])
    )}
`

/**
 * The background is a composite of game background and overlay image.
 */
export function Background(props) {
  return (
    <StyledBackground
      backgroundSrc={props.backgroundUrl}
      disabled={props.disabled}
      size={props.size}
      stretched={props.stretched}
    >
      {props.children}
    </StyledBackground>
  )
}

Background.propTypes = {
  backgroundUrl: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'picked-games', 'xl']),
  stretched: PropTypes.bool,
}

export function Loader(props) {
  return (
    <LazyLoad
      once
      resize
      offset={200}
      placeholder={
        <GameTileLoading
          id="1"
          title={props.title || 'Loading'}
          height="100%"
        />
      }
    >
      {props.children}
    </LazyLoad>
  )
}

Loader.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'picked-games', 'xl']),
  title: PropTypes.string,
  stretched: PropTypes.bool,
}
