import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as Urql from 'urql'
import PropTypes from 'prop-types'
import { gql } from 'urql'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import * as Icons from './icons'
import { Button } from './button'
import { useMenuQueries } from './use-menu-queries'

export const PLAYER_INFO_INIT_QUERY = gql`
  query PlayerInfoInit {
    player {
      account {
        currency
      }
      allAccounts {
        balanceCents
        bonusBalanceCents
        currency
        eurBalanceCents
        main
        moneyBalanceCents
        selected
        usdBalanceCents
        withdrawBalanceCents
      }
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 20px minmax(150px, 1fr) minmax(100px, 1fr);
  gap: 10px;
  padding-top: 8px;
  padding-bottom: 10px;
`

function Amount(props) {
  const balance = props.balanceCents / 100 || 0
  const amount = balance.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    decimals: 'auto',
  })

  return amount
}

Amount.propTypes = {
  balanceCents: PropTypes.number.isRequired,
}

export function MoneyBalanceDetails() {
  const translate = Herz.I18n.useTranslate()
  const { depositQuery } = useMenuQueries()
  const [{ data, fetching }] = Urql.useQuery({ query: PLAYER_INFO_INIT_QUERY })

  const goldCoins = React.useMemo(
    () =>
      data?.player?.allAccounts?.find(account => account.currency === 'GCC'),
    [data?.player]
  )

  const sweepCoins = React.useMemo(
    () =>
      data?.player?.allAccounts?.find(account => account.currency === 'SCC'),
    [data?.player]
  )

  return (
    <Common.Box
      borderRadius="14px"
      background="#0C132B"
      width="100%"
      padding="12px"
      minHeight="195px"
    >
      {fetching ? (
        <Common.Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Icons.Spinner />
        </Common.Box>
      ) : (
        <>
          <Common.Box width="100%" display="flex" flexDirection="column">
            <Grid>
              <Common.Box
                style={{
                  gridColumn: '1 / span 1',
                  gridRow: '1/1',
                }}
              >
                <Icons.SweepCoin />
              </Common.Box>
              <Common.Box
                style={{
                  gridColumn: '2 / span 3',
                  gridRow: '1/1',
                }}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Common.Box
                  fontFamily="Raleway"
                  fontSize="14px"
                  fontWeight="700"
                  color="#B2C2EE"
                >
                  {translate('main-menu.sweeps-coins')}
                </Common.Box>
                <Common.Box
                  fontWeight="800"
                  fontFamily="Raleway"
                  fontSize="14px"
                  color="white"
                >
                  SC <Amount balanceCents={sweepCoins?.balanceCents || 0} />
                </Common.Box>
              </Common.Box>
              <Common.Box
                style={{
                  gridColumn: '2 / span 3',
                  gridRow: '2/2',
                }}
                display="flex"
                justifyContent="space-between"
              >
                <Common.Box
                  fontFamily="Raleway"
                  fontSize="12px"
                  fontWeight="400"
                  color="#B2C2EE"
                >
                  {translate('main-menu.redeemable')}
                </Common.Box>
                <Common.Box
                  fontWeight="800"
                  fontFamily="Raleway"
                  fontSize="14px"
                  color="white"
                >
                  SC{' '}
                  <Amount
                    balanceCents={sweepCoins?.withdrawBalanceCents || 0}
                  />
                </Common.Box>
              </Common.Box>
              <Common.Box
                style={{
                  gridColumn: '2 / span 3',
                  gridRow: '3/3',
                }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Common.Box
                  fontFamily="Raleway"
                  fontSize="12px"
                  fontWeight="400"
                  color="#B2C2EE"
                >
                  {translate('main-menu.unplayed')}
                </Common.Box>
                <Common.Box
                  fontWeight="800"
                  fontFamily="Raleway"
                  fontSize="14px"
                  color="white"
                >
                  SC{' '}
                  <Amount balanceCents={sweepCoins?.moneyBalanceCents || 0} />
                </Common.Box>
              </Common.Box>
              <Common.Box
                style={{
                  gridColumn: '1 / span 1',
                  gridRow: '4/4',
                }}
              >
                <Icons.GoldCoin />
              </Common.Box>
              <Common.Box
                style={{
                  gridColumn: '2 / span 3',
                  gridRow: '4/4',
                }}
                display="flex"
                justifyContent="space-between"
              >
                <Common.Box
                  fontFamily="Raleway"
                  fontSize="14px"
                  fontWeight="700"
                  color="#B2C2EE"
                >
                  {translate('main-menu.gold-coins')}
                </Common.Box>
                <Common.Box
                  fontWeight="800"
                  fontFamily="Raleway"
                  fontSize="14px"
                  color="white"
                >
                  GC <Amount balanceCents={goldCoins?.balanceCents || 0} />
                </Common.Box>
              </Common.Box>
            </Grid>
          </Common.Box>
          <Common.Box
            width="100%"
            display="grid"
            gridTemplateColumns="1fr 1fr"
            style={{
              gap: '8px',
            }}
          >
            <ReactRouter.Link style={{ width: '100%' }} to={`?${depositQuery}`}>
              <Button stretch variant="primary">
                {translate('main-menu.deposit')}
              </Button>
            </ReactRouter.Link>
            <ReactRouter.Link style={{ width: '100%' }} to={`/redeem`}>
              <Button stretch variant="secondary">
                {translate('main-menu.withdraw')}
              </Button>
            </ReactRouter.Link>
          </Common.Box>
        </>
      )}
    </Common.Box>
  )
}
