import * as R from 'ramda'
import * as urql from 'urql'
import * as React from 'react'
import gql from 'graphql-tag'

const getLiveCasinoTables = gql`
  query {
    liveCasinoTables {
      backgroundUrl
      backgroundOverlayUrl
      backgroundOverlayImageAlignment
      backgroundColor
      betBehind
      colorMode
      dealerName
      gameType
      id
      imageUrl
      maxBetCents
      minBetCents
      seatsAvailable
      seatsTotal
      tableOpen
      tags
      title
      titleUrl
      provider
    }
  }
`

const isTableOpen = game => game.tableOpen === true

const hasSeatsAvailable = game => game.seatsAvailable !== 0

const isOfGameType = category => game =>
  category === 'all' ||
  R.includes(category, game.tags) ||
  game.gameType === category

const groupByGameType = games =>
  R.pipe(
    R.groupBy(R.prop('gameType')),
    R.toPairs,
    R.map(([type, games]) => ({ type, games }))
  )(games)

export function useLiveCasinoTablesQuery(category) {
  const [response] = urql.useQuery({ query: getLiveCasinoTables })
  const [allGames, setAllGames] = React.useState([])
  const [filtredGames, setGames] = React.useState([])

  React.useEffect(() => {
    if (response.data?.liveCasinoTables) {
      setAllGames(groupByGameType(response?.data?.liveCasinoTables))
      setGames(
        R.filter(
          R.allPass([isTableOpen, hasSeatsAvailable, isOfGameType(category)]),
          R.values(response.data?.liveCasinoTables)
        )
      )
    }
  }, [category, response.data?.liveCasinoTables])

  return [
    {
      data: allGames,
      error: response.error,
      fetching: response.fetching,
    },
    filtredGames,
  ]
}
