import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as Notifications from '@rushplay/notifications'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as Configuration from '../configuration'
import * as Player from '../player'
import * as Icons from '../icons'
import { ErrorBanner } from '../error-baner'
import { useMenuQueries } from '../use-menu-queries'
import { usePendingTransactions } from '../pending-transactions'
import { usePrev } from '../use-prev'

import { CTABanner } from './cta-banner'
import { RedeemAmountSection } from './redeem-amount'
import { SweepCoins } from './sweep-coins'

const slideUp = keyframes`
  from {
    transform: translateY(5%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const slideLeft = keyframes`
  from {
    transform: translateX(5%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const AnimatedBox = styled(Common.Box)`
  animation: ${slideUp} 0.2s ease-out;
  position: absolute;
  top: 20px;
  left: 0px;
  font-size: 25px;
  cursor: pointer;
`

const AnimatedSection = styled(Common.Box)`
  animation: ${slideLeft} 0.2s ease-out;
`

export function RedeemComponent(props) {
  const [redeem, setRedeem] = React.useState(false)
  const dispatch = ReactRedux.useDispatch()

  const translate = Herz.I18n.useTranslate()
  const { pendingTransactions, refetch } = usePendingTransactions()
  const history = ReactRouter.useHistory()
  const location = ReactRouter.useLocation()
  const prevQuery = usePrev(location.search)
  const environment = ReactRedux.useSelector(state =>
    Configuration.getEnvironment(state.configuration)
  )

  // For mobile only
  const [
    pendingTransactionDetails,
    setPendingTransactionDetails,
  ] = React.useState(null)

  const { withdrawalQuery } = useMenuQueries()

  React.useEffect(() => {
    const hasWalletClosed =
      prevQuery?.includes('wallet=redeem') &&
      !location.search.includes('wallet=redeem')

    // refetch pending translation after wallet is closed
    if (hasWalletClosed) {
      refetch()
    }
  }, [prevQuery, location.search])

  function handleSubmit(data) {
    history.push(`?${withdrawalQuery}&amount=${data.amount}`)
  }

  function handlePendingTransactionDetails(transactionId) {
    if (pendingTransactionDetails === transactionId) {
      return setPendingTransactionDetails(null)
    }

    return setPendingTransactionDetails(transactionId)
  }

  const handlePrizeout = React.useCallback(async () => {
    const obj = {
      miniWidget: true,
      env: environment === 'production' ? 'prod' : 'sandbox',
      user: {
        firstname: props.firstName,
        lastname: props.lastName,
        year_of_birth: Number(props.birthdate.split('-')[0]),
        gender: 'Female',
        email: props.email,
        user_id: `${props.playerUsername}_${props.playerCurrency}`,
        session_id: props.sessionToken,
        country: props.country,
        balance: props.sweepCoins.withdrawBalanceCents,
      },
      publisher: {
        name: 'Legendz',
        id: props.prizeoutId,
        apikey: props.prizeoutApiKey,
      },
      callbacks: {
        onInit: function(payload) {
          // eslint-disable-next-line no-console
          console.log('Widget Started', payload)
        },
        onClose: function(payload) {
          // eslint-disable-next-line no-console
          console.log('Widget Closed', payload)
          window.location.reload()
        },
        onCashoutFail: function(payload) {
          // eslint-disable-next-line no-console
          console.error('Cashout Failed', payload)
          dispatch(
            Notifications.add({
              message: `errors.${payload.error || 'general.unknown'}`,
              level: 'error',
            })
          )
        },
        onCashoutSuccess: function(payload) {
          // eslint-disable-next-line no-console
          console.log('Cashout Completed', payload)
          dispatch([
            Player.fetchPlayerInfo(),
            Player.fetchWithdrawInformation(),
          ])
        },
      },
    }

    window?.prizeoutSDK?.init(obj)
  }, [
    props.firstName,
    props.lastName,
    props.birthdate,
    props.sessionToken,
    props.prizeoutApiKey,
    props.prizeoutId,
    props.country,
    props.sweepCoins,
    props.playerUsername,
    props.playerCurrency,
  ])

  const handleRedeem = React.useCallback(() => setRedeem(true), [setRedeem])

  return (
    <Common.Box
      pt={1}
      maxWidth="681px"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      position="relative"
    >
      {redeem && (
        <AnimatedBox onClick={() => setRedeem(false)}>
          <Icons.KeyboardArrowLeft />
        </AnimatedBox>
      )}
      <Common.Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWith="520px"
        pt={0}
      >
        <Common.Box
          as="h3"
          color="white"
          fontSize="24px"
          fontWeight="600"
          fontFamily="Raleway"
          textAlign="center"
        >
          {translate('redeem-page.title')}
        </Common.Box>
        {!redeem && (
          <Common.Box
            as="p"
            fontSize="14px"
            fontWeight="400"
            fontFamily="Raleway"
            color="#B2C2EE"
            pt="16px"
            textAlign="center"
            maxWidth="500px"
          >
            {translate('redeem-page.description')}
          </Common.Box>
        )}
      </Common.Box>
      {pendingTransactions.length > 0 &&
        pendingTransactions?.map((transaction, index) => {
          const createdTimestamp = transaction.created
          const [datePart, timePart] = createdTimestamp.split(' ')
          const [year, month, day] = datePart.split('-')
          const formattedDate = `${day}/${month}/${year}`

          return (
            <Common.Box
              key={index}
              width="100%"
              display="flex"
              my={2}
              flexDirection="column"
            >
              <Common.Box
                borderRadius="6px"
                width="100%"
                display="flex"
                padding="16px"
                alignItems="center"
                justifyContent="space-between"
                border="1px solid #2A3149"
                background="#0C132B"
                onClick={() =>
                  handlePendingTransactionDetails(transaction.transactionId)
                }
              >
                <Common.Box display="flex" alignItems="center">
                  <Icons.Pending />
                  <Common.Box
                    as="p"
                    pl="12px"
                    fontSize="16px"
                    color="white"
                    fontFamily="Raleway"
                    fontWeight="700"
                    maxWidth={['150px', 'auto']}
                  >
                    {translate('redeem-page.pending.title')}
                  </Common.Box>
                </Common.Box>
                <Common.Box display="flex" alignItems="center">
                  <Common.Box
                    color="#FFF"
                    fontSize="14px"
                    fontWeight="500"
                    fontFamily="Raleway"
                    pr={2}
                    display={['none', 'block']}
                  >
                    {translate('redeem-page.pending.amount')}
                    <Common.Box as="span" color="white" fontweight="700">
                      {transaction.amount}
                    </Common.Box>
                  </Common.Box>
                  <Common.Box
                    color="#CDCED0"
                    fontSize="12px"
                    fontFamily="Raleway"
                    textAlign="center"
                  >
                    <Common.Box as="p" fontWeight="600">
                      {formattedDate}
                    </Common.Box>
                    <Common.Box as="p" fontWeight="400">
                      {timePart}
                    </Common.Box>
                  </Common.Box>
                  <Common.Box
                    display={['flex', 'none']}
                    pl={1}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <Common.Box
                      style={{
                        transform:
                          pendingTransactionDetails ===
                          transaction.transactionId
                            ? 'rotate(180deg)'
                            : 'none',
                      }}
                    >
                      <Icons.Chevron />
                    </Common.Box>
                  </Common.Box>
                </Common.Box>
              </Common.Box>
              {pendingTransactionDetails &&
                pendingTransactionDetails === transaction.transactionId && (
                  <Common.Box
                    display={['flex', 'none']}
                    padding="20px"
                    width="100%"
                    minHeight="70px"
                  >
                    {transaction.amount}
                  </Common.Box>
                )}
            </Common.Box>
          )
        })}
      {props.notVerified && (
        <Common.Box width="100%" pt={2}>
          <ErrorBanner
            message={translate('redeem-page.documents.request')}
            onClick={() => history.push('/my-identity')}
          />
        </Common.Box>
      )}
      <Common.Box
        width="100%"
        display="flex"
        justifyContent="center"
        pt={[2, 4]}
        pb={[2, 4]}
        alignItems="center"
      >
        {props.fetching ? (
          <Common.Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="108px"
            width="100%"
          >
            <Icons.Spinner />
          </Common.Box>
        ) : (
          <SweepCoins coins={props.sweepCoins} />
        )}
      </Common.Box>
      {redeem && !props.notVerified ? (
        <AnimatedSection>
          <Forms.Provider
            schema={props.dataSchema}
            name="cash-amount"
            onSubmit={(errors, data) => {
              if (Object.keys(errors).length === 0) {
                handleSubmit(data)
              }
            }}
          >
            <RedeemAmountSection
              dataSchema={props.dataSchema}
              min={props.limits?.min}
              handleSubmit={handleSubmit}
            />
          </Forms.Provider>
        </AnimatedSection>
      ) : (
        <>
          <Common.Box
            py={1}
            width="100%"
            display="flex"
            flexWrap="wrap"
            justifyContent={['center', 'space-between']}
            flexDirection={['column', 'row']}
            alignItems="center"
            style={{
              gap: '20px',
            }}
          >
            <CTABanner
              title={translate('redeem-page.cta.cash.title')}
              buttonText={translate('redeem-page.cta.cash.buttonText')}
              src="/images/redeem/cash.png"
              action={() => handleRedeem()}
              disabled={props.disabled}
              loading={props.loading || !props.documents || !props.sweepCoins}
            />
            <CTABanner
              title={translate('redeem-page.cta.prizeout.title')}
              buttonText={translate('redeem-page.cta.prizeout.buttonText')}
              src="/images/redeem/prize.png"
              link={null}
              action={handlePrizeout}
              disabled={props.disabledPrizeout}
              loading={props.loading || !props.documents || !props.sweepCoins}
            />
          </Common.Box>
          <Common.Box
            width="100%"
            py="40px"
            display="flex"
            justifyContent="center"
            fontSize="12px"
            fontWeight="400"
            fontFamily="Raleway"
            color="#CDCED0"
            textAlign="center"
            lineHeight="normal"
          >
            {translate('redeem-page.terms')}
          </Common.Box>
        </>
      )}
    </Common.Box>
  )
}

RedeemComponent.propTypes = {
  data: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  birthdate: PropTypes.string.isRequired,
  playerUsername: PropTypes.string.isRequired,
  playerCurrency: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  notVerified: PropTypes.bool.isRequired,
  dataSchema: PropTypes.object.isRequired,
  disabledPrizeout: PropTypes.bool.isRequired,
  sweepCoins: PropTypes.shape({
    redeemableBalanceCents: PropTypes.number.isRequired,
    withdrawBalanceCents: PropTypes.number.isRequired,
  }),
  fetching: PropTypes.bool.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      verified: PropTypes.bool.isRequired,
    })
  ).isRequired,
  limits: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number,
  }).isRequired,
  sessionToken: PropTypes.string.isRequired,
  prizeoutApiKey: PropTypes.string.isRequired,
  prizeoutId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
}
