import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import { GameTile } from './casino-categories/game-tile'
import { GameTileSection } from './game-tile-section'
import { useLayout } from './use-layout'

const Wrapper = styled.div`
  width: 100%;

  @media (max-width: 960px) {
    max-width: 500px;
    margin: 0 auto;
  }
`

export function CasinoSection(props) {
  const { gamesToRender, tileSize } = useLayout({
    games: props.games,
    tileSize: props.tileSize,
  })
  const translate = Herz.I18n.useTranslate(
    () => [`catalog-sections.${props.categoryKey}-${props.sectionKey}.title`],
    [props.sectionKey, props.categoryKey]
  )

  const count = props.tileSize === 'large' ? 9 : 6

  if (props.skeleton) {
    return (
      <Wrapper>
        <GameTileSection
          id="skeleton"
          layout={props.layout}
          size={props.tileSize}
          count={count}
          skeleton
          hideButtonMore
        >
          {new Array(props.count || 9).fill().map(index => (
            <GameTile
              id={`${index}-id`}
              size={props.tileSize}
              index={index}
              key={index}
              skeleton
            />
          ))}
        </GameTileSection>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <GameTileSection
        id={props.sectionKey}
        columns={R.length(props.games)}
        layout={props.layout}
        sectionTitle={translate(
          `catalog-sections.${props.categoryKey}-${props.sectionKey}.title`
        )}
        size={tileSize}
        hideButtonMore={props.hideButtonMore}
      >
        {gamesToRender?.map((game, index) => {
          return (
            <GameTile
              index={index}
              key={index}
              id={Number.parseInt(game.id)}
              backgroundUrl={game.imageUrl}
              jackpotValue={game.jackpotValue}
              jackpotCurrency={game.jackpotCurrency}
              title={game.title}
              titleUrl={game.titleUrl}
              provider={game.displayProvider || game.provider}
              gameKey={game.key}
              size={tileSize}
            />
          )
        })}
      </GameTileSection>
    </Wrapper>
  )
}

CasinoSection.propTypes = {
  layout: PropTypes.string,
  sectionKey: PropTypes.string,
  games: PropTypes.array,
  tileSize: PropTypes.string,
  title: PropTypes.string,
  skeleton: PropTypes.bool,
  hideButtonMore: PropTypes.bool,
  categoryKey: PropTypes.string,
  count: PropTypes.string,
}
