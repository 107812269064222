import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Player from './player'
import * as Configuration from './configuration'
import { Button } from './button'

const BannerHeading = styled.span`
  background: linear-gradient(180deg, #fff 0%, #9090ff 100%);
  backgroundclip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  font-family: 'Rubik', sans-serif;
  ${css({
    fontSize: 5,
  })};
`

const BlurredBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  left: 15%;
  height: 100%;
  width: 15%;
  background-color: white;
  filter: blur(27px);
`

const StyledImage = styled.img`
  position: absolute;
  top: -37%;
  left: 0;
  height: 175%;
`

const CountdownWrapper = styled.div`
  position: absolute;
  top: -11px;
  right: 15%;
  min-width: 120px;
  padding: 3px 11px;
  background-color: #1849dc;
  color: #fff;
  font-size: 13px;
  border-radius: 10px;
  text-align: center;
  font-size: 13px;
  line-height: normal;
  font-variant-numeric: lining-nums proportional-nums;
`

function getValue(value) {
  return value < 10 || value === 0 ? `0${value}` : value
}

function formatTimeLeft(totalSeconds) {
  const hours = getValue(
    Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60))
  )
  const minutes = getValue(Math.floor((totalSeconds % (60 * 60)) / 60))
  const seconds = getValue(totalSeconds % 60)

  return { hours, minutes, seconds }
}

function calculateInitialTimeLeft(timestamp) {
  const targetDate = new Date(timestamp).getTime()
  const now = Date.now()
  const difference = Math.max(targetDate - now, 0)

  return Math.floor(difference / 1000) // Convert to total seconds
}

function Countdown(props) {
  const requestRef = React.useRef(null)
  const startTimeRef = React.useRef(null)
  const [timeLeft, setTimeLeft] = React.useState(
    calculateInitialTimeLeft(props.timestamp)
  )

  function updateCountdown() {
    const elapsedTime = Math.floor((Date.now() - startTimeRef.current) / 1000)
    const newTimeLeft = Math.max(timeLeft - elapsedTime, 0)

    setTimeLeft(newTimeLeft)
    if (newTimeLeft <= 0) {
      props.handleShouldDisableButton(false)
      return cancelAnimationFrame(requestRef.current)
    }
    requestRef.current = requestAnimationFrame(updateCountdown)
    props.handleShouldDisableButton(true)
  }

  React.useEffect(() => {
    startTimeRef.current = Date.now()
    requestRef.current = requestAnimationFrame(updateCountdown)

    // Clean up on unmount
    return () => cancelAnimationFrame(requestRef.current)
  }, [timeLeft])

  if (timeLeft <= 0) {
    return null
  }

  const formattedTimeLeft = formatTimeLeft(timeLeft)

  return (
    <CountdownWrapper>
      {formattedTimeLeft.hours}:{formattedTimeLeft.minutes}:
      {formattedTimeLeft.seconds}
    </CountdownWrapper>
  )
}

Countdown.propTypes = {
  handleShouldDisableButton: PropTypes.func.isRequired,
  timestamp: PropTypes.string.isRequired,
}

export function DailyRewardBanner() {
  const history = ReactRouter.useHistory()
  const { authenticated, token } = Herz.Auth.useSession()
  const [timestamp, setTimestamp] = React.useState(null)
  const [shouldDisableButton, setShouldDisableButton] = React.useState(false)

  const translate = Herz.I18n.useTranslate()
  const dailyGameId = ReactRedux.useSelector(state =>
    Configuration.getDailyGameId(state.configuration)
  )
  const apiUrl = ReactRedux.useSelector(state =>
    Configuration.getApiUrl(state.configuration)
  )

  const showDailyRewardGame = ReactRedux.useSelector(state =>
    Player.getShowDailyRewardGame(state.player)
  )

  React.useEffect(() => {
    if (token) {
      fetch(`${apiUrl}/daily_reward`, {
        headers: {
          Authorization: token,
          Accept: 'application/vnd.casinosaga.v1',
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          setTimestamp(data.dailyRewardAvailableAt)
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err)
        })
    }
  }, [token, apiUrl])

  function handlePlay() {
    if (calculateInitialTimeLeft(timestamp) > 0) {
      return
    }
    return history.push(`/casino/games/${dailyGameId}?referrer=casino`)
  }

  if (!authenticated || !showDailyRewardGame || !dailyGameId) {
    return null
  }

  return (
    <Common.Box
      width="100%"
      display="grid"
      gridTemplateColumns="40% 60%"
      backgroundImage="radial-gradient(89.53% 92.72% at 50% 117.43%, #233980 0%, #111540 100%)"
      boxShadow="0px 0px 2px 0px #FFF, 0px 0px 4px 0px #1849DC"
      border="1px solid #1849DC"
      borderRadius="14px"
      position="relative"
    >
      <Common.Box
        position="absolute"
        top="0px"
        left="0px"
        width="100%"
        height="100%"
        overflow="hidden"
        borderRadius="14px"
      >
        <BlurredBackground />
      </Common.Box>
      <StyledImage src="/images/reward-cashier.svg"></StyledImage>
      <Common.Box></Common.Box>
      <Common.Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={0}
        position="relative"
      >
        {timestamp && (
          <Countdown
            timestamp={timestamp}
            handleShouldDisableButton={setShouldDisableButton}
          />
        )}
        <Common.Box p={0}>
          <BannerHeading>
            {translate('daily-reward-banner.title')}
          </BannerHeading>
        </Common.Box>
        <Common.Box width="100%" maxWidth="150px" mt={0}>
          <Button
            variant="primary"
            stretch
            onClick={handlePlay}
            disabled={shouldDisableButton}
          >
            {translate('daily-reward-banner.play')}
          </Button>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  ['daily-reward-banner.title', 'daily-reward-banner.play'],
  DailyRewardBanner
)

export default DailyRewardBanner
