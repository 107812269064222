import PropTypes from 'prop-types'

/**
 *
 *  @name useOpenChildWindow
 *
 * This hook opens a child window with close event listener.
 *
 * @param {object} props
 * @param {object} props.size - Size of the child window.
 * @param {number} props.size.width - Width of the child window.
 * @param {number} props.size.height - Height of the child window.
 * @param {function} props.handleClosed - Callback function to handle close event.
 *
 * @returns {function} openWindow
 *
 * @example
 *
 * const openWindow = useOpenChildWindow({
 * size: {
 * width: 600,
 * height: 400,
 * },
 * handleClosed: () => {
 * // handle close event
 * }
 * })
 *
 * openWindow('http://localhost:3000')
 *
 * */

export function useOpenChildWindow(props) {
  /**
   * @name openWindow
   *
   * This method opens a child window with close event listener.
   *
   * @param {string} url
   *
   */
  function openWindow(url) {
    const authWindow = window.open(
      url,
      'OAuth Sign-in',
      `width=${props.size?.width || 600},height=${props.size?.height || 400}`
    )

    // eslint-disable-next-line no-use-before-define
    const timer = setInterval(checkChild, 500)
    function checkChild() {
      if (authWindow.closed) {
        clearInterval(timer)
        props.handleClosed && props.handleClosed()
      }
    }
  }

  return openWindow
}

useOpenChildWindow.defaultProps = {
  size: {
    width: 600,
    height: 400,
  },
}

useOpenChildWindow.propTypes = {
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  handleClosed: PropTypes.func,
}
