import * as React from 'react'
import * as Urql from 'urql'
import * as ReactRedux from 'react-redux'
import gql from 'graphql-tag'
import { Helmet } from 'react-helmet-async'

import * as ShuftiPro from '@rushplay/shufti-pro'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Constants from './constants'
import * as Configuration from './configuration'
import * as Player from './player'
import { RedeemComponent } from './redeem-page/redeem-component'
import { makeDataSchema } from './redeem-page/data-schema'
import { usePlayerLimits } from './use-player-limit'

export const PLAYER_INFO_INIT_QUERY = gql`
  query PlayerInfoInit {
    player {
      account {
        currency
      }
      allAccounts {
        balanceCents
        bonusBalanceCents
        currency
        eurBalanceCents
        main
        moneyBalanceCents
        selected
        usdBalanceCents
        withdrawBalanceCents
      }
    }
  }
`

export function RedeemPage() {
  const [{ data, fetching }] = Urql.useQuery({
    query: PLAYER_INFO_INIT_QUERY,
    requestPolicy: 'cache-first',
  })

  const { limits, loading, playerLimitMaximum } = usePlayerLimits(
    Constants.TransactionType.REDEEM
  )

  const dataSchema = React.useMemo(
    () => makeDataSchema(limits, playerLimitMaximum, loading),
    [limits, loading, playerLimitMaximum]
  )

  const [documents] = ShuftiPro.useDocuments()

  const notVerified = documents
    ?.filter(doc => doc.type !== 'additional')
    .some(doc => doc.verified === false)

  const sweepCoins = React.useMemo(
    () =>
      data?.player?.allAccounts?.find(account => account.currency === 'SCC'),
    [data?.player]
  )

  const email = ReactRedux.useSelector(state => Player.getEmail(state.player))

  const firstName = ReactRedux.useSelector(state =>
    Player.getFirstName(state.player)
  )

  const lastName = ReactRedux.useSelector(state =>
    Player.getLastName(state.player)
  )

  const birthdate = ReactRedux.useSelector(state =>
    Player.getBirthdate(state.player)
  )

  const playerUsername = ReactRedux.useSelector(state =>
    Player.getUsername(state.player)
  )

  const playerCurrency = ReactRedux.useSelector(state =>
    Player.getCurrency(state.player)
  )

  const country = ReactRedux.useSelector(state =>
    Player.getCountryCode(state.player)
  )

  const { token: sessionToken } = Herz.Auth.useSession()

  const prizeoutApiKey = ReactRedux.useSelector(state =>
    Configuration.getPrizeoutApiKey(state.configuration)
  )

  const prizeoutId = ReactRedux.useSelector(state =>
    Configuration.getPrizeoutId(state.configuration)
  )

  // Disable buttons if not sufficient balance, loading state or not verified

  const disabled =
    loading ||
    !documents ||
    !sweepCoins ||
    notVerified ||
    sweepCoins?.withdrawBalanceCents < limits.min

  const disabledPrizeout =
    loading ||
    !documents ||
    !sweepCoins ||
    notVerified ||
    sweepCoins?.withdrawBalanceCents < 5000

  return (
    <Common.Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      px={[1, 0]}
    >
      <Helmet>
        <script
          src="https://widget.prizeout.com/prizeout-publisher-sdk.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <RedeemComponent
        data={data}
        firstName={firstName}
        lastName={lastName}
        birthdate={birthdate}
        playerUsername={playerUsername}
        playerCurrency={playerCurrency}
        email={email}
        country={country}
        disabled={disabled}
        disabledPrizeout={disabledPrizeout}
        notVerified={notVerified}
        sweepCoins={sweepCoins}
        fetching={fetching}
        documents={documents}
        limits={limits}
        sessionToken={sessionToken}
        prizeoutApiKey={prizeoutApiKey}
        prizeoutId={prizeoutId}
        dataSchema={dataSchema}
      />
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'redeem-page.title',
    'redeem-page.description',
    'redeem-page.terms',
    'redeem-page.pending.title',
    'redeem-page.cta.cash.title',
    'redeem-page.cta.cash.buttonText',
    'redeem-page.cta.prizeout.title',
    'redeem-page.cta.prizeout.buttonText',
    'redeem-page.amount.title',
    'redeem-page.cash-out',
    'redeem-page.cash.amount.label',
    'redeem-page.documents.request',
    'redeem-page.pending.amount',
    'main-menu.sweeps-coins',
    'main-menu.unplayed',
    'main-menu.redeemable',
    'main-menu.total',
    'cash-amount.amount.label',
    'cash-amount.amount.placeholder',
  ],
  RedeemPage
)

//Default export for loadable
export default RedeemPage
