import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Icons from '../icons'

import { BasicTileGrid } from './grids'

export function GameTileGrid(props) {
  const translate = Herz.I18n.useTranslate()
  const location = ReactRouter.useLocation()
  const isLiveCasino = location.pathname.includes('live-casino')
  const linkToMore = `/${isLiveCasino ? 'live-casino' : 'casino'}/${props.id}`

  return (
    <React.Fragment>
      <Common.Box width="100%" display="flex" justifyContent="space-between">
        {props.sectionTitle && (
          <Common.Box
            fontSize={5}
            fontFamily="head"
            fontWeight="bold"
            pb={0}
            color="g-text"
            px={[0, '0px']}
          >
            {props.sectionTitle}
          </Common.Box>
        )}
        {!props.hideButtonMore && (
          <ReactRouter.Link to={linkToMore}>
            <Common.Flex>
              <Common.Box
                as="p"
                fontSize="14px"
                fontFamily="Raleway"
                color="#FFF"
                fontWeight="500"
                pr={0}
              >
                {translate('game-tile-section.show-more')}
              </Common.Box>
              <Icons.ArrowRightAlt />
            </Common.Flex>
          </ReactRouter.Link>
        )}
      </Common.Box>
      <Common.Space>
        <BasicTileGrid size={props.size}>{props.children}</BasicTileGrid>
      </Common.Space>
    </React.Fragment>
  )
}

GameTileGrid.propTypes = {
  sectionTitle: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  hideButtonMore: PropTypes.bool,
  id: PropTypes.string,
}
