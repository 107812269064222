import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'
import { Flag } from './flags'
import { useServerConfiguration } from './server-configuration'

const MenuItem = styled.div`
  ${css({
    color: 'menu-item',
    py: 0,
  })};

  flex-direction: column;
  font-weight: 700;
  font-family: Raleway;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  transition: 0.3s;
`

const DropDownButton = styled.div`
  ${props =>
    css({
      transform: props.open ? 'rotate(180deg)' : 'rotate(0deg)',
    })}
  transition: transform 0.3s;
  align-items: center;
`
const DropDownWrapper = styled.div`
  font-size: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: capitalize;
  border-radius: 4px;
  cursor: initial;
  transition: 0.3s;
`

const LanguageSelectorSubitem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  ${css({
    color: 'white',
    '&:hover': {
      color: 'menu-item-color-active',
    },
  })};

  padding: 8px 0;
  transition: 0.3s;
`

export function LanguageSelector(props) {
  const configuration = useServerConfiguration()
  const locales =
    configuration?.countries?.find(
      country => country.alpha2 === configuration?.country?.alpha2
    )?.locales || []

  const translate = Herz.I18n.useTranslate(
    () => [
      `l10n.language.${configuration?.locale?.language}`,
      ...locales.map(locale => `l10n.language.${locale.language}`),
    ],
    [configuration.locale.language]
  )

  if (props.to && !props.items) {
    return (
      <MenuItem>
        <Common.Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          padding="8px 12px"
          borderRadius="4px"
          color={props.active ? 'menu-item-color-active' : 'menu-item'}
        >
          <ReactRouter.Link to={props.to}>
            <Common.Box width="100%" display="flex">
              <Common.Box pr={0}>
                <Flag code={configuration?.country?.alpha2 || 'US'} />
              </Common.Box>
              {translate(`l10n.language.${configuration?.locale?.language}`)}
            </Common.Box>
          </ReactRouter.Link>
        </Common.Box>
      </MenuItem>
    )
  }

  return (
    <MenuItem
      active={props.dropDown === props.name}
      onClick={() => props.onDropDown(props.name)}
    >
      <Common.Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        padding="8px 12px"
        borderRadius="4px"
        backgroundColor={
          props.dropDown === props.name
            ? 'menu-item-background-active'
            : 'transparent'
        }
      >
        <Common.Box
          style={{ textTransform: 'uppercase' }}
          fontSize="14px"
          fontFamily="Raleway"
          width="100%"
          display="flex"
        >
          <Common.Box display="flex" alignItems="center" pr={0}>
            <Flag
              code={configuration?.locale?.language === 'es' ? 'ES' : 'US'}
            />
          </Common.Box>
          {translate(`l10n.language.${configuration?.locale?.language}`)}
        </Common.Box>

        {configuration?.countries?.find(
          country => country.alpha2 === configuration?.country?.alpha2
        ) && (
          <DropDownButton open={props.dropDown === props.name}>
            <Icons.Chevron />
          </DropDownButton>
        )}
      </Common.Box>

      {props.dropDown === props.name && (
        <DropDownWrapper>
          {locales
            ?.filter(
              locale => locale.language !== configuration?.locale?.language
            )
            ?.map(item => (
              <LanguageSelectorSubitem key={item.slug}>
                <Common.Box pr={0}>
                  <Flag code={item.language === 'en' ? 'US' : item.language} />
                </Common.Box>
                <a
                  hrefLang={item.slug}
                  href={`/${item.slug}/?confirm_locale=1`}
                >
                  {translate(`l10n.language.${item.language}`)}
                </a>
              </LanguageSelectorSubitem>
            ))}
        </DropDownWrapper>
      )}
    </MenuItem>
  )
}

LanguageSelector.propTypes = {
  items: PropTypes.array,
  to: PropTypes.string,
  active: PropTypes.bool,
  name: PropTypes.string,
  dropDown: PropTypes.string,
  onDropDown: PropTypes.func,
}
