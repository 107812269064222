import * as React from 'react'
import * as Urql from 'urql'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from './constants'

const VIP_PROGRAM_QUERY = `
  query VipProgram {
    player {
      profile {
        vipProgram {
          level
          points
          pointsUntilNextLevel
        }
      }
    }
  }
`

function getVipLevel(level) {
  return Constants.VipLevels[level]
}

const StyledHeading = styled.h2`
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  background: linear-gradient(180deg, #fff 0%, #9090ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${props =>
    css({
      fontSize: props.fontSize,
    })};
`

const BannerHeading = styled.span`
  background: linear-gradient(180deg, #9a451a 0%, #ac5a02 100%);
  backgroundclip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  font-family: 'Rubik', sans-serif;
  ${css({
    fontSize: 6,
  })};
`

export function VipProgressBar(props) {
  const translate = Herz.I18n.useTranslate()
  const [{ data, fetching }] = Urql.useQuery({
    query: VIP_PROGRAM_QUERY,
    requestPolicy: 'cache-and-network',
  })

  const { level, points, pointsUntilNextLevel } =
    data?.player?.profile?.vipProgram || {}

  const totalPoints = points + pointsUntilNextLevel
  const progress = (points / totalPoints) * 100
  const nextLevel = level + 1

  if (fetching || !data) {
    return null
  }

  if (level === 4 && props.small) {
    return (
      <Common.Box borderRadius="14px" mt={1}>
        <Common.Box
          width="100%"
          height="100px"
          borderRadius="14px"
          display="grid"
          gridTemplateColumns="1fr 1fr"
          backgroundImage={`url(${translate('vip.legend-banner.background')})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
        >
          <Common.Box />
          <Common.Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Common.Box
              as="span"
              fontSize={3}
              color="static-white"
              fontWeight="700"
              lineHeight="20px"
              fontFamily="head"
            >
              {translate('vip.legend-banner.current-info')}
            </Common.Box>
            <BannerHeading>{translate('vip-banner.legend')}</BannerHeading>
          </Common.Box>
        </Common.Box>
      </Common.Box>
    )
  }

  if (level === 4) {
    return (
      <Common.Box
        width="100%"
        height={['115px', null, null, '100px']}
        borderRadius="14px"
        display="grid"
        gridTemplateColumns={
          props.small ? '1fr 1fr' : ['40% 60%', null, null, '1fr']
        }
        backgroundImage={
          props.small
            ? `url(${translate('vip.legend-banner.background')})`
            : [
                `url(${translate('vip.legend-banner.background')})`,
                null,
                null,
                `url(${translate('vip.legend-banner.background-desktop')})`,
              ]
        }
        backgroundSize={['contain', null, null, 'cover']}
        backgroundRepeat="no-repeat"
      >
        <Common.Box
          display={props.small ? 'block' : ['block', null, null, 'none']}
        />
        <Common.Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Common.Box
            as="span"
            fontSize={3}
            color="static-white"
            fontWeight="700"
            lineHeight="20px"
            fontFamily="head"
          >
            Current VIP Status
          </Common.Box>
          <BannerHeading>LEGEND</BannerHeading>
        </Common.Box>
      </Common.Box>
    )
  }

  return (
    <Common.Box
      backgroundColor={props.small && '#0C132B'}
      borderRadius={props.small ? '14px' : 'none'}
      mt={props.small ? 1 : '0px'}
      px={props.small ? 0 : '0px'}
    >
      <Common.Box width="100%">
        <Common.Box
          pb={props.small ? '0px' : 0}
          pt={0}
          fontSize={3}
          fontWeight="bold"
          textAlign="center"
          color="menu-item"
        >
          {translate('vip.info.current-status')}
        </Common.Box>
        <StyledHeading fontSize={props.small ? 5 : 6}>
          {getVipLevel(level)}
        </StyledHeading>
        <Common.Box
          my={props.small ? '0px' : 0}
          width="100%"
          display="flex"
          alignItems="center"
        >
          <Common.Box
            width="35px"
            height="35px"
            backgroundImage={`url(/images/star-${getVipLevel(level)}.png)`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
          />
          <Common.Box
            width="100%"
            border="1px solid #2A3149"
            borderRadius="10px"
            overflow="hidden"
            mx={0}
          >
            <Common.Box
              height="12px"
              width={`${progress}%`}
              background="linear-gradient(94deg, #7A18DC 0.32%, #5718DC 100%)"
              borderRadius="10px"
              transition="width 0.5s ease-in-out"
            />
          </Common.Box>
          <Common.Box
            width="35px"
            height="35px"
            backgroundImage={`url(/images/star-${getVipLevel(nextLevel)}.png)`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
          />
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

VipProgressBar.propTypes = {
  small: PropTypes.bool,
}

Herz.I18n.Loader.preload(
  [
    'vip.info.current-status',
    'vip.legend-banner.background',
    'vip.legend-banner.background-desktop',
    'vip.legend-banner.background-wide',
    'vip.legend-banner.current-info',
    'vip-banner.legend',
  ],
  VipProgressBar
)

export default VipProgressBar
