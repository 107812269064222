import PropTypes from 'prop-types'
import { layout, space, typography } from 'styled-system'

import * as common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

export const Card = styled('div', {
  shouldForwardProp: common.noneOf(['width', 'maxWidth']),
})`
  ${css({
    bg: 'nav',
    color: 'menu-item',
    borderRadius: '30px',
  })}
  ${typography};
  ${space};
  ${layout};
`

Card.propTypes = {
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
}
