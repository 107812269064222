import PropTypes from 'prop-types'

/**
 * @name fetchSessionToken
 *
 * Fetches a session token from the API
 *
 * @param {string} apiUrl
 * @param {string} provider
 * @param {object} body
 * @param {function} onSuccess
 * @param {function} onError
 * @param {function} onFinally
 *
 * @return {Promise}
 *
 * @example
 *
 * fetchSesstionToken(apiUrl, body, onSuccess, onError, onFinally)
 */
export async function fetchSessionToken(
  apiUrl,
  provider,
  body,
  onSuccess,
  onError,
  onFinally
) {
  return fetch(`${apiUrl}`, {
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/vnd.casinosaga.v1',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    mode: 'cors',
  })
    .then(response => response.json())
    .then(data => {
      return onSuccess(data, provider)
    })
    .catch(err => {
      return onError(err)
    })
    .finally(() => {
      if (onFinally) {
        return onFinally()
      }
    })
}

fetchSessionToken.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  body: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onFinally: PropTypes.func,
}

/**
 * @name exchangeToken
 *
 * Exchanges sessiont token to access token
 *
 * @param {string} apiUrl
 * @param {object} body
 * @param {function} onSuccess
 * @param {function} onError
 * @param {function} onFinally
 *
 * @return {Promise}
 *
 * @example
 *
 * exchangeToken(apiUrl, body, onSuccess, onError, onFinally)
 */
export async function exchangeToken(
  apiUrl,
  body,
  onSuccess,
  onError,
  onFinally
) {
  return fetch(`${apiUrl}`, {
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/vnd.casinosaga.v1',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    mode: 'cors',
  })
    .then(response => response.json())
    .then(data => {
      return onSuccess(data, 'facebook')
    })
    .catch(err => {
      return onError(err)
    })
    .finally(() => {
      if (onFinally) {
        return onFinally()
      }
    })
}

exchangeToken.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  body: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onFinally: PropTypes.func,
}
