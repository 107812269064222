import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

import { Button } from '../button'
import { ButtonLoader } from '../button-loader'

/**
 * @nane CTA Banner
 * @description Call to action banner
 * @param {string} title
 * @param {string} buttonText
 * @param {boolean} disabled
 * @param {string} src
 * @param {function} action
 * @param {string} link
 * @param {*} props
 * @returns
 */
export function CTABanner(props) {
  return (
    <Common.Box
      width="100%"
      maxWidth="327px"
      borderRadius="14px"
      height="126px"
      border="1px solid #1849DC"
      boxShadow="0px 0px 2px 0px #FFF, 0px 0px 4px 0px #1849DC"
      backgroundImage="radial-gradient(89.53% 92.72% at 50% 117.43%, #233980 0%, #111540 100%)"
      position="relative"
    >
      <Common.Box width="100%" display="grid" gridTemplateColumns="45% 55%">
        <Common.Box width="100%" position="relative" height="100%">
          <Common.Box
            as="img"
            src={props.src}
            alt="cash"
            width="185px"
            position="absolute"
            top="-20px"
            left="-20px"
          />
        </Common.Box>
        <Common.Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          minWidth="180px"
          pl={0}
          py={0}
          pr={1}
        >
          <Common.Box
            as="h3"
            fontSize="12px"
            fontWeight="800"
            color="white"
            lineHeight="normal"
            fontFamily="Raleway"
            minHeight="60px"
          >
            {props.title}
          </Common.Box>
          {props.link && !props.action ? (
            <Common.Box width="100%" pt={0}>
              <ReactRouter.Link disabled={props.disabled} to={props.link}>
                <Button disabled={props.disabled} stretch variant="primary">
                  {props.buttonText}
                </Button>
              </ReactRouter.Link>
            </Common.Box>
          ) : (
            <Common.Box width="100%" pt={0}>
              <Button
                disabled={props.disabled}
                onClick={props.action}
                stretch
                variant="primary"
              >
                {props.loading ? <ButtonLoader /> : props.buttonText}
              </Button>
            </Common.Box>
          )}
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

CTABanner.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  src: PropTypes.string.isRequired,
  action: PropTypes.func,
  link: PropTypes.string,
  loading: PropTypes.bool,
}
