import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import { CasinoGameTile } from '../casino-game-tile'
import { GameTileLoading } from '../game-tile-loading'
import { Parallax } from '../parallax'
import { useMenuQueries } from '../use-menu-queries'

const BigTilesWrapper = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;

  @media (max-width: 960px) {
    grid-row-start: 1;
    grid-row-end: 2;
  }
`

export function GameTile(props) {
  const { loginQuery } = useMenuQueries()
  const { authenticated } = Herz.Auth.useSession()

  if (props.skeleton) {
    if (props.size === 'large' && props.index === 0) {
      return (
        <BigTilesWrapper>
          <GameTileLoading height="100%" />
        </BigTilesWrapper>
      )
    }
    return <GameTileLoading height="100%" />
  }

  if (props.size === 'large' && props.index === 0) {
    return (
      <BigTilesWrapper>
        <ReactRouter.Link
          to={authenticated ? `/casino/games/${props.id}` : `?${loginQuery}`}
          style={{ height: '100%' }}
        >
          <Parallax>
            <CasinoGameTile
              id={Number.parseInt(props.id)}
              backgroundUrl={props.backgroundUrl}
              jackpotValue={props.jackpotValue}
              jackpotCurrency={props.jackpotCurrency}
              title={props.title}
              titleUrl={props.titleUrl}
              provider={props.displayProvider || props.provider}
              gameKey={props.gameKey}
              size={'xl'}
            />
          </Parallax>
        </ReactRouter.Link>
      </BigTilesWrapper>
    )
  }

  return (
    <ReactRouter.Link
      style={{ height: '100%' }}
      to={authenticated ? `/casino/games/${props.id}` : `?${loginQuery}`}
    >
      <Parallax>
        <CasinoGameTile
          id={Number.parseInt(props.id)}
          backgroundUrl={props.backgroundUrl}
          jackpotValue={props.jackpotValue}
          jackpotCurrency={props.jackpotCurrency}
          title={props.title}
          titleUrl={props.titleUrl}
          provider={props.displayProvider || props.provider}
          gameKey={props.id}
          size={props.tileSize}
        />
      </Parallax>
    </ReactRouter.Link>
  )
}

GameTile.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  skeleton: PropTypes.bool,
  backgroundOverlayUrl: PropTypes.string,
  backgroundOverlayImageAlignment: PropTypes.string,
  backgroundUrl: PropTypes.string,
  jackpotValue: PropTypes.number,
  jackpotCurrency: PropTypes.string,
  title: PropTypes.string,
  titleUrl: PropTypes.string,
  displayProvider: PropTypes.string,
  provider: PropTypes.string,
  tileSize: PropTypes.string,
  gameKey: PropTypes.any,
}
