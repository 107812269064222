import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import * as Icons from '../icons'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 20px minmax(180px, 1fr) minmax(100px, 1fr);
  gap: 10px;
  padding-top: 8px;
  padding-bottom: 10px;
`

export function Amount(props) {
  const balance = props.balanceCents / 100 || 0
  const amount = balance.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    decimals: 'auto',
  })

  return amount
}

Amount.propTypes = {
  balanceCents: PropTypes.number.isRequired,
}

/**
 * @name SweepCoins
 *
 * @description Display balance details for sweep coins
 *
 * @param {object} props
 * @param {*} props
 * @returns
 */

export function SweepCoins(props) {
  const translate = Herz.I18n.useTranslate()

  if (!props.coins) {
    return null
  }

  return (
    <Grid>
      <Common.Box
        style={{
          gridColumn: '1 / span 2',
          gridRow: '1/1',
        }}
        display="flex"
        alignItems="center"
      >
        <Icons.SweepCoin />
        <Common.Box
          fontFamily="Raleway"
          fontSize="18px"
          color="#B2C2EE"
          fontWeight="700"
          pl={0}
        >
          {translate('main-menu.sweeps-coins')}
        </Common.Box>
      </Common.Box>
      <Common.Box
        style={{
          gridColumn: '2 / span 3',
          gridRow: '2/2',
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Common.Box fontFamily="Raleway" fontSize="12px" color="#B2C2EE">
          {translate('main-menu.total')}
        </Common.Box>
        <Common.Box
          fontWeight="400"
          fontFamily="Raleway"
          fontSize="14px"
          color="white"
        >
          SC <Amount balanceCents={props.coins?.balanceCents || 0} />
        </Common.Box>
      </Common.Box>
      <Common.Box
        style={{
          gridColumn: '2 / span 3',
          gridRow: '3/3',
        }}
        display="flex"
        justifyContent="space-between"
      >
        <Common.Box
          fontFamily="Raleway"
          fontSize="12px"
          fontWeight="400"
          color="#B2C2EE"
        >
          {translate('main-menu.redeemable')}
        </Common.Box>
        <Common.Box
          fontWeight="800"
          fontFamily="Raleway"
          fontSize="14px"
          color="white"
        >
          SC <Amount balanceCents={props.coins?.withdrawBalanceCents || 0} />
        </Common.Box>
      </Common.Box>
      <Common.Box
        style={{
          gridColumn: '2 / span 3',
          gridRow: '4/4',
        }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Common.Box fontFamily="Raleway" fontSize="12px" color="#B2C2EE">
          {translate('main-menu.unplayed')}
        </Common.Box>
        <Common.Box
          fontWeight="400"
          fontFamily="Raleway"
          fontSize="14px"
          color="white"
        >
          SC <Amount balanceCents={props.coins?.moneyBalanceCents || 0} />
        </Common.Box>
      </Common.Box>
    </Grid>
  )
}

SweepCoins.propTypes = {
  coins: PropTypes.object.isRequired,
}
