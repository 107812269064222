import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as Inventory from './inventory'
import * as Configuration from './configuration'
import * as Player from './player'
import { Button } from './button'
import { HtmlContent } from './html-content'
import { QueryDrawer } from './query-drawer'
import { useSafeUpdateQuery } from './use-safe-update-query'

const appear = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  50% {
    opacity: 1;
    transform: translateY(0);
  }

  100% { 
    opacity: 0;
    transform: translateY(-10px);
  }
`

const Terms = styled.div`
  width: 100%;
  color: #cdced0;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;

  ${css({
    '& strong': {
      fontWeight: '700',
    },
  })}
`

const Banner = styled.div`
  position: relative;
  padding: 16px;
  width: 330px;
  border-radius: 14px;
  border: 1px solid #1849dc;
  background: radial-gradient(
    89.53% 92.72% at 50% 117.43%,
    #233980 0%,
    #111540 100%
  );
  box-shadow: 0px 0px 2px 0px #fff, 0px 0px 4px 0px #1849dc;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
`

const Text = styled.h3`
  color: white;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;

  ${css({
    '& span': {
      color: '#B2C2EE',
    },
    '& span:nth-of-type(2)': {
      color: '#66E247',
    },
  })}
`

const StyledInput = styled.input`
  outline: none;
  border: none;
  background: none;
  width: 100%;
  color: #cdced0;
  padding: 8px;
  font-family: Raleway;
  overflow: hidden;
  font-size: 14px;

  ${css({
    'input[type=checkbox]:checked + &': {
      borderColor: 'none',
      bg: 'none',
      outline: 'none',
    },
    'input[type=checkbox]:not([checked]):disabled + &': {
      borderColor: 'none',
      bg: 'none',
      outline: 'none',
    },
    'input[type=checkbox]:disabled:checked + &': {
      borderColor: 'none',
      bg: 'none',
      outline: 'none',
    },
  })};
`

const VisualFeedback = styled.div`
  position: absolute;
  top: -20px;
  right: 5%;
  color: white;
  animation: ${appear} 0.4s ease-in;
  animation-fill-mode: forwards;
  pointer-events: none;
  color: #66e247;
`

function ReferralLink() {
  const hostname =
    typeof window !== 'undefined' ? window?.location?.origin : null
  const inputRef = React.useRef(null)
  const translate = Herz.I18n.useTranslate()
  const [copied, setCopied] = React.useState(false)
  const referralId = ReactRedux.useSelector(state =>
    Player.getReferralId(state.player)
  )

  function handleReferralLink() {
    inputRef.current.select()
    document.execCommand('copy')

    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <Common.Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      border="1px solid #2A3149"
      borderRadius="4px"
      width="100%"
      background="#0C132B"
      position="relative"
    >
      {copied && (
        <VisualFeedback>
          {translate('invite-drawer.visual-feedback.copied')}
        </VisualFeedback>
      )}

      <StyledInput
        type="text"
        ref={inputRef}
        value={`${hostname}/?referred_by_id=${referralId}`}
        readOnly
      />
      <Button variant="primary" onClick={handleReferralLink}>
        {translate('invite-drawer.copy.cta')}
      </Button>
    </Common.Box>
  )
}

export function InviteAFriendDrawer() {
  const { token } = Herz.Auth.useSession()
  const apiUrl = ReactRedux.useSelector(state =>
    Configuration.getApiUrl(state.configuration)
  )
  const translate = Herz.I18n.useTranslate()
  const history = ReactRouter.useHistory()
  const menuQuery = useSafeUpdateQuery({
    menu: 'me',
    invite: null,
  })

  const referralCount = ReactRedux.useSelector(state =>
    Player.getReferralCount(state.player)
  )

  const [campaign, setCampaign] = React.useState({})
  const multiplayer =
    referralCount % campaign?.referralCountNeeded === 0
      ? Math.ceil(referralCount / campaign?.referralCountNeeded) + 1
      : Math.ceil(referralCount / campaign?.referralCountNeeded)

  React.useEffect(() => {
    Inventory.fetch()
    fetch(`${apiUrl}/ctool/campaigns`, {
      headers: {
        Accept: 'application/vnd.casinosaga.v1',
        Authorization: token,
      },
    })
      .then(res => res.json())
      .then(data => {
        const referralCampaign = data?.find(
          campaign => campaign.campaignType === 'referral_registration'
        )
        return setCampaign(referralCampaign)
      })
      // eslint-disable-next-line no-console
      .catch(err => console.error(err))
  }, [])

  return (
    <QueryDrawer
      variant="secondary"
      onSecondaryAction={() => history.push(`?${menuQuery}`)}
      activeQueryName="invite"
    >
      <Common.Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Common.Box
          maxWidth="220px"
          as="h2"
          style={{
            whiteSpace: 'pre',
            fontVariantNumeric: 'lining-nums proportional-nums',
            fontFeatureSettings: "'clig' off, 'liga' off",
          }}
          fontFamily="Raleway"
          fontSize="24px"
          color="white"
          fontWeight="600"
          textAlign="center"
        >
          <HtmlContent
            html={{
              __html: translate('invite-drawer.title', {
                amount: campaign?.referralCountNeeded,
              }),
            }}
          />
        </Common.Box>
        <Common.Box pt={7}>
          <Banner style={{ height: '261px' }}>
            <Common.Box
              style={{ transform: 'translate(-50%, -50%)' }}
              position="absolute"
              top="10%"
              left="50%"
              right="50%"
              as="img"
              width="328px"
              height="256px"
              alt="invite"
              src="/images/invite-a-friend-drawer.png"
            />
            <Text>
              <HtmlContent
                html={{
                  __html: translate('invite-drawer.description', {
                    friends: 3,
                    coins: 50,
                  }),
                }}
              />
            </Text>
            <Common.Box pt={0} width="100%">
              <ReferralLink />
            </Common.Box>
            {campaign?.referralCountNeeded ? (
              <Common.Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                pt="8px"
                fontSize="16px"
                fontWeight="800"
              >
                <Common.Box>
                  {translate('invite-drawer.friends-count')}
                </Common.Box>
                <Common.Box pr="16px">{`${referralCount}/${campaign?.referralCountNeeded *
                  multiplayer}`}</Common.Box>
              </Common.Box>
            ) : null}
          </Banner>
        </Common.Box>
        <Common.Box
          py={0}
          width="100%"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="column"
        >
          <Common.Box
            py="16px"
            as="h3"
            fontSize="16px"
            fontFamily="Raleway"
            textAlign="center"
            color="#B2C2EE"
            fontWeight="700"
            style={{
              textTransform: 'uppercase',
            }}
          >
            {translate('invite-drawer.subheader')}
          </Common.Box>
          <Terms>
            <HtmlContent
              html={{
                __html: translate('invite-drawer.terms'),
              }}
            />
          </Terms>
          <Common.Box
            pt={3}
            pb={0}
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Common.Box
              as="p"
              fontSize="14px"
              color="#CDCED0"
              fontFamily="Raleway"
            >
              {translate('invite-drawer.footer')}
            </Common.Box>
            <Common.Box
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              fontWeight="700"
              fontSize="14px"
              color="#CDCED0"
              fontFamily="Raleway"
            >
              <ReactRouter.Link to="/terms-and-conditions">
                {translate('footer.terms-and-conditions')}
              </ReactRouter.Link>
            </Common.Box>
          </Common.Box>
        </Common.Box>
      </Common.Box>
    </QueryDrawer>
  )
}

Herz.I18n.Loader.preload(
  [
    'invite-drawer.title',
    'invite-drawer.description',
    'invite-drawer.terms',
    'invite-drawer.subheader',
    'footer.terms-and-conditions',
    'invite-drawer.footer',
    'invite-drawer.copy.cta',
    'invite-drawer.visual-feedback.copied',
    'invite-drawer.friends-count',
    'invite-drawer.promotion.header',
    'invite-drawer.promotion.text',
    'invite-drawer.promotion.cta',
  ],
  InviteAFriendDrawer
)
