import * as ReactRedux from 'react-redux'
import * as React from 'react'
import LazyLoad from 'react-lazyload'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as Configuration from './configuration'

const scroll = keyframes`
 0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
`

const ProviderContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  width: 1400px;

  opacity: 0.4;


  /* Hide scrollbar for Webkit browsers (e.g., Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${props =>
    props.gridTemplateColumns &&
    `
    grid-template-columns: ${props.gridTemplateColumns};
  `}

  animation: ${scroll} linear infinite;
  animation-duration: ${props => props.duration}s;
`

export function GameProviderImages() {
  const containerRef = React.useRef(null)

  const [duration, setDuration] = React.useState(30) // default scroll duration

  const gameProviders = ReactRedux.useSelector(state =>
    Configuration.getGameProviderImageUrls(state.configuration)
  )

  React.useEffect(() => {
    if (gameProviders.length > 0) {
      // Adjust scroll duration based on the number of items
      const speedFactor = 0.01 // Adjust this number to control scroll speed
      setDuration(Math.max(gameProviders.length * speedFactor, 30))
    }
  }, [gameProviders])

  React.useEffect(() => {
    const container = containerRef.current

    const handleScroll = () => {
      if (
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth
      ) {
        container.scrollLeft = 0 // Reset scroll position to the start
      }
    }

    container.addEventListener('scroll', handleScroll)

    return () => container.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <ProviderContainer
      ref={containerRef}
      gridTemplateColumns={`repeat(${Math.ceil(
        gameProviders.length / 2
      )}, 1fr)`}
      duration={duration}
    >
      {gameProviders?.map((item, i) => (
        <LazyLoad once height="34px" key={item.name} offset={100}>
          <Common.Box
            key={i}
            as="img"
            width="200px"
            alt={item.name}
            src={item.image.url}
          />
        </LazyLoad>
      ))}
    </ProviderContainer>
  )
}
