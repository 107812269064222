import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import { calculateGamesToRender, calculateTileSize } from './utils'
import { useIsMobile } from './use-is-mobile'

export function useLayout(props) {
  const isMobile = useIsMobile()
  const params = ReactRouter.useParams()
  const page = params?.slug

  const gamesToRender = calculateGamesToRender(
    props.games,
    props.tileSize,
    isMobile,
    page
  )

  const tileSize = calculateTileSize(gamesToRender, props.tileSize)

  return {
    gamesToRender,
    tileSize,
  }
}

useLayout.propTypes = {
  games: PropTypes.array.isRequired,
  tileSize: PropTypes.string.isRequired,
}
