import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from './constants'
import * as Icons from './icons'
import { HtmlContent } from './html-content'

const DropDownButton = styled.div`
  ${props =>
    css({
      transform: props.open ? 'rotate(180deg)' : 'rotate(0deg)',
    })}
  transition: transform 0.3s;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: 18px;
  right: 15px;
`

export function SeoSection(props) {
  const { authenticated } = Herz.Auth.useSession()
  const [isExtended, setIsExtended] = React.useState(true)

  function handleToggle() {
    setIsExtended(prevState => !prevState)
  }

  if (!props.content || authenticated) {
    return null
  }

  return (
    <Common.Box py={2} px={[1, 1, '0px']}>
      <Common.Box
        p={'11px'}
        width="100%"
        maxWidhth={Constants.ContentMaxWidth}
        margin="0 auto"
        textAlign="left"
        fontSize={2}
        borderRadius="6px"
        border="1px solid rgba(255, 255, 255, 0.20)"
        overflowY="hidden"
        height={isExtended ? '100%' : '50px'}
        position="relative"
        onClick={handleToggle}
      >
        <DropDownButton open={isExtended}>
          <Icons.Chevron />
        </DropDownButton>
        <HtmlContent
          html={{
            __html: props.content,
          }}
        />
      </Common.Box>
    </Common.Box>
  )
}

SeoSection.propTypes = {
  content: PropTypes.string.isRequired,
}
