import * as Urql from 'urql'
import * as ReactRouter from 'react-router-dom'
import React from 'react'
import gql from 'graphql-tag'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import { CasinoSection } from './casino-section'
import { GameTile } from './casino-categories/game-tile'
import { GameTileSection } from './game-tile-section'
import { calculateGamesToRender, calculateTileSize } from './utils'
import { useIsMobile } from './use-is-mobile'

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 30px;
  padding-top: 0px;

  @media (max-width: 960px) {
    max-width: 500px;
    margin: 0 auto;
  }
`

const CatalogQuery = gql`
  query CatalogQuery {
    catalog(key: "landing-page") {
      ... on GetCatalogSuccess {
        kind
        categories {
          id
          sections {
            id
            key
            tileSize
            layout
            games {
              title
              id
              imageUrl
              backgroundUrl
              backgroundOverlayImageAlignment
              backgroundOverlayUrl
              titleUrl
              provider
              displayProvider
              blitzEnabled
              loginRequired
              liveCasinoAttributes {
                seatsTotal
                seatsAvailable
                tableOpen
              }
            }
          }
        }
      }
      ... on GetCatalogFailure {
        error
        errorCode
      }
    }
  }
`

export function LandingPageCategory() {
  const [casinoResponse] = Urql.useQuery({
    query: CatalogQuery,
    requestPolicy: 'cache-first',
  })
  const params = ReactRouter.useParams()
  const page = params?.slug

  const isMobile = useIsMobile()
  const sections = React.useMemo(
    () =>
      casinoResponse?.data?.catalog?.categories
        ? casinoResponse?.data?.catalog?.categories[0]?.sections?.filter(
            section => section.games.length > 0
          )
        : [],
    [casinoResponse.data]
  )

  const translate = Herz.I18n.useTranslate(
    () => [
      ...sections.map(section => `catalog-categories.${section.key}.title`),
      ...sections.flatMap(({ games }) =>
        games.map(game => `game-tile.provider-logo.${game.provider}.logo`)
      ),
    ],
    [sections]
  )

  return (
    <React.Fragment>
      {casinoResponse.fetching && (
        <Wrapper margin="0 auto">
          <Common.Box
            alignSelf="center"
            color="g-text"
            display="flex"
            flexDirection="column"
            flexGrow="1"
            fontSize={['48px', '64px']}
          >
            <CasinoSection count={9} skeleton layout="row" tileSize="large" />
          </Common.Box>
        </Wrapper>
      )}
      {sections?.map(section => {
        const gamesToRender = calculateGamesToRender(
          section.games,
          section.tileSize,
          isMobile,
          page
        )

        const tileSize = calculateTileSize(gamesToRender, section.tileSize)

        return (
          <Wrapper margin="0 auto" key={`landing-page.-${section.key}`}>
            <GameTileSection
              id={section.key}
              columns={section?.games?.length}
              layout={section.layout}
              sectionTitle={translate(
                `catalog-categories.${section.key}.title`
              )}
              size={tileSize}
              hideButtonMore
            >
              {gamesToRender?.map((node, index) => (
                <GameTile
                  index={index}
                  key={node.key}
                  gameKey={node.key}
                  id={Number.parseInt(node.id)}
                  backgroundUrl={node.imageUrl}
                  jackpotValue={node.jackpotValue}
                  jackpotCurrency={node.jackpotCurrency}
                  title={node.title}
                  titleUrl={node.titleUrl}
                  provider={node.displayProvider || node.provider}
                  size={section.tileSize}
                />
              ))}
            </GameTileSection>
          </Wrapper>
        )
      })}
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload(
  [
    'game-tile-section.show-more',
    'game-tile-section.slots.header',
    'game-tile-section.show-more',
  ],
  LandingPageCategory
)
