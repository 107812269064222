import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'
import { useMenuQueries } from './use-menu-queries'

const Wrapper = styled.div`
  position: relative;
  justify-content: space-around;
  width: 100%;
  height: calc(100vw * 0.15);
  position: fixed;
  left: 0%;
  bottom: 0%;
  font-size: 10px;
  display: none;
  z-index: 99999;

  ${props =>
    css({
      '@media (max-width: 450px)': {
        display: 'flex',
        visibility: props.hidden ? 'hidden' : 'visible',
        height: props.hidden && '0',
        transition: 'all .25s ease-in-out',
      },
    })}

  &:before {
    content: '';
    position: absolute;
    bottom: 0%;
    left: 0%;
    background-image: url('/images/rect.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: calc(100vw * 0.16);
  }
`

const NavItemComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${props =>
    css({
      color: props.highlight ? 'white' : '#B2C2EE',
      width: '70px',
      height: '70px',
      transform: props.highlight ? 'translateY(-20px)' : 'none',
      borderRadius: '50%',
      background: props.highlight
        ? 'linear-gradient(94deg, #7A18DC 0.32%, #5718DC 100%)'
        : 'none',

      '&:after': {
        content: props.highlight ? '""' : 'none',
        position: 'absolute',
        background: 'url(/images/quick-menu-right.svg)',
        right: '10%',
      },

      '&:before': {
        content: props.highlight ? '""' : 'none',
        position: 'absolute',
        background: 'url(/images/quick-menu-left.svg)',
        left: '-10%',
      },
    })}
`

const CornerElementLeft = styled.span`
  ${css({
    position: 'absolute',
    left: '25%',
    width: '25%',
    height: 'inherit',
    background: 'url(/images/quick-menu-left.svg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    bottom: '80%',
    zIndex: -1,
    display: 'block',
  })}
`

const CornerElementRight = styled.span`
  ${css({
    position: 'absolute',
    right: '25%',
    width: '25%',
    height: 'inherit',
    background: 'url(/images/quick-menu-right.svg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    bottom: '80%',
    zIndex: -1,
    display: 'block',
  })}
`

function NavItem(props) {
  return (
    <ReactRouter.Link to={props.to}>
      <NavItemComponent highlight={props.highlight}>
        {props.icon}
        <Common.Box pt={0}>
          <Common.Text fontWeight="700" fontSize={'10px'}>
            {props.title}
          </Common.Text>
        </Common.Box>
      </NavItemComponent>
    </ReactRouter.Link>
  )
}

NavItem.propTypes = {
  highlight: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.element,
  to: PropTypes.string,
}

const BetslipNavItemComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  ${props =>
    css({
      color: props.highlight ? '#0C132B' : '#B2C2EE',
      width: '70px',
      height: '70px',
      transform: props.highlight ? 'translateY(-20px)' : 'none',
      borderRadius: '50%',
      background: props.highlight
        ? 'linear-gradient(180deg, #FFF 0%, #9090FF 100%)'
        : 'none',
      '&:after': {
        content: props.highlight ? '""' : 'none',
        position: 'absolute',
        background: 'url(/images/quick-menu-right.svg)',
        right: '10%',
      },
      '&:before': {
        content: props.highlight ? '""' : 'none',
        position: 'absolute',
        background: 'url(/images/quick-menu-left.svg)',
        left: '-10%',
      },
    })}
`

function BetslipButton(props) {
  return (
    <BetslipNavItemComponent
      highlight={props.highlight}
      onClick={props.onClick}
    >
      {props.highlight ? (
        <Common.Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="#212836"
          borderRadius="50%"
          width="20px"
          height="20px"
          color="white"
          fontSize={0}
          fontWeight="bold"
        >
          {props.count > 9 ? '9+' : props.count}
        </Common.Box>
      ) : (
        props.icon
      )}

      <Common.Box pt={0}>
        <Common.Text fontWeight="700" fontSize={'10px'}>
          {props.title}
        </Common.Text>
      </Common.Box>
    </BetslipNavItemComponent>
  )
}

BetslipButton.propTypes = {
  count: PropTypes.number,
  highlight: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
}

const SPORTSBOOK_EVENTS = {
  OPEN_MY_BETS_LIST: 'OPEN_MY_BETS_LIST',
  SET_BET_SLIP_COUNT: 'SET_BET_SLIP_COUNT',
  SET_BET_SLIP_OPENED: 'SET_BET_SLIP_OPENED',
  TOGGLE_BET_SLIP: 'TOGGLE_BET_SLIP',
}

function isGamePlay(path) {
  const pattern = /^\/casino\/games\/\d+$/

  return pattern.test(path)
}

export function QuickNavigationMenu(props) {
  const location = ReactRouter.useLocation()

  const { authenticated } = Herz.Auth.useSession()
  const translate = Herz.I18n.useTranslate()
  const {
    depositQuery,
    registerQuery,
    menuQuery,
    promotionQuery,
    myProfileQuery,
  } = useMenuQueries()

  const [betslipCount, setBetslipCount] = React.useState(0)
  const [isBetSlipOpened, setIsBetSlipOpened] = React.useState(false)

  const isSportsbookPage = location.pathname.includes('/sports')

  React.useEffect(() => {
    function sportsBookEventListener(event) {
      if (!location.pathname.includes('/sports') || !authenticated) {
        return
      }

      const messageData =
        typeof event.data === 'string' ? JSON.parse(event.data) : event.data

      const actionType = messageData.type

      switch (actionType) {
        case SPORTSBOOK_EVENTS.SET_BET_SLIP_COUNT: {
          const count = messageData.payload

          if (count !== betslipCount) {
            setBetslipCount(count)
          }
          break
        }
        case SPORTSBOOK_EVENTS.SET_BET_SLIP_OPENED: {
          const isOpened = messageData.payload
          if (isOpened !== isBetSlipOpened) {
            setIsBetSlipOpened(isOpened)
          }
        }
      }
    }

    window.addEventListener('message', sportsBookEventListener)

    return () => window.removeEventListener('message', sportsBookEventListener)
  }, [betslipCount, isBetSlipOpened, location.pathname])

  const displayBetslipButton =
    location.pathname.includes('/sports') && authenticated

  function handleBetslipClick() {
    const message = {
      type: SPORTSBOOK_EVENTS.TOGGLE_BET_SLIP,
    }
    const { sportsbookRef } = props
    if (sportsbookRef.current) {
      sportsbookRef.current.contentWindow?.postMessage(
        JSON.stringify(message),
        '*'
      )
    }
  }

  function handleMyPlaysClick() {
    const message = {
      type: SPORTSBOOK_EVENTS.OPEN_MY_BETS_LIST,
    }
    const { sportsbookRef } = props
    if (sportsbookRef.current) {
      sportsbookRef.current.contentWindow?.postMessage(
        JSON.stringify(message),
        '*'
      )
    }
  }

  // Hides menu when launching game
  if (isGamePlay(location.pathname) || isSportsbookPage) {
    return null
  }

  return (
    <Wrapper hidden={isBetSlipOpened}>
      <CornerElementLeft />
      <CornerElementRight />
      <NavItem
        to="/casino"
        icon={<Icons.Lobby />}
        title={translate('quick-navigation-menu.lobby')}
      />
      <NavItem
        to={authenticated ? `?${myProfileQuery}` : `?${promotionQuery}`}
        icon={authenticated ? <Icons.MyAccount /> : <Icons.Promo />}
        title={
          authenticated
            ? translate('quick-navigation-menu.my-account')
            : translate('quick-navigation-menu.promo')
        }
      />
      {displayBetslipButton ? (
        <BetslipButton
          title={translate('quick-navigation-menu.play-slip')}
          count={betslipCount}
          onClick={handleBetslipClick}
          highlight
        />
      ) : (
        <NavItem
          to={authenticated ? `?${depositQuery}` : `?${registerQuery}`}
          icon={authenticated ? <Icons.BuyCoins /> : <Icons.JoinUs />}
          title={
            authenticated
              ? translate('quick-navigation-menu.buy-coins')
              : translate('quick-navigation-menu.join-us')
          }
          highlight
        />
      )}
      {displayBetslipButton ? (
        <BetslipButton
          title={translate('quick-navigation-menu.my-plays')}
          icon={<Icons.Receipt />}
          onClick={handleMyPlaysClick}
        />
      ) : (
        <NavItem
          to="/casino/search"
          icon={<Icons.Browse />}
          title={translate('quick-navigation-menu.browse')}
        />
      )}
      <NavItem
        to={`?${menuQuery}`}
        icon={<Icons.MenuNav />}
        title={translate('quick-navigation-menu.menu')}
      />
    </Wrapper>
  )
}

QuickNavigationMenu.propTypes = {
  sportsbookRef: PropTypes.object,
}

Herz.I18n.Loader.preload(
  [
    'quick-navigation-menu.lobby',
    'quick-navigation-menu.my-account',
    'quick-navigation-menu.promo',
    'quick-navigation-menu.buy-coins',
    'quick-navigation-menu.join-us',
    'quick-navigation-menu.browse',
    'quick-navigation-menu.menu',
    'quick-navigation-menu.play-slip',
    'quick-navigation-menu.my-plays',
  ],
  QuickNavigationMenu
)
