import * as ReactRedux from 'react-redux'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as CombinedSelectors from './combined-selectors'
import * as BaseGameTile from './base-game-tile'

export function CasinoGameTile(props) {
  // TODO: Seems useless; check and remove
  const game = ReactRedux.useSelector(state =>
    CombinedSelectors.getGameById(state, { id: props.id })
  )

  return (
    <BaseGameTile.Loader
      stretched={props.stretched}
      size={props.size}
      title={props.title || game.title}
    >
      <BaseGameTile.Background
        stretched={props.stretched}
        backgroundUrl={props.backgroundUrl}
        disabled={props.disabled}
        size={props.size}
      ></BaseGameTile.Background>
    </BaseGameTile.Loader>
  )
}

CasinoGameTile.propTypes = {
  backgroundUrl: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'picked-games', 'xl']),
  title: PropTypes.string,
  titleUrl: PropTypes.string,
  stretched: PropTypes.bool,
}
