import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'
import * as Notifications from '@rushplay/notifications'

import * as CombinedSelectors from '../combined-selectors'
import * as Configuration from '../configuration'
import * as Constants from '../constants'
import * as Player from '../player'
import { GradientText } from '../gradient-text'
import { ProcessesIds, RegistrationStep } from '../constants'
import { ProgressBar } from '../progress-bar'
import { QueryDrawer } from '../query-drawer'
import { RegistrationForm } from '../registration-form'
import { SocialLogin } from '../social-login'
import { parseQueryString } from '../utils'
import { useFacebookSignin } from '../use-facebook-signing'
import { useGoogleOAuthSigninConsent } from '../use-google-oauth-signin-consent'
import { useLogin } from '../use-login'
import { useServerConfiguration } from '../server-configuration'

import { dataSchema } from './data-schema'

const dataSchemaForKeysPreloading = dataSchema({
  // Enum values are not used in translations so none are needed
  countryCallingCode: [],
  // Enum values are not used in translations so none are needed
  country: [],
  // The actual value is passed as variable thus can be arbitrary
  maxYear: 2022,
  // Enforce extra rule to retrieve this key
  shouldVerifyPhone: true,
})

const formName = 'register'
const preloadSchemaKeys = Forms.findTranslationKeys(
  formName,
  dataSchemaForKeysPreloading
)

export function RegistrationDrawer() {
  const [step, setStep] = React.useState(RegistrationStep.Credentials)
  const history = ReactRouter.useHistory()
  const session = Herz.Auth.useSession()
  const { handleLogin } = useLogin()
  const { authenticated } = Herz.Auth.useSession()
  const [emailError, setEmailError] = React.useState(null)
  const [phoneError, setPhoneError] = React.useState(null)
  const [usernameError, setUsernameError] = React.useState(null)
  const [showVisualFeedback, setShowVisualFeedback] = React.useState(false)

  const registrationInProgress = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: [ProcessesIds.REGISTRATION],
    })
  )

  const location = ReactRouter.useLocation()
  const translate = Herz.I18n.useTranslate()
  const fingerprint = Herz.Seon.useFingerprint()
  const dispatch = ReactRedux.useDispatch()
  const affiliateClickId = ReactRedux.useSelector(state =>
    CombinedSelectors.getAffiliateClickId(state)
  )

  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )
  const currency = ReactRedux.useSelector(state =>
    Player.getCurrency(state.player)
  )
  const analytics = ReactRedux.useSelector(state => state.analytics)
  const affiliateSubId = Analytics.getSubId(analytics)
  const btag = Analytics.getBtag(analytics)
  const referralId = Analytics.getReferralId(analytics)
  const utmCampaign = Analytics.getUtmCampaign(analytics)
  const utmMedium = Analytics.getUtmMedium(analytics)
  const utmSource = Analytics.getUtmSource(analytics)

  const { countries, locale } = useServerConfiguration()

  const countryCallingCodes = countries.map(country =>
    country.countryCode.toString()
  )
  const socialLoginEnabled = ReactRedux.useSelector(state =>
    Configuration.getSocialLoginEnabled(state.configuration)
  )

  const params = new URLSearchParams(location.search.substring(1))
  const register = params.get('register')

  const search = new URLSearchParams(location.search)
  const token = search.get('token')
  const provider = search.get('provider')

  const countryNames = countries.map(country => country.name)
  const states = Constants.USA_STATES.map(country => country.value)

  const parsedData = React.useMemo(() => parseQueryString(location.search), [
    location.search,
  ])

  React.useEffect(() => {
    dispatch([
      Processes.stop(ProcessesIds.REGISTRATION),
      Processes.stop(ProcessesIds.REGISTER_REQUEST),
      Processes.stop(ProcessesIds.LOGIN_REQUEST),
      Processes.stop(ProcessesIds.USERNAME_UNIQUENESS_REQUEST),
      Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
    ])
  }, [])

  const googleOAuth = useGoogleOAuthSigninConsent({
    onSuccess: handleSuccess,
    onError: handleError,
  })

  const facebookOAuth = useFacebookSignin({
    onSuccess: handleSuccess,
    onError: handleError,
  })

  const schema = React.useMemo(
    () =>
      dataSchema({
        countryCallingCode: countryCallingCodes,
        country: countryNames,
        state: states,
        maxYear: new Date(Date.now()).getFullYear() - 18,
        social: Boolean(register === 'social'),
      }),
    [countryCallingCodes, countryNames]
  )

  /**
   * Social login success handler
   * @param {*} data => Success | Error response
   * @returns
   */
  function handleSuccess(data) {
    dispatch([
      Processes.stop(ProcessesIds.REGISTER_REQUEST),
      Processes.stop(ProcessesIds.LOGIN_REQUEST),
    ])

    if (data && data.session_token) {
      return handleLogin(data.session_token)
    }

    if (data.errors.length > 0) {
      const phone = data.errors.find(error => error[0] === 'address.mobile')
      const key =
        phone[1] === '"is not a valid mobile number."'
          ? 'phone-invalid'
          : 'phone-is-not-unique'

      if (phone) {
        setShowVisualFeedback(true)
        return setPhoneError(key)
      }
    }
  }

  React.useEffect(() => {
    if (register === 'social') {
      setStep(RegistrationStep.Identity)
    }
  }, [location.search])

  function startRegistration() {
    dispatch([
      Processes.start(ProcessesIds.REGISTRATION),
      Analytics.registrationStarted(),
      Notifications.dismissAll(),
    ])
  }

  function handleError() {
    dispatch([
      Processes.stop(ProcessesIds.REGISTER_REQUEST),
      Processes.stop(ProcessesIds.LOGIN_REQUEST),
    ])
  }

  function handleCredentialsSubmit(data) {
    if (register === 'social') {
      dispatch([
        Processes.start(ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST),
        Api.validatePhoneNumber(
          `+${data.countryCallingCode}`,
          data.phonenumber,
          {
            success: () => {
              setShowVisualFeedback(false)
              return [
                Processes.stop(ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST),
                Processes.start(ProcessesIds.USERNAME_UNIQUENESS_REQUEST),
                Api.validateUsername(data.username, {
                  success: res => {
                    if (res.value?.errorCode) {
                      setShowVisualFeedback(true)
                      if (res.value?.message === 'username already exists') {
                        setUsernameError(
                          'errors.registration.username-uniqueness'
                        )
                      }
                      if (res.value?.message === 'username is inappropriate') {
                        setUsernameError(
                          'errors.registration.username-is-inappropriate'
                        )
                      } else {
                        setUsernameError(
                          'errors.registration.username-verification-falied'
                        )
                      }

                      return [
                        Processes.stop(
                          ProcessesIds.USERNAME_UNIQUENESS_REQUEST
                        ),
                        Processes.stop(ProcessesIds.REGISTER_REQUEST),
                      ]
                    } else {
                      // registration
                      if (provider === 'google') {
                        return googleOAuth.handleLoginWithData(
                          prepareSocialData(data),
                          token,
                          handleError
                        )
                      }

                      if (provider === 'facebook' || provider === 'meta') {
                        return facebookOAuth.handleLoginWithData(
                          prepareSocialData(data),
                          token,
                          handleError
                        )
                      }

                      return [
                        Processes.stop(ProcessesIds.REGISTER_REQUEST),
                        Processes.stop(
                          ProcessesIds.USERNAME_UNIQUENESS_REQUEST
                        ),
                      ]
                    }
                  },
                  failure: () => {
                    setShowVisualFeedback(true)
                    return [
                      Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
                      Processes.stop(ProcessesIds.USERNAME_UNIQUENESS_REQUEST),
                      Processes.stop(ProcessesIds.REGISTER_REQUEST),
                      Processes.stop(ProcessesIds.LOGIN_REQUEST),
                    ]
                  },
                  version: 1,
                }),
              ]
            },
            failure: res => {
              setShowVisualFeedback(true)
              if (res.value?.message === 'phone-not-unique') {
                setPhoneError(
                  'errors.registration.phone-validation.phone-not-unique'
                )
              } else if (res.value?.message === 'phone-invalid') {
                setPhoneError(
                  'errors.registration.phone-validation.phone-invalid'
                )
              } else {
                setPhoneError('errors.registration.phone-validation.failed')
              }
              return [
                Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
                Processes.stop(ProcessesIds.USERNAME_UNIQUENESS_REQUEST),
                Processes.stop(ProcessesIds.REGISTER_REQUEST),
                Processes.stop(ProcessesIds.LOGIN_REQUEST),
              ]
            },
            version: 2,
          }
        ),
      ])

      return null
    }

    return dispatch([
      Processes.start(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
      Api.validateEmail(data.email, {
        success: res => {
          if (res.value?.errorCode) {
            setShowVisualFeedback(true)
            if (res.value?.message === 'email already exists') {
              setEmailError('errors.registration.email-uniqueness')
            } else {
              setEmailError('errors.registration.email-verification.failed')
            }
            return Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST)
          } else {
            return [
              Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
              Processes.start(ProcessesIds.USERNAME_UNIQUENESS_REQUEST),
              Api.validateUsername(data.username, {
                success: res => {
                  if (res.value?.errorCode) {
                    setShowVisualFeedback(true)
                    if (res.value?.message === 'username already exists') {
                      setUsernameError(
                        'errors.registration.username-uniqueness'
                      )
                    }
                    if (res.value?.message === 'username is inappropriate') {
                      setUsernameError(
                        'errors.registration.username-is-inappropriate'
                      )
                    } else {
                      setUsernameError(
                        'errors.registration.username-verification-falied'
                      )
                    }

                    return Processes.stop(
                      ProcessesIds.USERNAME_UNIQUENESS_REQUEST
                    )
                  } else {
                    setStep(RegistrationStep.Identity)
                    return [
                      Processes.stop(ProcessesIds.REGISTER_REQUEST),
                      Processes.stop(ProcessesIds.USERNAME_UNIQUENESS_REQUEST),
                    ]
                  }
                },
                failure: () => {
                  setShowVisualFeedback(true)
                  return [
                    Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
                    Processes.stop(ProcessesIds.USERNAME_UNIQUENESS_REQUEST),
                    Processes.stop(ProcessesIds.REGISTER_REQUEST),
                  ]
                },
                version: 1,
              }),
            ]
          }
        },
        failure: () => {
          setShowVisualFeedback(true)
          return [
            Processes.stop(ProcessesIds.REGISTER_REQUEST),
            Processes.stop(ProcessesIds.EMAIL_UNIQUENESS_REQUEST),
          ]
        },
        version: 1,
      }),
    ])
  }

  function prepareSocialData(data) {
    return {
      city: data.city,
      mobile: `+${data.countryCallingCode}${data.phonenumber}`,
      zip: data.zip,
      address: data.street,
      state: data.state,
      affiliateClickId,
      affiliateSubId,
      access_token: parsedData.access_token,
      // We don't let them decide if they want marketing stuff or not
      // By registering they approve of us sending (Last checkbox that also approves T&C & Privacy-Policy)
      allowEmail: data.promotional,
      allowSms: data.promotional,
      dob: `${data.bdayYear}-${data.bdayMonth}-${data.bdayDay}`,
      clientType,
      country_code:
        countries?.length === 1
          ? countries[0]?.alpha2
          : countries?.find(country => country.name === data.country)['alpha2'],
      currency,
      first_name: data.firstName.trim(),
      last_name: data.lastName.trim(),
      language: locale.language,
      generateUsername: false,
      username: data.username,
      netrefererBTag: btag,
      referredById: referralId,
      utmCampaign,
      utmMedium,
      utmSource,
      seonSession: fingerprint.value,
    }
  }

  function prepareData(data) {
    return {
      affiliateClickId,
      affiliateSubId,
      // We don't let them decide if they want marketing stuff or not
      // By registering they approve of us sending (Last checkbox that also approves T&C & Privacy-Policy)
      allowEmail: data.promotional,
      allowSms: data.promotional,
      birthdate: `${data.bdayDay}/${data.bdayMonth}/${data.bdayYear}`,
      city: data.city,
      clientType,
      countryCallingCode: `+${data.countryCallingCode}`,
      countryCode:
        countries?.length === 1
          ? countries[0]?.alpha2
          : countries?.find(country => country.name === data.country)['alpha2'],
      currency,
      email: data.email,
      firstName: data.firstName.trim(),
      generateUsername: false,
      language: locale.language,
      lastName: data.lastName.trim(),
      phoneNumber: data.phonenumber,
      netrefererBTag: btag,
      referredById: referralId,
      password: data.password,
      passwordConfirmation: data.password,
      phoneVerificationCode: data.phoneVerificationCode,
      street: data.street,
      state: data.state,
      username: data.username,
      utmCampaign,
      utmMedium,
      utmSource,
      postalCode: data.zip,
      seonSession: fingerprint.value,
    }
  }

  function endRegistration() {
    dispatch([
      Processes.stop(ProcessesIds.REGISTRATION),
      Processes.stop(ProcessesIds.REGISTER_REQUEST),
      Processes.stop(ProcessesIds.LOGIN_REQUEST),
    ])
  }

  function handleRegister(data) {
    const userData = prepareData(data)

    dispatch([
      Processes.start(ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST),
      Api.validatePhoneNumber(`+${data.countryCallingCode}`, data.phonenumber, {
        success: () => {
          setShowVisualFeedback(false)
          return [
            Processes.stop(ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST),
            Processes.start(ProcessesIds.REGISTER_REQUEST),
            Processes.start(ProcessesIds.LOGIN_REQUEST),
            session
              .create(userData)
              .then(response => {
                const token = response.createPlayer?.session?.token
                if (token) {
                  history.push('/casino')
                  handleLogin(token)

                  return
                }
                if (response.error) {
                  response.error?.errors?.forEach(item => {
                    // eslint-disable-next-line no-console
                    console.error(item)
                    dispatch(
                      Notifications.add({
                        message: `errors.registration.${item}`,
                        level: 'error',
                      })
                    )
                  })
                  if (
                    response.error.code === 'errors.login.seon' ||
                    (response.error.name === 'LockedError' &&
                      response.error.reason === 'seon')
                  ) {
                    dispatch(
                      Notifications.add({
                        message: 'errors.registration.seon-locked',
                        level: 'error',
                      })
                    )
                  }
                  return
                }
                throw new Error('Response without token and error message')
              })
              .catch(error => {
                // eslint-disable-next-line no-console
                console.error(error)
              })
              .finally(() => {
                endRegistration()
              }),
          ]
        },
        failure: res => {
          setShowVisualFeedback(true)
          if (res.value?.message === 'phone-not-unique') {
            setPhoneError(
              'errors.registration.phone-validation.phone-not-unique'
            )
          } else if (res.value?.message === 'phone-invalid') {
            setPhoneError('errors.registration.phone-validation.phone-invalid')
          } else {
            setPhoneError('errors.registration.phone-validation.failed')
          }
          return Processes.stop(ProcessesIds.PHONENUMBER_UNIQUENESS_REQUEST)
        },
        version: 2,
      }),
    ])
  }

  function secondaryAction() {
    setStep(RegistrationStep.Credentials)
    dispatch([
      Processes.stop(ProcessesIds.REGISTER_REQUEST),
      Processes.stop(ProcessesIds.LOGIN_REQUEST),
    ])
    history.push('/?register=me')
  }

  if (fingerprint.fetching || authenticated) {
    return null
  }

  return (
    <Forms.Provider
      name={formName}
      schema={schema}
      onSubmit={(formErrors, data) => {
        if (!registrationInProgress) {
          startRegistration()
        }

        switch (step) {
          case RegistrationStep.Credentials: {
            if (
              Object.keys(formErrors).some(error =>
                [
                  '#/properties/email',
                  '#/properties/password',
                  '#/properties/username',
                ].includes(error)
              )
            ) {
              setShowVisualFeedback(true)
            } else {
              handleCredentialsSubmit(data)
            }
            break
          }

          case RegistrationStep.Identity: {
            if (
              formErrors.constructor === Object &&
              Object.keys(formErrors).length > 0
            ) {
              setShowVisualFeedback(true)
            } else {
              if (register === 'social') {
                handleCredentialsSubmit(data)
              } else {
                handleRegister(data)
              }
            }
            break
          }

          default: {
            break
          }
        }
      }}
    >
      <QueryDrawer
        onSecondaryAction={
          step > RegistrationStep.Credentials ? () => secondaryAction() : null
        }
        activeQueryName="register"
        additionalQueries={{ params: null, token: null, provider: null }}
        onSideEffect={secondaryAction}
        title={translate(`register.title.step-${step + 1}`)}
      >
        <Common.Box pt="1">
          <Common.Box
            position="relative"
            width="100%"
            height="64px"
            background="linear-gradient(0deg, #0D111D 0%, #5E1EC6 100%)"
            borderRadius="14px"
            border="1px solid #233980"
            textAlign="center"
            mb={1}
            p="6px"
          >
            <Common.Box
              as="img"
              position="absolute"
              top="0px"
              left="-5px"
              alt={`registration.banner.left-${step + 1}`}
              src={translate(`registration.banner.left-${step + 1}`)}
            />
            <Common.Box
              as="img"
              position="absolute"
              top="0px"
              right="-6px"
              alt={`registration.banner.right-${step + 1}`}
              src={translate(`registration.banner.right-${step + 1}`)}
            />
            <Common.Text fontFamily="Raleway" fontSize="17px" fontWeight="600">
              {translate(`registration.banner.header-${step + 1}.part-1`)}
            </Common.Text>
            <GradientText pt="0px" fontSize="24px">
              {translate(`registration.banner.header-${step + 1}.part-2`)}
            </GradientText>
            <ProgressBar step={step} />
          </Common.Box>
          <RegistrationForm
            initialValues={parsedData}
            social={Boolean(register === 'social')}
            countryNames={countryNames}
            countryCallingCodes={countryCallingCodes}
            emailErrorKey={emailError}
            phoneErrorKey={phoneError}
            usernameErrorKey={usernameError}
            showVisualFeedback={showVisualFeedback}
            step={step}
            onClearEmailErrorKey={() => setEmailError(null)}
            onClearPhoneErrorKey={() => setPhoneError(null)}
            onClearUsernameErrorKey={() => setUsernameError(null)}
          />
        </Common.Box>
        {step === Constants.RegistrationStep.Credentials && (
          <>
            <Common.Box width="100%">
              <SocialLogin hidden={!socialLoginEnabled} />
            </Common.Box>
            <Common.Box pt="1" fontSize="14px" textAlign="center">
              <Common.Box as="span" pr="6px" opacity="0.6">
                {translate('landing-page.has-account')}
              </Common.Box>
              <ReactRouter.Link
                to="/login"
                style={{ textDecoration: 'underline', fontWeight: 700 }}
              >
                {translate('main-menu.login')}
              </ReactRouter.Link>
            </Common.Box>
          </>
        )}
      </QueryDrawer>
    </Forms.Provider>
  )
}

Herz.I18n.Loader.preload(
  [
    'register.title.step-1',
    'register.title.step-2',
    'register.title.step-3',
    'registration.banner.left-1',
    'registration.banner.left-2',
    'registration.banner.left-3',
    'registration.banner.right-1',
    'registration.banner.right-2',
    'registration.banner.right-3',
    `registration.banner.header-1.part-1`,
    `registration.banner.header-2.part-1`,
    `registration.banner.header-3.part-1`,
    `registration.banner.header-1.part-2`,
    `registration.banner.header-2.part-2`,
    `registration.banner.header-3.part-2`,
    'landing-page.has-account',
    'main-menu.login',
    'gracaptcha.terms.note',
    'errors.registration.seon-locked',
    ...preloadSchemaKeys,
  ],
  RegistrationDrawer
)
