import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import { Button } from './button'

export function Card(props) {
  const translate = Herz.I18n.useTranslate()

  const sweepsCoins = props?.rewards?.find(reward => reward?.currency === 'SCC')
  const goldCoins = props.rewards?.find(reward => reward?.currency === 'GCC')

  const isDiscounted =
    sweepsCoins.promo_amount_cents > sweepsCoins.regular_amount_cents ||
    goldCoins.promo_amount_cents > goldCoins.regular_amount_cents

  return (
    <Common.Box width="158px" minHeight="228px" py={1}>
      <Common.Box
        width="100%"
        height="100%"
        borderRadius="8px"
        backgroundImage={
          isDiscounted
            ? 'url(/images/card-bg-offer.svg)'
            : 'url(/images/card-bg.svg)'
        }
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        position="relative"
      >
        <Common.Box
          position="absolute"
          top="0px"
          left="0px"
          width="100%"
          zIndex="10"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="160"
            height="52"
            viewBox="0 0 160 52"
            fill="none"
          >
            <path
              d="M160 0H0V52C0 52 34.6082 41.547 80 41.547C125.392 41.547 160 52 160 52V0Z"
              fill="#19004E"
            />
          </svg>
        </Common.Box>
        <Common.Box
          zIndex="9999"
          position="relative"
          display="flex"
          padding="8px"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Common.Box
            as="h4"
            fontWeight="700"
            fontFamily="Raleway"
            color="white"
            fontSize="12px"
          >
            {translate('coin-store.bonus.title')}
          </Common.Box>
          <Common.Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            {isDiscounted && (
              <Common.Box
                as="p"
                fontSize={'10px'}
                fontWeight="700"
                fontFamily="Raleway"
                color="white"
                textAlign="center"
                opacity="0.5"
                pr={0}
                style={{
                  textDecoration: 'line-through',
                }}
              >
                {translate('coin-store.bonus', {
                  amount: sweepsCoins?.regular_amount_cents / 100,
                })}
              </Common.Box>
            )}
            <Common.Box
              as="p"
              fontSize={isDiscounted ? '8px' : '12px'}
              fontWeight="700"
              fontFamily="Raleway"
              color="#4AE948"
              textAlign="center"
            >
              {translate('coin-store.bonus', {
                amount: isDiscounted
                  ? sweepsCoins?.promo_amount_cents / 100
                  : sweepsCoins?.regular_amount_cents / 100,
              })}
            </Common.Box>
          </Common.Box>
        </Common.Box>
        <Common.Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          flexDirection="column"
          zIndex="99"
        >
          {isDiscounted && (
            <Common.Box
              as="img"
              src="/images/special-offer-header.svg"
              alt="Special offer"
              width="143px"
              height="31px"
              position="absolute"
              top="7px"
            />
          )}
          <Common.Box
            as="img"
            alt="coin"
            src={`/images/coins/coin-${props.index > 6 ? 6 : props.index}.png`}
            width="100%"
            pt={isDiscounted ? '20px' : '0px'}
          />
          <Common.Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Common.Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              {goldCoins?.promo_amount_cents >
                goldCoins.regular_amount_cents && (
                <Common.Box
                  as="p"
                  fontSize={'10px'}
                  fontWeight="700"
                  fontFamily="Raleway"
                  color="white"
                  textAlign="center"
                  opacity="0.5"
                  pr={0}
                  style={{
                    textDecoration: 'line-through',
                  }}
                >
                  {goldCoins?.regular_amount_cents / 100}
                </Common.Box>
              )}
              <Common.Box
                as="h3"
                fontSize="14px"
                fontFamily="Raleway"
                color="#FFF"
                fontWeight="700"
              >
                {goldCoins?.promo_amount_cents > goldCoins.regular_amount_cents
                  ? goldCoins?.promo_amount_cents / 100
                  : goldCoins?.regular_amount_cents / 100}
              </Common.Box>
            </Common.Box>
            <Common.Box
              as="p"
              fontSize="14px"
              fontFamily="Raleway"
              color="#FFF"
              fontWeight="700"
            >
              {translate('coin-store.gold-coins')}
            </Common.Box>
            <Common.Box py={1}>
              <Button onClick={props.onStepChange} variant="primary">
                ${props.amount_cents / 100}
              </Button>
            </Common.Box>
          </Common.Box>
        </Common.Box>
        <Common.Box
          position="absolute"
          top="0px"
          left="0px"
          width="100%"
          height="100%"
          style={{
            pointerEvents: 'none',
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundImage={
            isDiscounted
              ? 'url(/images/card-pattern-offer.svg)'
              : 'url(/images/card-pattern.svg)'
          }
        ></Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

Card.propTypes = {
  amount_cents: PropTypes.number,
  rewards: PropTypes.object,
  onStepChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}
