import * as React from 'react'
import PropTypes from 'prop-types'

/**
 * Convert USD to GC and SC.
 * @param {number} usd - The amount in USD.
 * @returns {{gc: number, sc: number}} An object containing the amount in GC and SC.
 * @example
 * convertUsdToGcAndSc(9.99); // { gc: 1000, sc: 10.00 }
 *
 * Details: https://herogaming.atlassian.net/wiki/spaces/DEV/pages/2575433729/New+Currency
 */
export function convertUsdToGcAndSc(usd) {
  const gc = usd * 100 + 1 // Calculate GC
  const sc = usd + 0.01 // Calculate SC
  return { gc, sc }
}

/**
 * Convert GC to USD and SC.
 * @param {number} gc - The amount in GC.
 * @returns {{usd: number, sc: number}} An object containing the amount in USD and SC.
 * @example
 * convertGcToUsdAndSc(1000); // { usd: 9.99, sc: 10.00 }
 *
 * Details: https://herogaming.atlassian.net/wiki/spaces/DEV/pages/2575433729/New+Currency
 */
export function convertGcToUsdAndSc(gc) {
  const usd = (gc - 1) / 100 // Calculate USD from GC
  const sc = usd + 0.01 // Calculate SC from USD
  return { usd, sc }
}

/**
 * @name useCustomAmount
 * @description A custom React hook to manage and convert amounts between USD, GC, and SC.
 * 
 * Convertion rates
 
       USD to GC: 1 USD = 100 GC (plus 1 for each transaction)
       USD to SC: 1 USD = 1 SC (plus 0.01 for each transaction)
       GC to USD: 100 GC = 1 USD (subtract 1 for each transaction)
       GC to SC: Convert GC to USD first, then USD to SC.
       SC to USD: 1 SC = 1 USD (minus 0.01 for each transaction)
       SC to GC: Convert SC to USD first, then USD to GC.
    
   Convertion example
 
        9.99 USD in GC would be 1000 GC = 9.99 x 100 = 999 + 1 = 1000
        SC is 1-1 with the USD -0.01
        9.99 USD in SC would be 10.00 SC
      
        Details: https://herogaming.atlassian.net/wiki/spaces/DEV/pages/2575433729/New+Currency
 */

export function useCustomAmount(props) {
  // In USD
  const { max } = props

  const [usdAmount, setUsdAmount] = React.useState('0.0')
  const [gccAmount, setGccAmount] = React.useState('0.0')
  const [sccAmount, setSccAmount] = React.useState('0.0')

  React.useEffect(() => {
    // Set initial amount if provided
    if (props.initialAmount && props.initialAmount > 0 && !isNaN(props.max)) {
      const amount = props.initialAmount / 100
      const normalizedAmount = normalizeAmount(amount, max)
      const { gc, sc } = convertUsdToGcAndSc(normalizedAmount)

      setUsdAmount(normalizedAmount.toFixed(2))
      setGccAmount(gc.toFixed(2))
      setSccAmount(sc.toFixed(2))
    }
  }, [props.initialAmount, max])

  /**
   * @access private
   * @param {float} value
   * @returns value
   */
  function normalizeAmount(value, max) {
    const numValue = Number.parseFloat(value)

    if (Number.isNaN(numValue)) {
      return 0
    }

    return Math.max(0, Math.min(numValue, max))
  }

  /**
   * @access public
   * @param {float} amount
   * This method updates values
   * based on USD amount input value
   */
  function onUsdUpdate(amount) {
    if (/[.,]$/.test(amount)) {
      return setUsdAmount(amount)
    }

    if (amount == 0) {
      setUsdAmount(0)
      setGccAmount(0)
      return setSccAmount(0)
    }

    const maxForUsd = max / 100
    const normalizedAmount = normalizeAmount(amount, maxForUsd)
    const { gc, sc } = convertUsdToGcAndSc(normalizedAmount)

    setUsdAmount(normalizedAmount)
    setGccAmount(gc.toFixed(2))
    setSccAmount(sc.toFixed(2))
  }

  /**
   * @access public
   * @param {float} amount
   * This method updates values
   * based on GCC amount input values
   */
  function onGccUpdate(amount) {
    if (/[.,]$/.test(amount)) {
      return setGccAmount(amount)
    }

    if (amount == 0) {
      setUsdAmount(0)
      setGccAmount(0)
      return setSccAmount(0)
    }

    const maxForGc = max + 1
    const normalizedAmount = normalizeAmount(amount, maxForGc)
    const { usd, sc } = convertGcToUsdAndSc(normalizedAmount)
    setGccAmount(normalizedAmount)
    setUsdAmount(usd.toFixed(2))
    setSccAmount(sc.toFixed(2))
  }

  return [onUsdUpdate, onGccUpdate, usdAmount, sccAmount, gccAmount]
}

useCustomAmount.propTypes = {
  initialAmount: PropTypes.number,
}
