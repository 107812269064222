import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'

import * as Icons from '../icons'

import {
  GridLarge,
  GridMedium,
  GridMediumCustom,
  GridSmall,
  GridSmallCustom,
} from './grids'

function Grid(props) {
  if (props.size === 'large') {
    return <GridLarge>{props.children}</GridLarge>
  }
  if (props.size === 'medium') {
    return <GridMedium>{props.children}</GridMedium>
  }

  if (props.size === 'small') {
    return <GridSmall>{props.children}</GridSmall>
  }

  if (props.size === 'custom-small') {
    return <GridSmallCustom>{props.children}</GridSmallCustom>
  }

  if (props.size === 'custom-medium') {
    return <GridMediumCustom>{props.children}</GridMediumCustom>
  }

  return <GridSmall>{props.children}</GridSmall>
}

Grid.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
}

export function GameTileRow(props) {
  const translate = Herz.I18n.useTranslate()
  const location = ReactRouter.useLocation()
  const isLiveCasino = location.pathname.includes('live-casino')
  const linkToMore = `/${isLiveCasino ? 'live-casino' : 'casino'}/${props.id}`

  return (
    <React.Fragment>
      <Common.Box
        alignItems="center"
        display="flex"
        justifyContent={props.sectionTitle ? 'space-between' : 'flex-end'}
        py={1}
        px={[1, 1, 1, '0px']}
      >
        {props.sectionTitle && (
          <Common.Box
            fontSize="18px"
            fontWeight="700"
            fontFamily="Raleway"
            color="#B2C2EE"
          >
            {props.sectionTitle}
          </Common.Box>
        )}
        {!props.hideButtonMore && (
          <ReactRouter.Link to={linkToMore}>
            <Common.Flex>
              <Common.Box
                as="p"
                fontSize="14px"
                fontFamily="Raleway"
                color="#FFF"
                fontWeight="500"
                pr={0}
              >
                {translate('game-tile-section.show-more')}
              </Common.Box>
              <Icons.ArrowRightAlt />
            </Common.Flex>
          </ReactRouter.Link>
        )}
      </Common.Box>
      <Common.Box display="block" width="100%" style={{ overflow: 'hidden' }}>
        <Grid size={props.size}>{props.children}</Grid>
      </Common.Box>
    </React.Fragment>
  )
}

GameTileRow.propTypes = {
  sectionTitle: PropTypes.string,
  layout: PropTypes.string,
  id: PropTypes.string,
  columns: PropTypes.number,
  children: PropTypes.node,
  size: PropTypes.string,
  hideButtonMore: PropTypes.bool,
}
