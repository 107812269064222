import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as Urql from 'urql'
import { gql } from 'urql'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import * as locks from '@rushplay/compliance/locks'
import styled from '@emotion/styled'

import * as Constants from './constants'
import * as Icons from './icons'
import { Button } from './button'
import { DailyRewardBanner } from './daily-reward-banner'
import { DrawerMenuItem } from './drawer-menu-item'
import { InviteAFriend } from './invite-a-friend'
import { LanguageSelector } from './language-selector'
import { MoneyBalanceDetails } from './money-balance-details'
import { QueryDrawer } from './query-drawer'
import { VipProgressBar } from './vip-progress-bar'
import { useMenuQueries } from './use-menu-queries'

const getCasinoCategories = gql`
  query CasinoCategories {
    catalog(key: "main") {
      ... on GetCatalogSuccess {
        categories {
          id
          key
        }
      }
    }
  }
`

const MenuContainer = styled(Common.Box)`
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export function DrawerMenu() {
  const location = ReactRouter.useLocation()
  const { authenticated } = Herz.Auth.useSession()

  const [dropDown, setDropDown] = React.useState(null)
  const [response] = Urql.useQuery({ query: getCasinoCategories })
  const {
    loginQuery,
    registerQuery,
    purchaseLimitQuery,
    timeoutQuery,
    realityCheckQuery,
    myProfileQuery,
    gamingHistoryQuery,
    transactionHistoryQuery,
  } = useMenuQueries()

  const translate = Herz.I18n.useTranslate(
    () => [
      ...(response.data?.catalog?.categories?.map(
        category => `catalog-categories.${category.key}.title`
      ) || []),
    ],
    [response.data]
  )
  const locked = ReactRedux.useSelector(
    state => locks.isInitialized(state.locks) && locks.hasLocks(state.locks)
  )

  function onDropDown(name) {
    if (name === dropDown) {
      return setDropDown(null)
    }

    return setDropDown(name)
  }

  return (
    <QueryDrawer light activeQueryName="menu">
      <MenuContainer
        width="100%"
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="space-between"
        pt={4}
      >
        <Common.Box width="100%" display="flex" flexDirection="column">
          {authenticated ? (
            <React.Fragment>
              <Common.Box width="100%">
                <Common.Box
                  color="#CDCED0"
                  fontSize="24px"
                  fontFamily="Raleway"
                  frontWeight="600"
                  as="h3"
                  pb={1}
                >
                  {translate('main-menu.hub.title')}
                </Common.Box>
                <MoneyBalanceDetails />
              </Common.Box>

              <VipProgressBar small />
              <Common.Box my={1}>
                <DailyRewardBanner />
              </Common.Box>
            </React.Fragment>
          ) : (
            <Common.Box
              py={0}
              width="100%"
              display="flex"
              justifyContent="space-between"
              style={{ gap: '10px' }}
            >
              <ReactRouter.Link
                style={{ width: '100%' }}
                to={`?${registerQuery}`}
              >
                <Button stretch variant="primary">
                  {translate('main-menu.register')}
                </Button>
              </ReactRouter.Link>
              <ReactRouter.Link style={{ width: '100%' }} to={`?${loginQuery}`}>
                <Button stretch variant="secondary">
                  {translate('main-menu.login')}
                </Button>
              </ReactRouter.Link>
            </Common.Box>
          )}

          <Common.Box
            as="nav"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            py={1}
          >
            <DrawerMenuItem
              name="casino"
              disabled={locked}
              dropDown={dropDown}
              onDropDown={onDropDown}
              items={response.data?.catalog?.categories.map(item => ({
                label: translate(`catalog-categories.${item.key}.title`),
                to: `/casino/${item.key}`,
                active: location.pathname.includes(item.key),
              }))}
              icon={Icons.Casino}
            >
              {translate('main-menu.casino')}
            </DrawerMenuItem>
            <DrawerMenuItem
              name="live-casino"
              dropDown={dropDown}
              disabled={locked}
              onDropDown={onDropDown}
              items={Constants.liveCasinoCategories.map(item => ({
                label: item,
                to: `/live-casino/${item}/`,
                active: location.pathname.includes(item),
              }))}
              icon={Icons.LiveCasino}
            >
              {translate('main-menu.live-casino')}
            </DrawerMenuItem>
            <DrawerMenuItem
              active={location.pathname.includes('sports')}
              disabled={locked}
              icon={Icons.Sports}
              to="/sports"
            >
              {translate('main-menu.sports')}
            </DrawerMenuItem>
            <DrawerMenuItem
              active={location.pathname.includes('vip')}
              disabled={locked}
              icon={Icons.Vip}
              to="/vip"
            >
              {translate('main-menu.vip')}
            </DrawerMenuItem>
            <DrawerMenuItem
              disabled={locked}
              icon={Icons.PromotionsMenu}
              to={`/promotions`}
            >
              {translate('main-menu.promotion-notifications')}
            </DrawerMenuItem>
          </Common.Box>
          {authenticated && (
            <Common.Box pt={0}>
              <InviteAFriend />
            </Common.Box>
          )}

          <Common.Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            pt={2}
          >
            {authenticated && (
              <>
                <DrawerMenuItem
                  name="my-account"
                  dropDown={dropDown}
                  disabled={locked}
                  variant="secondary"
                  onDropDown={onDropDown}
                  items={[
                    {
                      label: translate('account.nav.my-profile'),
                      to: `?${myProfileQuery}`,
                    },
                    {
                      label: translate('account.nav.my-identity'),
                      to: '/my-identity',
                    },
                    {
                      label: translate('account.nav.transaction-history'),
                      to: `?${transactionHistoryQuery}`,
                    },
                  ]}
                >
                  {translate('account.nav.my-profile')}
                </DrawerMenuItem>
                <DrawerMenuItem
                  name="responsible-gaming"
                  dropDown={dropDown}
                  disabled={locked}
                  variant="secondary"
                  onDropDown={onDropDown}
                  items={[
                    {
                      label: translate('purchase-limits.title'),
                      to: `?${purchaseLimitQuery}`,
                    },
                    {
                      label: translate('time-out.title'),
                      to: `?${timeoutQuery}`,
                    },
                    {
                      label: translate('account.nav.reality-check'),
                      to: `?${realityCheckQuery}`,
                    },
                  ]}
                >
                  {translate('account.nav.responsible-gaming')}
                </DrawerMenuItem>
                <DrawerMenuItem
                  name="gameplay"
                  dropDown={dropDown}
                  disabled={locked}
                  variant="secondary"
                  onDropDown={onDropDown}
                  items={[
                    {
                      label: translate('account.nav.gambling-history'),
                      to: `?${gamingHistoryQuery}`,
                    },
                  ]}
                >
                  {translate('gameplay')}
                </DrawerMenuItem>
                <DrawerMenuItem
                  name="terms-and-policies"
                  dropDown={dropDown}
                  disabled={locked}
                  variant="secondary"
                  onDropDown={onDropDown}
                  items={[
                    {
                      label: translate('terms-and-conditions.nav.general'),
                      to: '/terms-and-conditions/general',
                    },
                  ]}
                >
                  {translate('terms-and-conditions')}
                </DrawerMenuItem>
              </>
            )}
          </Common.Box>
        </Common.Box>

        <Common.Box borderTop="1px solid #2A3149" width="100%">
          {!authenticated && (
            <>
              <LanguageSelector
                name="language-selector"
                dropDown={dropDown}
                onDropDown={onDropDown}
              />
              <DrawerMenuItem
                name="terms-and-policies"
                dropDown={dropDown}
                disabled={locked}
                border
                variant="secondary"
                onDropDown={onDropDown}
                items={[
                  {
                    label: translate('terms-and-conditions.nav.general'),
                    to: '/terms-and-conditions/general',
                  },
                ]}
              >
                {translate('terms-and-conditions')}
              </DrawerMenuItem>
            </>
          )}
          <Common.Box
            fontWeight="700"
            fontFamily="Raleway"
            fontSize="14px"
            style={{
              textTransform: 'uppercase',
              borderTop: '1px solid #2A3149',
              py: 1,
            }}
            color="#5A5959"
          >
            <a
              href="mailto:guestservices@legendz.com"
              data-testid="main-menu-support"
            >
              <Common.Space py={1} px={0}>
                {translate('main-menu.support')}
              </Common.Space>
            </a>
          </Common.Box>
          {authenticated && (
            <Common.Box
              fontWeight="700"
              fontFamily="Raleway"
              fontSize="14px"
              style={{
                textTransform: 'uppercase',
              }}
              color="#5A5959"
            >
              <ReactRouter.Link to="/logout" data-testid="main-menu-log-out">
                <Common.Space p={0}>
                  {translate('main-menu.log-out')}
                </Common.Space>
              </ReactRouter.Link>
            </Common.Box>
          )}
        </Common.Box>
      </MenuContainer>
    </QueryDrawer>
  )
}

Herz.I18n.Loader.preload(
  [
    'main-menu.gold-coins',
    'main-menu.account',
    'main-menu.login',
    'main-menu.register',
    'main-menu.my-games',
    'main-menu.casino',
    'main-menu.live-casino',
    'main-menu.vip',
    'main-menu.promotion-notifications',
    'account.nav.me-moments',
    'main-menu.support',
    'main-menu.deposit',
    'main-menu.withdraw',
    'main-menu.log-out',
    'badge.new',
    'campaign-tooltip.header',
    'campaign-tooltip.body',
    'main-menu.sports',
    'terms-and-conditions.nav.general',
    'terms-and-conditions',
    'account.nav.my-profile',
    'account.nav.my-identity',
    'account.nav.gambling-history',
    'account.nav.receipts',
    'gameplay',
    'main-menu.hub.title',
    'main-menu.redeemable',
    'main-menu.sweeps-coins',
    'main-menu.unplayed',
    'purchase-limits.title',
    'time-out.title',
    'account.nav.reality-check',
    'account.nav.responsible-gaming',
    'account.nav.transaction-history',
  ],
  DrawerMenu
)
