import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constans from './constants'
import * as Promotions from './promotions'
import { SeoSection } from './seo-section'

const PromotionTitle = styled.span`
  background-image: linear-gradient(180deg, #fff 0%, #9090ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  line-height: 1;
  font-family: Rubik;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50px;
  ${css({
    fontSize: 6,
    px: [0, null, null, 3],
  })}
`

const PromotionImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`

function getTagColor(tag) {
  if (tag === 'sports') {
    return '#CC18DC'
  }
  if (tag === 'casino') {
    return '#1849DC'
  }

  return '#5718DC'
}

function PromotionItem(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      `promotions.${props.promotionKey}.title`,
      `promotions.${props.promotionKey}.terns-condition`,
      `promotions.${props.promotionKey}.img-src`,
      `promotions.${props.promotionKey}.description`,
      `promotions.${props.promotionKey}.button-link`,
    ],
    [props.promotionKey]
  )

  return (
    <Common.Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      width="100%"
      maxWidth={['225px', null, null, '300px']}
      background="#0C132B"
      border="1px solid #212836"
      borderRadius="14px"
      boxShadow="0px 0px 4px 0px #1849DC"
    >
      <Common.Box
        width="100%"
        height={['40px', null, '100px']}
        position="relative"
      >
        <PromotionImage
          src={translate(`promotions.${props.promotionKey}.img-src`)}
        />
      </Common.Box>
      <Common.Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        px={1}
        py={0}
        justifyContent="center"
      >
        {props.tags.length ? (
          props.tags.map(tag => (
            <Common.Box
              key={tag}
              backgroundColor={getTagColor(tag)}
              borderRadius="10px"
              fontSize={1}
              m="2px"
              color="static-white"
              p="3px 11px"
              textAlign="center"
            >
              {[...tag].map((char, index) =>
                index === 0 ? char.toUpperCase() : char
              )}
            </Common.Box>
          ))
        ) : (
          <Common.Box width="100%" height="18px" />
        )}
      </Common.Box>
      <PromotionTitle>
        {translate(`promotions.${props.promotionKey}.title`)}
      </PromotionTitle>
      <Common.Box
        p={0}
        textAlign="center"
        fontSize={2}
        fontFamily="head"
        lineHeight="normal"
        flex="1"
      >
        {translate(`promotions.${props.promotionKey}.description`)}
      </Common.Box>
    </Common.Box>
  )
}

PromotionItem.propTypes = {
  promotionKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export function PromotionsPage() {
  const dispatch = ReactRedux.useDispatch()
  const translate = Herz.I18n.useTranslate()

  const promotions = ReactRedux.useSelector(state =>
    Promotions.getPromotionsToDisplay(state.promotions, { dateNow: Date.now() })
  )

  React.useEffect(() => {
    dispatch(Promotions.fetch())
  }, [])
  return (
    <Common.Box
      width="100%"
      maxWidth={Constans.ContentMaxWidth}
      display="flex"
      flexDirection="column"
      alignItems="center"
      m="0 auto"
      px={1}
      pb={2}
    >
      <Common.Box
        as="h2"
        fontFamily="head"
        fontSize={6}
        color="static-white"
        fontWeight="400"
        lineHeight="32px"
        py={2}
      >
        {translate('promotions.page.title')}
      </Common.Box>
      <Common.Box
        width="100%"
        display="grid"
        gridTemplateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)']}
        gridColumnGap={2}
        gridRowGap={7}
        justifyItems="center"
        mt={6}
      >
        {promotions.map(promotion => (
          <PromotionItem
            key={promotion.key}
            promotionKey={promotion.key}
            {...promotion}
          />
        ))}
      </Common.Box>
      <SeoSection content={translate('promotions-page.seo-content')} />
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  ['promotions.page.title', 'promotions-page.seo-content'],
  PromotionsPage
)

export default PromotionsPage
