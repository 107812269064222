import * as ReactRedux from 'react-redux'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as Urql from 'urql'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { differenceInMinutes } from 'date-fns'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as emotion from '@emotion/core'
import styled from '@emotion/styled'

import * as Configuration from './configuration'
import * as Player from './player'
import { Button } from './button'
import { Card } from './card'
import { Checkbox } from './checkbox'
import { Heading } from './heading'
import { HtmlContent } from './html-content'
import { clearSessionStats, fetchSessionStats, getSessionStats } from './player'

const PopupWrapper = styled(Modal)`
  outline: none;
  margin: 8px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgb(0, 0, 0, 0.8);
`

const Divider = styled.hr`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  ${props => emotion.css`
        border-top: 1px solid ${props.theme.colors['nav']};
  `}
`

const getCreatedAt = `
    query CreatedAt {
      player {
        session {
          createdAt
        }
      }
    }
`

function RealityCheckModal(props) {
  const [response] = Urql.useQuery({ query: getCreatedAt })
  const translate = Herz.I18n.useTranslate()
  const [checked, setChecked] = React.useState(false)
  const history = ReactRouter.useHistory()
  const dispatch = ReactRedux.useDispatch()
  const period = ReactRedux.useSelector(state =>
    Player.getRealityCheckPeriod(state.player)
  )
  const createdAt = response?.data?.player?.session?.createdAt

  const sessionStats = ReactRedux.useSelector(state =>
    getSessionStats(state.player)
  )

  React.useEffect(() => {
    dispatch(fetchSessionStats())
    return () => {
      dispatch(clearSessionStats())
    }
  }, [])

  return (
    <PopupWrapper ariaHideApp={false} contentLabel="contentLabel" isOpen>
      <Common.Box display="flex" textAlign="center" fontSize={2}>
        <Card padding={2}>
          <Heading level={3} color="white">
            {translate('reality-check.reminder')}
          </Heading>
          <Common.Box
            pt={0}
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            lineHeight="1.5"
          >
            <HtmlContent
              html={{
                __html: translate('reality-check.playing-for', {
                  time: period / 60000,
                }),
              }}
            />
            {sessionStats && 'gamingBalanceCents' in sessionStats && (
              <Common.Text lineHeight="1.5" textAlign="center">
                <Divider />
                <Heading level={3} color="white">
                  {translate('reality-check.session-stats')}
                </Heading>
                <p>
                  {translate(
                    sessionStats.gamingBalanceCents < 0
                      ? 'reality-check.session-balance-negative'
                      : 'reality-check.session-balance-positive',
                    {
                      balance: sessionStats.gamingBalanceCents,
                    }
                  )}
                </p>
                {createdAt ? (
                  <p>
                    <Common.Timestamp>
                      {timestamp => {
                        const date = new Date(createdAt)
                        const createdAtTimestamp = date.getTime()
                        return translate('reality-check.session-logged-in', {
                          minutes: differenceInMinutes(
                            timestamp,
                            createdAtTimestamp
                          ),
                        })
                      }}
                    </Common.Timestamp>
                  </p>
                ) : null}
              </Common.Text>
            )}
          </Common.Box>
          <Checkbox
            name="reality-check-acknowledge"
            checked={checked}
            label={translate('reality-check.acknowledge')}
            value={checked}
            onChange={() => setChecked(current => !current)}
          />
          <Common.Space py={1}>
            <Button
              variant="primary"
              disabled={!checked}
              onClick={props.handleDismiss}
            >
              {translate('reality-check.continue')}
            </Button>
          </Common.Space>
          <Common.Space py={1}>
            <Button variant="primary" onClick={() => history.push('/casino')}>
              {translate('reality-check.leave-game')}
            </Button>
          </Common.Space>

          <Common.Box
            pt={1}
            display="flex"
            justifyContent="space-around"
            style={{ textDecoration: 'underline' }}
          >
            <Common.Box px={0}>
              <ReactRouter.Link to="/account/gambling-history">
                {translate('reality-check.history')}
              </ReactRouter.Link>
            </Common.Box>
          </Common.Box>
        </Card>
      </Common.Box>
    </PopupWrapper>
  )
}

RealityCheckModal.propTypes = {
  handleDismiss: PropTypes.func,
}

Herz.I18n.Loader.preload(
  [
    'reality-check.history',
    'reality-check.reminder',
    'reality-check.playing-for',
    'reality-check.session-stats',
    'reality-check.session-balance-negative',
    'reality-check.session-balance-positive',
    'reality-check.session-logged-in',
    'reality-check.acknowledge',
    'reality-check.continue',
    'reality-check.leave-game',
    'reality-check.history',
  ],
  RealityCheckModal
)

export function RealityCheck(props) {
  const [shouldDisplay, setShouldDisplay] = React.useState(false)
  const { authenticated } = Herz.Auth.useSession()
  const gameServerUrl = ReactRedux.useSelector(state =>
    Configuration.getGameServerUrl(state.configuration)
  )

  React.useEffect(() => {
    function showRealityCheck(event) {
      const action = event.data || {}
      if (action.type === '@rushplay/gamer/REALITY_CHECK_TRIGGERED') {
        return setShouldDisplay(true)
      }
    }

    window.addEventListener('message', showRealityCheck)

    return function cleanup() {
      window.removeEventListener('message', showRealityCheck)
    }
  }, [setShouldDisplay])

  function onDismiss() {
    props.iframeRef.current.contentWindow.postMessage(
      {
        type: '@rushplay/gamer/REALITY_CHECK_RESET',
      },
      gameServerUrl
    )
    setShouldDisplay(false)
  }

  if (!authenticated || !shouldDisplay) {
    return null
  }

  return <RealityCheckModal handleDismiss={onDismiss} />
}

RealityCheck.propTypes = {
  iframeRef: PropTypes.object.isRequired,
}
