import * as React from 'react'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'

import * as Cookies from '../cookies-module'
import { BannerTemplate } from '../banners/banner-template'
import { useMenuQueries } from '../use-menu-queries'

export function LandingBanner() {
  const translate = Herz.I18n.useTranslate()
  const [returningPlayer] = Cookies.useCookie('returning_player')

  const { registerQuery, loginQuery } = useMenuQueries()

  if (returningPlayer) {
    return (
      <BannerTemplate
        header={translate('landing-page.returning.banner-text')}
        prePend={
          <Common.Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            py={0}
          >
            <Common.Box as="span" pr={0}>
              <Common.Box
                as="img"
                alt="clock"
                src="/images/clock.png"
                height="33px"
                width="33px"
              ></Common.Box>
            </Common.Box>
            <Common.Box
              as="p"
              fontFamily="Raleway"
              fontSize="18px"
              color="white"
            >
              {translate('landing-page.returning.subheader')}
            </Common.Box>
          </Common.Box>
        }
        buttonText={translate('landing-page.returning.button')}
        link={`/?${loginQuery}`}
        largeButton={false}
        background={[
          translate('landing-page.returning.background.mobile'),
          translate('landing-page.returning.background'),
        ]}
        overlay={[
          translate('landing-page.returning.overlay.1.mobile'),
          translate('landing-page.returning.overlay.1'),
        ]}
      />
    )
  }

  return (
    <BannerTemplate
      header={translate('landing-page.banner-text')}
      description={translate('landing-page.subheader')}
      buttonText={translate('landing-page.button')}
      link={`/?${registerQuery}`}
      largeButton={false}
      background={[
        translate('landing-page.background.mobile'),
        translate('landing-page.background'),
      ]}
      overlay={[
        translate('landing-page.overlay.1.mobile'),
        translate('landing-page.overlay.1'),
      ]}
    />
  )
}

Herz.I18n.Loader.preload(
  [
    'landing-page.returning.background.mobile',
    'landing-page.returning.background',
    'landing-page.returning.overlay.1.mobile',
    'landing-page.returning.overlay.1',
    'landing-page.returning.banner-text',
    'landing-page.returning.subheader',
    'landing-page.returning.button',
    'landing-page.returning.background.mobile',
    'landing-page.background',
    'landing-page.background.mobile',
    'landing-page.overlay.1.mobile',
    'landing-page.overlay.1',
    'landing-page.banner-text',
    'landing-page.subheader',
    'landing-page.button',
  ],
  LandingBanner
)
