import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import * as Processes from '@rushplay/processes'

import * as Constants from './constants'
import * as Player from './player'
import { SeoSection } from './seo-section'
import { Spinner } from './spinner'
import { useServerConfiguration } from './server-configuration'

export function Sportsbook(props) {
  const translate = Herz.I18n.useTranslate()
  const configuration = useServerConfiguration()
  const { language } = configuration.locale
  const { delasportIframeUrls } = configuration
  const currency = ReactRedux.useSelector(state =>
    Player.getCurrency(state.player)
  )
  const { authenticated } = Herz.Auth.useSession()
  const loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: [Constants.ProcessesIds.LOGIN_REQUEST],
    })
  )

  const [iframeSrc, setIframeSrc] = React.useState(() =>
    authenticated ? delasportIframeUrls[currency] : delasportIframeUrls.SCC
  )

  React.useEffect(() => {
    if (authenticated) {
      setIframeSrc(delasportIframeUrls[currency])
    }

    if (!authenticated) {
      setIframeSrc(delasportIframeUrls.SCC)
    }
  }, [authenticated, currency])

  if (loading) {
    return (
      <Common.Box
        width="100%"
        height={['calc(100dvh - 200px)', null, null, 'calc(100dvh - 60px)']}
        minHeight={['calc(100dvh - 200px)', null, null, 'calc(100dvh - 60px)']}
        dipslay="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner />
      </Common.Box>
    )
  }

  return (
    <Common.Box display="flex" width="100%" flexDirection="column">
      <Common.Box
        width="100%"
        height={['calc(100dvh - 55px)', null, null, 'calc(100dvh - 60px)']}
        minHeight={['calc(100dvh - 55px)', null, null, 'calc(100dvh - 60px)']}
      >
        <iframe
          ref={props.sportsbookRef}
          width="100%"
          height="100%"
          src={`${iframeSrc}/${language}`}
          scrolling="yes"
        />
      </Common.Box>
      <Common.Box width="100%" maxWidth={Constants.ContentMaxWidth} mx="auto">
        <SeoSection content={translate('sportsbook.seo-content')} />
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(['sportsbook.seo-content'], Sportsbook)

Sportsbook.propTypes = {
  sportsbookRef: PropTypes.object,
}
