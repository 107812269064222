import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Constants from './constants'
import * as Player from './player'
import { useTransactionAmountLimits } from './use-transaction-amount-limits'

export function usePlayerLimits(transactionType) {
  const { limits, loading } = useTransactionAmountLimits(transactionType)

  const leftToDepositCents = ReactRedux.useSelector(state =>
    Player.getDepositLimitRemainder(state.player)
  )
  const withdrawableBalanceCents = ReactRedux.useSelector(state =>
    Player.getWithdrawableBalanceCents(state.player)
  )
  const playerWithdrawLimits = ReactRedux.useSelector(state =>
    Player.getWithdrawLimits(state.player)
  )

  const playerDailyLimit =
    playerWithdrawLimits.find(limit => limit.interval === 'day') || {}
  const leftToWithdrawCents = playerDailyLimit.limitCents
    ? Math.min(withdrawableBalanceCents, playerDailyLimit.leftToWithdrawCents)
    : withdrawableBalanceCents

  const playerLimitMaximum =
    transactionType === Constants.TransactionType.PURCHASE
      ? leftToDepositCents
      : leftToWithdrawCents

  return { limits, loading, playerLimitMaximum }
}

usePlayerLimits.propTypes = {
  transactionType: PropTypes.oneOf([
    Constants.TransactionType.AUTH,
    Constants.TransactionType.PURCHASE,
    Constants.TransactionType.REDEEM,
  ]).isRequired,
}
