import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as CombinedSelectors from './combined-selectors'
import { LandingPage } from './landing-page'
import { Spinner } from './spinner'

export function Home(props) {
  const { authenticated, fetching } = Herz.Auth.useSession()
  const isPhoneVerificationRequired = CombinedSelectors.useIsPhoneVerificationRequired()

  if (fetching) {
    return (
      <Common.Box width="100%">
        <Spinner />
      </Common.Box>
    )
  }

  if (authenticated && !isPhoneVerificationRequired) {
    return <ReactRouter.Redirect from="/" to="/casino" />
  }

  return <LandingPage {...props} />
}

// For @loadable/components
export default Home
