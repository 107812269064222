import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Configuration from './configuration'
import * as Player from './player'
import { BigWinsBoard, RacesBoard } from './boards'

const RACES_LEADERBOARD_KEY = 'legendz-leaderboard-iteration'

const BOARDS = {
  RACES: 'races',
  WINS: 'big-wins',
}

function assignRewardsToBigWinners(rewards, bigWinners) {
  const bigWinnersWithRewards = bigWinners.map((winner, index) => {
    const [name, score, currentReward = []] = winner

    for (const reward of rewards) {
      const { rank_begin, rank_end } = reward.rankFilter

      if (index >= rank_begin && index <= rank_end) {
        currentReward.push(reward)
      }
    }

    return [name, score, currentReward]
  })

  return bigWinnersWithRewards
}

function assignRewardsToPlayer(rewards, player) {
  return rewards.filter(reward => {
    const { rank_begin, rank_end } = reward.rankFilter

    return player.playerRank >= rank_begin && player.playerRank <= rank_end
  })
}

function fetchRacesData(apiUrl, token) {
  return fetch(`${apiUrl}/ctool/campaigns/${RACES_LEADERBOARD_KEY}`, {
    headers: {
      Accept: 'application/vnd.casinosaga.v1',
      Authorization: token,
    },
  })
}

export function Leaderboard() {
  const translate = Herz.I18n.useTranslate()
  const { token } = Herz.Auth.useSession()
  const apiUrl = ReactRedux.useSelector(state =>
    Configuration.getApiUrl(state.configuration)
  )
  const username = ReactRedux.useSelector(state =>
    Player.getUsername(state.player)
  )

  const [state, setState] = React.useState({
    activeBoard: BOARDS.RACES,
    records: null,
    playerStats: null,
  })

  function handleFetchRacesData(board) {
    fetchRacesData(apiUrl, token)
      .then(res => res.json())
      .then(data => {
        const { topWinners, rewards, playerRank, playerProgress } = data

        const topWinnersWithRewards = topWinners
          ? assignRewardsToBigWinners(rewards, topWinners)
          : null

        const playerRewards = assignRewardsToPlayer(rewards, { playerRank })

        return setState(prevState => ({
          ...prevState,
          records: topWinnersWithRewards,
          playerStats: { username, playerRank, playerProgress, playerRewards },
          ...(board ? { activeBoard: board } : {}),
        }))
      })
      // eslint-disable-next-line no-console
      .catch(err => console.error(err))
  }

  function handleSetActiveBoard(board) {
    if (state.activeBoard === board) {
      return
    }

    if (board === BOARDS.RACES) {
      return handleFetchRacesData(board)
    }
    return setState(() => ({
      records: null,
      playerStats: null,
      activeBoard: board,
    }))
  }

  React.useEffect(() => {
    handleFetchRacesData()
  }, [])

  return (
    <Common.Box
      width="100%"
      height="685px"
      display="flex"
      minHeight={[null, null, null, '600px']}
    >
      <Common.Box
        display="flex"
        flex="1"
        width={['100%', null, null, '65%']}
        flexDirection="column"
      >
        <Common.Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Common.Box
            as="h3"
            fontFamily="head"
            fontSize={4}
            fontWeight="bold"
            lineHeight="24px"
            flex="1"
          >
            {translate('legendz.leaderboard.heading')}
          </Common.Box>
          <Common.Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="static-white"
          >
            {Object.values(BOARDS).map(board => (
              <Common.Box
                key={board}
                p="8px 12px"
                border="1px solid #919294"
                borderRadius="6px"
                ml={0}
                opacity={state.activeBoard === board ? 1 : 0.3}
                cursor="pointer"
                onClick={() => handleSetActiveBoard(board)}
              >
                {translate(`leaderboard.switch.button.${board}`)}
              </Common.Box>
            ))}
          </Common.Box>
        </Common.Box>
        <Common.Box>
          {state.activeBoard === BOARDS.RACES ? (
            <RacesBoard records={state.records} player={state.playerStats} />
          ) : (
            <BigWinsBoard />
          )}
        </Common.Box>
      </Common.Box>
      <Common.Box display={['none', null, null, 'flex']} width="35%">
        <Common.Box
          width="100%"
          height="100%"
          backgroundImage={`url(${translate('leaderboard.image')})`}
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundPosition="bottom"
        />
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'leaderboard.image',
    'leaderboard.switch.button.races',
    'leaderboard.switch.button.big-wins',
    'legendz.leaderboard.heading',
    'leaderboard.table.heading.player',
    'leaderboard.table.heading.game-title',
    'leaderboard.table.heading.win',
    'leaderboard.table.heading.muliplier',
    'leaderboard.table.heading.place',
    'leaderboard.table.heading.score',
    'leaderboard.table.heading.prize',
    'leaderboard.prize.freespins',
    'leaderboard.prize.bonus-item',
    'leaderboard.prize.bonus-percent-item',
    'leaderboard.prize.money-item',
    'leaderboard.prize.money-percent-item',
    'leaderboard.prize.rubies-bonus',
    'leaderboard.prize.freebet',
    'leaderboard.prize.secret-prize',
    'leaderboard.prize.pp-booster',
    'leaderboard.prize.feature-triggers',
    'leaderboard.prize.free-games-voucher',
  ],
  Leaderboard
)

export default Leaderboard
