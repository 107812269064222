import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import * as Constants from './constants'
import * as Icons from './icons'
import { Button } from './button'
import { Card } from './coins-card'
import { useCustomAmount } from './use-custom-amount'
import { useFetchPackages } from './use-fetch-packages'
import { usePlayerLimits } from './use-player-limits'

const INPUT_MODE = {
  USD: 'USD',
  GC: 'GC',
}

const SwitchButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  padding: 7px;
  color: white;
  background-image: linear-gradient(#7a18dc, #5718dc);
  cursor: pointer;
  outline: none;
  border: none;
`

const StyledInput = styled.input`
  outline: none;
  border: none;
  background: transparent;
  color: #eaeaea;
  width: 100%;
  height: 100%;
  float: right;
  clear: both;
  text-align: right;
`

function GoldeCoinsInput(props) {
  const translate = Herz.I18n.useTranslate()

  return (
    <Common.Box width="100%">
      <Common.Box
        height="22px"
        padding="0px 8px"
        borderRadius="4px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        border="1px solid #2A3149"
        background="#2A3149"
        mb="-4px"
      >
        <Common.Box
          color="white"
          fontSize="12px"
          fontFamily="Raleway"
          fontWeight="700"
          display="flex"
          style={{
            pointerEvents: 'none',
          }}
        >
          <Common.Box style={{ transform: 'translateY(-2px)' }} width="20px">
            <Icons.SweepCoin />
          </Common.Box>
          <Common.Box fontFamily="Raleway" fontSize="10px" color="white">
            SC
          </Common.Box>
          <Common.Box
            fontFamily="Raleway"
            color="white"
            fontSize="10px"
            ml="5px"
            maxWidth="50px"
            overflow="hidden"
          >
            {props.sccAmount}
          </Common.Box>
        </Common.Box>
        <Common.Box
          as="p"
          fontSize="8px"
          fontFamily="Raleway"
          fontWeight="700"
          textTransform="uppercase"
          color="#FFF"
        >
          {translate('coins-store.free-bonus')}
        </Common.Box>
      </Common.Box>
      <Common.Box
        height="32px"
        padding="0px 8px"
        borderRadius="8px"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        border={
          props.inputMode === INPUT_MODE.GC
            ? '1px solid #2A3149'
            : '1px solid #0C132B'
        }
        background={props.inputMode === INPUT_MODE.GC ? '#0C132B' : '#2A3149'}
      >
        <Common.Box
          color="#B2C2EE"
          fontSize="12px"
          fontFamily="Raleway"
          fontWeight="700"
          width="60px"
          display="flex"
          style={{
            pointerEvents: 'none',
          }}
        >
          <Icons.GoldCoin />
          <Common.Box ml={0}>GC</Common.Box>
        </Common.Box>
        <StyledInput
          ref={props.ref}
          disabled={props.inputMode !== INPUT_MODE.GC}
          inputMode="numeric"
          autoFocus
          value={props.amount}
          onChange={props.onChange}
        />
      </Common.Box>
    </Common.Box>
  )
}

GoldeCoinsInput.propTypes = {
  amount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  inputMode: PropTypes.string.isRequired,
  ref: PropTypes.object,
  sccAmount: PropTypes.number,
}

function USDInput(props) {
  const translate = Herz.I18n.useTranslate()
  return (
    <Common.Box
      padding="0 8px"
      height="40px"
      width="100%"
      borderRadius="8px"
      border={
        props.inputMode === INPUT_MODE.USD
          ? '1px solid #0C132B'
          : '1px solid #2A3149'
      }
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      background={props.inputMode === INPUT_MODE.USD ? '#0C132B' : '#2A3149'}
    >
      <Common.Box
        as="p"
        color="#B2C2EE"
        fontSize="12px"
        fontFamily="Raleway"
        fontWeight="700"
        width="110px"
        style={{
          pointerEvents: 'none',
        }}
      >
        $ {translate('coins-store.input.usd.label')}
      </Common.Box>
      <StyledInput
        ref={props.ref}
        disabled={props.inputMode !== INPUT_MODE.USD}
        type="text"
        inputMode="numeric"
        autoFocus
        value={props.amount}
        onChange={props.onChange}
      />
    </Common.Box>
  )
}

USDInput.propTypes = {
  amount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  inputMode: PropTypes.string.isRequired,
  ref: PropTypes.object,
}

function FieldError(props) {
  const translate = Herz.I18n.useTranslate()
  const minRequired = props.currency === 'USD' ? props.min / 100 : props.min + 1
  const maxRequired = props.currency === 'USD' ? props.max / 100 : props.max + 1

  if (props.amount < minRequired) {
    return (
      <Common.Box
        color="#EE5482"
        fontFamily="Raleway"
        fontSize="12px"
        fontWeight="700"
      >
        {translate('transaction.purchase.error.min-amount', {
          minimum: minRequired,
        })}
      </Common.Box>
    )
  }

  if (props.amount > maxRequired) {
    return (
      <Common.Box
        color="#EE5482"
        fontFamily="Raleway"
        fontSize="12px"
        fontWeight="700"
      >
        {translate('transaction.purchase.error.max-amount', {
          maximum: maxRequired,
        })}
      </Common.Box>
    )
  }

  return null
}

FieldError.propTypes = {
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

function CustomAmount(props) {
  const translate = Herz.I18n.useTranslate()

  const [
    onUsdUpdate,
    onGccUpdate,
    usdAmount,
    sccAmount,
    gccAmount,
  ] = useCustomAmount({
    initialAmount: props.initialAmount,
    max: props.max,
  })

  const dollarRef = React.useRef(null)
  const goldCoinRef = React.useRef(null)

  function focusDollarInput() {
    if (dollarRef.current) {
      dollarRef.current.focus()
    }
  }

  function focusGoldCoinInput() {
    if (goldCoinRef.current) {
      goldCoinRef.current.focus()
    }
  }

  React.useEffect(() => {
    if (props.inputMode === INPUT_MODE.USD) {
      focusDollarInput()
    } else {
      focusGoldCoinInput()
    }
  }, [props.inputMode])

  function handleChange(e) {
    onUsdUpdate(e.target.value)
  }

  function handleChangeGoldCoins(e) {
    onGccUpdate(e.target.value)
  }

  return (
    <Common.Box
      width="343px"
      height="190px"
      backgroundImage="radial-gradient(89.53% 92.72% at 50% 117.43%, #233980 0%, #111540 100%)"
      borderRadius="14px"
      boxShadow="0px 0px 2px 0px #FFF, 0px 0px 4px 0px #1849DC"
      border="1px solid #1849DC"
      padding="12px"
    >
      <Common.Box
        display="grid"
        width="100%"
        style={{
          gridTemplateColumns: '50% 50%',
        }}
      >
        <Common.Box width="100%" position="relative">
          <Common.Box position="absolute" top="50px" right="6px" zIndex="9999">
            <SwitchButton onClick={props.handleSwitch}>
              <Icons.SwithcCurrency />
            </SwitchButton>
          </Common.Box>

          <Common.Box
            as="h3"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="800"
            backgroundImage="linear-gradient(180deg, #FFF 0%, #9090FF 100%)"
            style={{
              backgroundClip: 'text',
              '-webkit-text-fill-color': 'transparent',
              textTransform: 'uppercase',
            }}
          >
            {translate('transaction.purchase.custom-amount')}
          </Common.Box>
          <Common.Box pt={1}>
            <Common.Box
              style={{
                '-webkit-transform': 'scaleX(-1)',
                transform: 'scaleX(-1)',
                filter: 'hue-rotate(5deg)',
              }}
              ml="-12%"
              width="100%"
              as="img"
              alt="coins"
              src="/images/custom-coins-image.png"
            />
          </Common.Box>
        </Common.Box>
        <Common.Box width="100%">
          <Common.Box
            width="100%"
            display="flex"
            flexDirection={
              props.inputMode === 'USD' ? 'column' : 'column-reverse'
            }
            style={{
              gap: '8px',
            }}
          >
            <GoldeCoinsInput
              ref={goldCoinRef}
              onChange={handleChangeGoldCoins}
              sccAmount={sccAmount}
              amount={gccAmount}
              inputMode={props.inputMode}
            />
            <USDInput
              ref={dollarRef}
              onChange={handleChange}
              amount={usdAmount}
              inputMode={props.inputMode}
            />
          </Common.Box>
          <Common.Box height="20px" pt="5px" pl="10px">
            <FieldError
              currency={props.inputMode}
              amount={
                props.inputMode === INPUT_MODE.USD ? usdAmount : gccAmount
              }
              min={props.min}
              max={props.max}
            />
          </Common.Box>
          <Common.Box pt={0}>
            <Button
              variant="primary"
              stretch
              disabled={
                usdAmount === 0 ||
                gccAmount === 0 ||
                usdAmount < props.min / 100 ||
                usdAmount > props.max / 100
              }
              onClick={() => {
                props.onStepChange(usdAmount)
              }}
            >
              {translate('main-menu.deposit')}
            </Button>
          </Common.Box>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

CustomAmount.propTypes = {
  onStepChange: PropTypes.func.isRequired,
  handleSwitch: PropTypes.func.isRequired,
  inputMode: PropTypes.string.isRequired,
  initialAmount: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

export function TransactionCards(props) {
  const [inputMode, setInputMode] = React.useState(INPUT_MODE.USD)
  const { limits, playerLimitMaximum } = usePlayerLimits(
    Constants.TransactionType.PURCHASE
  )

  const translate = Herz.I18n.useTranslate()

  const { packages, fetching } = useFetchPackages()

  function handleSwitch() {
    setInputMode(inputMode === INPUT_MODE.USD ? INPUT_MODE.GC : INPUT_MODE.USD)
  }

  return (
    <Common.Box
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pb={2}
    >
      <Common.Box
        width="100%"
        fontSize="14px"
        fontFamily="Raleway"
        padding={1}
        color="#B2C2EE"
        as="h3"
        textAlign="center"
      >
        {translate('transaction.purchase.description')}
      </Common.Box>
      <Common.Box py={1}>
        <CustomAmount
          initialAmount={2000}
          max={Math.min(limits.max, playerLimitMaximum)}
          min={limits.min}
          inputMode={inputMode}
          handleSwitch={handleSwitch}
          onStepChange={amount => {
            props.onStepChange(
              Constants.TransactionStep.Payer,
              Number.parseFloat(amount) * 100
            )
          }}
        />
      </Common.Box>
      <Common.Box
        width="100%"
        display="grid"
        flexDirection="row"
        style={{
          gap: '24px 23px',
        }}
        gridTemplateColumns="repeat(2, 158px)"
        justifyContent="center"
      >
        {fetching ? (
          <Icons.Spinner />
        ) : (
          packages?.map((item, index) => (
            <Card
              index={index}
              onStepChange={() => {
                props.onStepChange(
                  Constants.TransactionStep.Payer,
                  Number.parseFloat(item.amount_cents)
                )
              }}
              key={index}
              {...item}
            />
          ))
        )}
      </Common.Box>
    </Common.Box>
  )
}

TransactionCards.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}
