import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Processes from '@rushplay/processes'

import * as Constants from './constants'
import { fetchSessionToken } from './fetch-session-token'
import { message_receive } from './messaging'
import { useGoogleOAuth } from './oauth-providers/google-oauth-provider'
import { useOpenChildWindow } from './use-open-child-window'

export function useGoogleOAuthSigninConsent(props) {
  const fingerprint = Herz.Seon.useFingerprint()
  const dispatch = ReactRedux.useDispatch()
  const [listenToEvent, setListenToEvent] = React.useState(false)
  const openWindow = useOpenChildWindow({
    handleClosed: props.handleClosed,
  })
  const { params, apiUrl } = useGoogleOAuth()
  const actualParams = props.overrideParams
    ? { ...params, ...props.params }
    : params
  const url = React.useMemo(
    () =>
      Constants.GOOGLE_OAUTH_ENDPOINT +
      '?' +
      new URLSearchParams(actualParams).toString(),
    [actualParams]
  )

  function handleLogin() {
    setListenToEvent(true)

    // Open OAuth authorization request in a popup
    openWindow(url)
  }

  // stop listening to events
  function onFinally() {
    setListenToEvent(false)
  }

  /**
   * @name handleLoginWithData
   *
   * @param {object} data include requested data
   * @param {string} token access_token
   * @param {string} onError error message
   */
  async function handleLoginWithData(data, token) {
    dispatch(Processes.start(Constants.ProcessesIds.REGISTER_REQUEST))
    await fetchSessionToken(
      `${apiUrl}/google_auth`,
      'google',
      { token, seonSessionId: fingerprint.value, ...data },
      props.onSuccess,
      props.onError
    )
  }

  React.useEffect(() => {
    if (listenToEvent) {
      // listen to messages from google popup
      const messageListener = window.addEventListener('storage', async ev => {
        message_receive(
          Constants.GOOGLE_OAUTH_SESSION_TOKEN,
          ev,
          async message => {
            dispatch(Processes.start(Constants.ProcessesIds.LOGIN_REQUEST))
            await fetchSessionToken(
              `${apiUrl}/google_auth`,
              'google',
              { token: message, seonSessionId: fingerprint.value },
              props.onSuccess,
              props.onError,
              onFinally
            )
          }
        )
      })

      return () => {
        setListenToEvent(false)
        window.removeEventListener('storage', messageListener)
      }
    }
  }, [listenToEvent])

  return { handleLogin, handleLoginWithData }
}

useGoogleOAuthSigninConsent.defaultProps = {
  size: {
    width: 600,
    height: 400,
  },
}

useGoogleOAuthSigninConsent.propTypes = {
  params: PropTypes.object,
  overrideParams: PropTypes.bool,
  size: PropTypes.object,
  handleClosed: PropTypes.func,
}
