import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReduxEffects from 'redux-effects'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import { lazy as loadable } from '@loadable/component'

import * as Constants from '../constants'
import * as Cookies from '../cookies'
import * as Player from '../player'
import * as Suspense from '../suspense'
import * as Configuration from '../configuration'
import { DrawerMenu } from '../drawer-menu'
import { Footer } from '../footer'
import { Home } from '../home'
import { HorseRacing } from '../horse-racing'
import { InviteAFriendDrawer } from '../invite-a-friend-drawer'
import { PrivateRoute } from '../private-route'
import { PromotionsPage } from '../promotions-page'
import { QuickNavigationMenu } from '../quick-navigation-menu'
import { RedeemPage } from '../redeem-page'
import { RegistrationDrawer } from '../registration-drawer/registration-drawer'
import { Sportsbook } from '../sportsbook'
import { Wallet } from '../wallet'
import { useScrollToTop } from '../use-scroll-to-top'

const AccountVerification = loadable(() => import('../account-verification'))
const ChangePasswordDrawer = loadable(() => import('../change-password-drawer'))
const Casino = loadable(() => import('../casino'))
const LiveCasino = loadable(() => import('../live-casino'))
const DevTools = loadable(() => import('../dev-tools'))
const Faq = loadable(() => import('../faq'))
const GamblingHistoryDrawer = loadable(() =>
  import('../gambling-history-drawer')
)
const GamePage = loadable(() => import('../game-page'))
const HowToVerifyPage = loadable(() => import('../how-to-verify'))
const Logout = loadable(() => import('../logout'))
const LoginDrawer = loadable(() => import('../login-drawer'))
const MainMenu = loadable(() => import('../main-menu'))
const Moments = loadable(() => import('../moments'))
const MyProfileDrawer = loadable(() => import('../my-profile-drawer'))
const NotFound = loadable(() => import('../not-found'))
const OauthRedirect = loadable(() => import('../oauth-redirect'))
const PasswordRecovery = loadable(() => import('../password-recovery'))
const PasswordReset = loadable(() => import('../password-reset'))
const PromotionTermsAndConditionsPage = loadable(() =>
  import('../promotion-terms-and-conditions-page')
)
const ResetPasswordDrawer = loadable(() => import('../reset-password-drawer'))
const ResponsibleGamingDrawer = loadable(() =>
  import('../responsible-gaming-drawer')
)
const StaticPage = loadable(() => import('../static-page'))
const TermsAndConditions = loadable(() => import('../terms-and-conditions'))
const TransactionHistory = loadable(() =>
  import('../transaction-history-drawer')
)
const Rules = loadable(() => import('../rules'))
const VipPage = loadable(() => import('../vip-page'))

export function Primary() {
  const [ref, handleScrollToTop] = useScrollToTop()
  const sportsbookRef = React.useRef(null)
  const dispatch = ReactRedux.useDispatch()
  const location = ReactRouter.useLocation()
  const { authenticated } = Herz.Auth.useSession()

  const isHorseRacingEnabled = ReactRedux.useSelector(state =>
    Configuration.isHorseRacingEnabled(state.configuration)
  )

  const isSportsbookPage = location.pathname.includes('/sports')
  const isGameplay = location.pathname.includes('/games')

  React.useEffect(() => {
    if (!authenticated) {
      dispatch(
        ReduxEffects.bind(
          Cookies.get(Constants.CookieKeys.HORSE_RACING_SEEN),
          value => value && Player.updateHorseRacingSeen(JSON.parse(value))
        )
      )
    }
  }, [authenticated, dispatch])

  const isMenuHidden = isSportsbookPage || isGameplay
  const mobileMargin = isMenuHidden ? '0px' : '48px'

  return (
    <React.Fragment>
      <ReactRouter.Route path="/" component={MainMenu} />
      <Common.Box ref={ref}>
        <Common.Box
          mt={[mobileMargin, '0px']}
          minHeight={[
            `calc(var(--window-inner-height, 100vh) - 50px)`,
            `calc(var(--window-inner-height, 100vh) - 50px)`,
          ]}
          display="flex"
        >
          <Suspense.Boundary>
            <ReactRouter.Switch>
              <ReactRouter.Route exact path="/">
                <Home onScrollToTop={handleScrollToTop} />
              </ReactRouter.Route>

              <ReactRouter.Redirect
                from="/locks"
                to={{ pathname: '/', search: location.search }}
              />

              <PrivateRoute path="/casino/games/:gameId" component={GamePage} />

              <ReactRouter.Route path="/casino" component={Casino} />
              <ReactRouter.Route path="/live-casino" component={LiveCasino} />

              <ReactRouter.Route path="/sports">
                <Sportsbook sportsbookRef={sportsbookRef} />
              </ReactRouter.Route>

              {isHorseRacingEnabled ? (
                <ReactRouter.Route
                  path="/horse-racing"
                  component={HorseRacing}
                />
              ) : null}

              <ReactRouter.Route path="/moments" component={Moments} />

              <PrivateRoute
                path="/my-identity"
                component={AccountVerification}
              />

              <PrivateRoute path="/how-to-verify" component={HowToVerifyPage} />

              <ReactRouter.Route
                exact
                path="/password-recovery"
                component={PasswordRecovery}
              />

              <ReactRouter.Redirect
                exact
                from="/password/:token"
                to="/password-recovery/:token"
              />
              <ReactRouter.Route
                path="/password-recovery/:token"
                component={PasswordReset}
              />

              <ReactRouter.Route
                path="/oauth-redirect"
                component={OauthRedirect}
              />

              <ReactRouter.Route
                path={[
                  '/(about)',
                  '/(privacy-policy)',
                  '/(responsible-gaming)',
                  '/(dispute-resolution)',
                  '/(anti-money-laundering)',
                  '/(fairness-rng-testing-methods)',
                  '/(cookie-policy)',
                  '/(kyc-policies)',
                  '/(self-exclusion)',
                  '/(security)',
                  '/(accounts-payouts-bonuses)',
                  '/(account-verification-faq)',
                ]}
                component={StaticPage}
              />

              <ReactRouter.Route
                path="/promotions"
                component={PromotionsPage}
              />

              <ReactRouter.Route
                path="/promotions/:key"
                component={PromotionTermsAndConditionsPage}
              />

              <ReactRouter.Route path="/faq" component={Faq} />

              <ReactRouter.Route
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />

              <ReactRouter.Route path="/rules" component={Rules} />
              <PrivateRoute path="/redeem" component={RedeemPage} />

              <ReactRouter.Route path="/vip" component={VipPage} />

              <ReactRouter.Route path="/logout" component={Logout} />

              {/* Keep existing functionality for people who still have it for a while */}
              <ReactRouter.Redirect
                from="/login"
                to={{ pathname: '/', search: '?login=me' }}
              />

              <ReactRouter.Route path="/dev-tools">
                <DevTools />
              </ReactRouter.Route>

              <ReactRouter.Route
                path="/500"
                render={() => {
                  throw new Error('Failure was successful')
                }}
              />

              <ReactRouter.Route component={NotFound} />
            </ReactRouter.Switch>
          </Suspense.Boundary>
        </Common.Box>
        <Footer />
      </Common.Box>

      <QuickNavigationMenu
        onClick={() => handleScrollToTop()}
        sportsbookRef={sportsbookRef}
      />
      <MyProfileDrawer />
      <ResponsibleGamingDrawer />
      <ChangePasswordDrawer />
      <GamblingHistoryDrawer />
      <TransactionHistory />
      <ResetPasswordDrawer />
      <Wallet />
      <LoginDrawer />
      <RegistrationDrawer />
      <DrawerMenu />
      <InviteAFriendDrawer />
    </React.Fragment>
  )
}

// For @loadable/component
export default Primary
