import * as React from 'react'
import PropTypes from 'prop-types'

import { GameTileGrid } from './casino-categories/game-tile-grid'
import { GameTileRow } from './casino-categories/game-tile-row'

export function GameTileSection(props) {
  if (props.layout === 'grid') {
    return (
      <GameTileGrid
        hideButtonMore={props.hideButtonMore}
        sectionTitle={props.sectionTitle}
        size={props.size}
        id={props.id}
      >
        {props.children}
      </GameTileGrid>
    )
  }

  // Hybrid; row that can expand into grid
  if (props.layout === 'row') {
    return (
      <GameTileRow
        hideButtonMore={props.hideButtonMore}
        sectionTitle={props.sectionTitle}
        id={props.id}
        layout={props.layout}
        size={props.size}
      >
        {props.children}
      </GameTileRow>
    )
  }

  return props.children
}

GameTileSection.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  columns: PropTypes.number,
  layout: PropTypes.oneOf(['grid', 'row']),
  containWidth: PropTypes.bool,
  sectionTitle: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  extraSpacing: PropTypes.number,
  hideButtonMore: PropTypes.bool,
}
