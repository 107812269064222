import * as React from 'react'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'

import * as Constants from './constants'
import { exchangeToken, fetchSessionToken } from './fetch-session-token'
import { message_receive } from './messaging'
import { useFacebookOAuth } from './oauth-providers/facebook-oauth-provider'
import { useOpenChildWindow } from './use-open-child-window'

export function useFacebookSignin(props) {
  const fingerprint = Herz.Seon.useFingerprint()
  const [listenToEvent, setListenToEvent] = React.useState(false)
  const { params, apiUrl } = useFacebookOAuth()
  const openWindow = useOpenChildWindow({
    handleClosed: props.handleClosed,
  })

  const url = React.useMemo(
    () =>
      Constants.FACEBOOK_OAUTH_ENDPOINT +
      '?' +
      new URLSearchParams(params).toString(),
    [params]
  )

  function handleLogin() {
    setListenToEvent(true)
    // Open OAuth authorization request in a popup
    openWindow(url)
  }

  function onFinally() {
    setListenToEvent(false)
  }

  /**
   * @name handleLoginWithData
   *
   * @param {object} data include requested data
   */
  async function handleLoginWithData(data) {
    await fetchSessionToken(
      `${apiUrl}/facebook_auth`,
      'facebook',
      { ...data, seonSessionId: fingerprint.value },
      props.onSuccess,
      props.onError
    )
  }

  React.useEffect(() => {
    if (listenToEvent) {
      // listen to messages from google popup
      const messageListener = window.addEventListener('storage', async ev => {
        message_receive(
          Constants.FACEBOOK_OAUTH_SESSION_TOKEN,
          ev,
          async message => {
            await exchangeToken(
              `${apiUrl}/facebook_auth/exchange_token`,
              { code: message },
              async res => {
                await fetchSessionToken(
                  `${apiUrl}/facebook_auth`,
                  'facebook',
                  {
                    access_token: res.access_token || '',
                    seonSessionId: fingerprint.value,
                  },
                  props.onSuccess,
                  props.onError,
                  onFinally
                )
              },
              props.onError
            )
          }
        )
      })

      return () => {
        window.removeEventListener('storage', messageListener)
      }
    }
  }, [listenToEvent])

  return { handleLogin, handleLoginWithData }
}

useFacebookSignin.defaultProps = {
  size: {
    width: 600,
    height: 400,
  },
}

useFacebookSignin.propTypes = {
  params: PropTypes.object,
  overrideParams: PropTypes.bool,
  size: PropTypes.object,
}
