import * as React from 'react'

import * as Herz from '@rushplay/herz'

import { BannerTemplate } from './banner-template'

export function LiveCasinoBanner() {
  const translate = Herz.I18n.useTranslate()
  return (
    <BannerTemplate
      header={translate('lobby.live-casino-banner.title')}
      description={translate('lobby.live-casino-banner.description')}
      buttonText={translate('lobby.live-casino-banner.button')}
      link={'/live-casino'}
      largeButton
      background={[
        translate('lobby.live-casino-banner.background.mobile'),
        translate('lobby.live-casino-banner.background'),
      ]}
      overlay={[
        translate('lobby.live-casino-banner.overlay.1.mobile'),
        translate('lobby.live-casino-banner.overlay.1'),
      ]}
    />
  )
}
