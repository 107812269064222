import * as FramerMotion from 'framer-motion'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Notifications from '@rushplay/notifications'
import styled from '@emotion/styled'

import * as CombinedSelectors from './combined-selectors'
import * as Constants from './constants'
import * as Player from './player'
import * as Icons from './icons'
import { OfferPicker } from './offer-picker'
import { Payer } from './payer'
import { TransactionAmount } from './transaction-amount'
import { usePlayerLimits } from './use-player-limits'
import { useSafeUpdateQuery } from './use-safe-update-query'

const Wrapper = styled.div`
  flex-grow: 1;
  padding-bottom: 16px;
  height: 100%;

  > form {
    height: 100%;
  }
`

export function makeDataSchema(limits, playerLimitMaximum, loading = false) {
  if (loading) {
    return
  }

  return {
    properties: {
      amount: {
        type: 'number',
        maximum: R.min(limits.max, playerLimitMaximum),
        minimum: limits.min,
      },
    },
    required: ['amount'],
    type: 'object',
  }
}

export const formName = 'transaction'

function TransactionConsumer(props) {
  const dispatch = ReactRedux.useDispatch()
  const history = ReactRouter.useHistory()
  const [depositMethod, setDepositMethod] = React.useState('')
  const depositMethodRef = React.useRef(depositMethod)
  const amountCents = Forms.useField('#/properties/amount', {
    noRegister: true,
  }).value
  const closeWalletUrl = useSafeUpdateQuery({ wallet: null, amount: null })
  const depositCount = ReactRedux.useSelector(state =>
    Player.getDepositCount(state.player)
  )

  function updateDepositMethod(newState) {
    depositMethodRef.current = newState
    setDepositMethod(newState)
  }

  const onSelectMethod = React.useCallback(
    method => {
      const isDeposit =
        props.transactionType === Constants.TransactionType.PURCHASE
      updateDepositMethod(method)
      if (isDeposit) {
        dispatch(Analytics.selectPaymentMethod(method))
      }
    },
    [dispatch, props.transactionType]
  )

  const handleSuccess = React.useCallback(() => {
    const isDeposit =
      props.transactionType === Constants.TransactionType.PURCHASE

    dispatch([
      Api.deleteClaimedDepositOffer({ version: 2 }),
      Notifications.add({
        message: `wallet.transaction-success.${props.transactionType}.content`,
        level: 'success',
      }),
    ])

    // optimistic update to hide Welcome Campaign banner
    if (depositCount === 0) {
      dispatch(Player.updateHasWelcomeCampaign(false))
    }

    // hack to avoid fetching player info before deposit count is updated on BE side
    setTimeout(
      () =>
        isDeposit &&
        dispatch(
          Player.fetchPlayerInfo({
            success: () =>
              Analytics.completeTransaction(
                'successful',
                depositMethodRef.current
              ),
          })
        ),
      5000
    )
    history.push(`?${closeWalletUrl}`)
  }, [dispatch, props.transactionType, depositMethodRef.current, amountCents])

  const onFailure = React.useCallback(
    status => {
      const isDeposit =
        props.transactionType === Constants.TransactionType.PURCHASE
      if (isDeposit) {
        dispatch(
          Player.fetchPlayerInfo({
            success: () =>
              Analytics.completeTransaction(status, depositMethodRef.current),
          })
        )
      }

      dispatch(
        Notifications.add({
          message: `wallet.transaction-failure`,
          level: 'error',
        })
      )

      history.push(`?${closeWalletUrl}`)
    },
    [dispatch, props.transactionType, depositMethodRef.current]
  )

  const onCancel = React.useCallback(() => {
    onFailure('cancelled')
    history.push(`?${closeWalletUrl}`)
  }, [dispatch, props.onStepChange])

  return (
    <FramerMotion.AnimatePresence exitBeforeEnter>
      {props.step === Constants.TransactionStep.Amount &&
        props.transactionType !== Constants.TransactionType.REDEEM && (
          <FramerMotion.motion.div
            key="amount-step"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25 }}
            exit={{ opacity: 0 }}
          >
            <TransactionAmount
              onStepChange={props.onStepChange}
              selectedOfferId={props.offerId}
              transactionType={props.transactionType}
              disabled={props.disabled}
              offerPicker={
                <OfferPicker
                  selectedOfferId={props.offerId}
                  transactionType={props.transactionType}
                  onChangeOfferId={props.onOfferChange}
                />
              }
            />
          </FramerMotion.motion.div>
        )}

      {(props.step === Constants.TransactionStep.Payer ||
        props.transactionType === Constants.TransactionType.REDEEM) && (
        <FramerMotion.motion.div
          key="payer-step"
          initial={{ opacity: 0, height: '100%' }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.25 }}
          exit={{ opacity: 0 }}
        >
          <Payer
            amountCents={amountCents || props.amount}
            offerId={props.offerId}
            transactionType={props.transactionType}
            onSelectMethod={onSelectMethod}
            onFailure={onFailure}
            onSuccess={handleSuccess}
            onCancel={onCancel}
          />
        </FramerMotion.motion.div>
      )}
    </FramerMotion.AnimatePresence>
  )
}

TransactionConsumer.propTypes = {
  offerId: PropTypes.string,
  step: PropTypes.number.isRequired,
  transactionType: PropTypes.oneOf([
    Constants.TransactionType.AUTH,
    Constants.TransactionType.PURCHASE,
    Constants.TransactionType.REDEEM,
  ]).isRequired,
  onOfferChange: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  amount: PropTypes.number,
}

// Provider
export function Transaction(props) {
  const dispatch = ReactRedux.useDispatch()
  const offers = ReactRedux.useSelector(state =>
    CombinedSelectors.getCalculatedOffers(state, { amountCents: 0 })
  )
  const [offerId, setOfferId] = React.useState(undefined)

  const { limits, loading, playerLimitMaximum } = usePlayerLimits(
    props.transactionType
  )

  const dataSchema = React.useMemo(
    () => makeDataSchema(limits, playerLimitMaximum, loading),
    [limits, loading, playerLimitMaximum]
  )

  React.useEffect(() => {
    // I'm specifically checking if it's undefined because then we know
    // that the user made no choice yet. Only preselecting offers when they arrive
    // if no choice has been made. Choosing "no offer" the state would be null.
    if (offers?.length > 0 && offerId === undefined) {
      setOfferId(offers[0]?.id)
    }
  }, [offers])

  if (loading) {
    return (
      <Common.Box fontSize={6} pt={2} display="flex" justifyContent="center">
        <Icons.Spinner />
      </Common.Box>
    )
  }

  return (
    <Wrapper key={props.transactionType}>
      <Forms.Provider
        schema={dataSchema}
        name={formName}
        onSubmit={(errors, data) => {
          if (R.isEmpty(errors)) {
            if (offerId) {
              dispatch(
                Api.claimDepositOffer(offerId, {
                  failure: res =>
                    Notifications.add({
                      message: `errors.${res.value.message ||
                        'general.unknown'}`,
                      level: 'error',
                    }),
                  version: 2,
                })
              )
            }

            if (props.transactionType === Constants.TransactionType.PURCHASE) {
              dispatch(
                Player.fetchPlayerInfo({
                  success: () => Analytics.selectAmount(data.amount),
                })
              )
            }

            return props.onStepChange(Constants.TransactionStep.Payer)
          }
        }}
      >
        <TransactionConsumer
          offerId={offerId}
          step={props.step}
          transactionType={props.transactionType}
          onOfferChange={setOfferId}
          onStepChange={props.onStepChange}
          disabled={playerLimitMaximum === 0}
          amount={props.amount}
        />
      </Forms.Provider>
    </Wrapper>
  )
}

Transaction.propTypes = {
  step: PropTypes.number,
  transactionType: PropTypes.string,
  onStepChange: PropTypes.func.isRequired,
  amount: PropTypes.number,
}
