import * as Urql from 'urql'
import * as ReactRedux from 'react-redux'

import * as Api from '@rushplay/api-client'
import * as Analytics from '@rushplay/analytics'
import * as Notifications from '@rushplay/notifications'
import * as Processes from '@rushplay/processes'

import * as Player from './player'
import * as FetchOptions from './fetch-options'
import * as Cookies from './cookies-module'
import { CookieKeys, CookieOptions, ProcessesIds } from './constants'
import { getDomain } from './get-domain'

export const PLAYER_INFO_INIT_QUERY = `
  query PlayerInfoInit {
    player {
      account {
        balanceCents
        bonusBalanceCents
        depositNumber
        lastDepositCents
        moneyBalanceCents
        currency
        eurBalanceCents
      }
      delasportTokens {
        SCC
        GCC
      }
      attribution {
        affiliateClickId
        affiliateSubId
        affiliateId
        affiliateTag
      }
      profile {
        address {
          city
          zip: postalCode
          state
          street
        }
        id
        phoneNumber {
          mobile
        }
        displayName
        email
        firstName
        lastName
        username
        birthdate
        allowEmail
        allowSMS
        avatar
        betbackDropPoint
        betbackProgress
        betbackProgressPercentage
        claimableBalanceCents
        countryCode
        inventoryNotifications
        requiresPhoneValidation
        language
        mainBetbackBalanceCents
        nextBetbackBalanceCents
        personId
        rubies
        vip
        vipTier
        segments {
          valueSegment
          productSegment
        }
      }
      info {
        depositAttempts
        gift {
          bonusCents
          wagerFactor
          sportWagerFactor
        }

      }
    }
  }
`

export function useLogin() {
  const client = Urql.useClient()
  const { headers, agent } = FetchOptions.useContext()
  const [, setAuthToken] = Cookies.useCookie(CookieKeys.TOKEN)
  const [, setSportsbookToken] = Cookies.useCookie(CookieKeys.SPORTSBOOK_TOKEN)
  const [, setPhoneVerificationCookie] = Cookies.useCookie(
    CookieKeys.IS_PHONE_VERIFICATION_REQUIRED
  )
  const dispatch = ReactRedux.useDispatch()

  function afterLoginActions(token, playerData) {
    const isPhoneVerificationRequired =
      playerData?.profile?.requiresPhoneValidation === 'restricted'
    const hasZeroBalance = playerData.account?.balanceCents === 0
    setAuthToken(token, CookieOptions[CookieKeys.TOKEN])
    setPhoneVerificationCookie(
      isPhoneVerificationRequired,
      CookieOptions[CookieKeys.IS_PHONE_VERIFICATION_REQUIRED]
    )
    const delasportTokens = playerData?.delasportTokens
    const playerCurrency = playerData?.account?.currency
    const sportbookToken = delasportTokens[playerCurrency]
    setSportsbookToken(sportbookToken, {
      ...CookieOptions[CookieKeys.TOKEN],
      domain: getDomain(),
    })

    dispatch([
      Analytics.authenticate(),
      Player.playerInfoInit({
        ...playerData.account,
        ...playerData.info,
        ...playerData.attribution,
        ...playerData.profile,
        ...playerData.profile.address,
        ...playerData.profile.phoneNumber,
        ...playerData.profile.valueSegment,
        isPhoneVerificationRequired,
      }),
      hasZeroBalance && Player.updateEnforceDeposit(true),
      isPhoneVerificationRequired &&
        Api.requestSmsVerification({
          failure: () =>
            Notifications.add({
              message: 'errors.phone-verification-request.failed',
              level: 'error',
            }),
          version: 1,
          token,
        }),
    ])
  }

  function handleLogin(token) {
    client
      .query(
        PLAYER_INFO_INIT_QUERY,
        { token },
        {
          fetchOptions: {
            agent,
            headers: {
              ...headers,
              Authorization: token,
            },
          },
        }
      )
      .toPromise()
      .then(res => {
        const playerData = res.data?.player ?? null
        if (playerData) {
          afterLoginActions(token, playerData)
          return
        }
        throw new Error('Missing player data in response')
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
      .finally(() => {
        dispatch([
          Processes.stop(ProcessesIds.LOGIN_REQUEST),
          Processes.stop(ProcessesIds.REGISTER_REQUEST),
        ])
      })
  }

  function handleLoginError(error) {
    setAuthToken(null, CookieOptions[CookieKeys.TOKEN])
    dispatch([
      Processes.stop(ProcessesIds.LOGIN_REQUEST),
      Processes.stop(ProcessesIds.REGISTER_REQUEST),
    ])
    if (error.code) {
      dispatch([
        Notifications.add({
          message: error.code,
          level: 'error',
        }),
      ])
    } else {
      dispatch([
        Notifications.add({
          message: `errors.${error.status || 'general.unknown'}`,
          level: 'error',
        }),
      ])
    }
  }

  return { handleLogin, handleLoginError }
}
