import * as Common from '@rushplay/common'
import styled from '@emotion/styled'

export const Scrollable = styled(Common.Box)`
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`
